import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { MriContext } from '../mriContext'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
    
    
}));
function Mri3() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(MriContext);
    const [insertMetal, setInsertMetal] = React.useState('');
    const [dentures, setDentures] = React.useState('');
    const [eatenAnyMeal, setEatenAnyMeal] = React.useState('');
    const [contrastMediumBefore, setContrastMediumBefore] = React.useState('');
    const [feltSickAfterContrastMedium, setFeltSickAfterContrastMedium] = React.useState('');
    const [asthmaOrAllergies, setAsthmaOrAllergies] = React.useState('');
    const [openedWouned, setOpenedWouned] = React.useState('');
    const [prohibitLiquid, setProhibitLiquid] = React.useState('');
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "mri3 - Dressing",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const handleChangeInsertMetal = event => {
        setInsertMetal(event.target.value);
        setValues({ ...values, surgeriesToInsertAMetal: event.target.value })
    };
    const handleChangeDentures = event => {
        setDentures(event.target.value);
        setValues({ ...values, denturesOrHearingAid: event.target.value })
    };
    const handleChangeEatenAnyMeal = event => {
        setEatenAnyMeal(event.target.value);
        setValues({ ...values, eatenAnyMeal: event.target.value })
    };
    const handleChangeContrastMediumBefore = event => {
        setContrastMediumBefore(event.target.value);
        setValues({ ...values, contrastMediumBefore: event.target.value })
    };
    const handleChangeFeltSickAfterContrastMedium = event => {
        setFeltSickAfterContrastMedium(event.target.value);
        setValues({ ...values, feltSickAfterContrastMedium: event.target.value })
    };
    const handleChangeAsthmaOrAllergies = event => {
        setAsthmaOrAllergies(event.target.value);
        setValues({ ...values, asthmaOrAllergies: event.target.value })
    };
    const handleChangeOpenedWouned = event => {
        setOpenedWouned(event.target.value);
        setValues({ ...values, openedWouned: event.target.value })
    };
    const handleChangeProhibitLiquid = event => {
        setProhibitLiquid(event.target.value);
        setValues({ ...values, prohibitLiquid: event.target.value })
    };
    
    const allergies = [
        { name: t('test.asthma') + "/喘息", value: "asthma" },
        { name: t('test.hives') + "/じんましん", value: "hives" },
        { name: t('test.atopic') + "/アトピー", value: "atopic" },
        { name: t('test.hayfever') + "/花粉症", value: "hayfever" },
        { name: t('test.Shrimp') + "/甲殻類（エビ、カニ類）", value: "Shrimp" },
        { name: t('test.fish') + "/青魚", value: "fish" },
        { name: t('test.fishroe') + "/魚卵", value: "fishroe" },
        { name: t('test.shellfish') + "/貝類", value: "shellfish" },
        { name: t('test.egg') + "/卵", value: "egg" },
        { name: t('test.milk') + "/牛乳", value: "milk" },
        { name: t('test.cheese') + "/チーズ", value: "cheese" },
        { name: t('test.buckwheat') + "/そば", value: "buckwheat" },
        { name: t('test.peanuts') + "/ピーナッツ", value: "peanuts" },
        { name: t('test.almonds') + "/アーモンド", value: "almonds" },
        { name: t('test.wheat') + "/小麦", value: "wheat" },
        { name: t('test.soy') + "/大豆", value: "soy" },
        { name: t('test.kiwifruit') + "/キウイ", value: "kiwifruit" },
        { name: t('test.peaches') + "/桃", value: "peaches" },
        { name: t('test.apples') + "/りんご", value: "apples" },
        { name: t('test.avocados') + "/アボガド", value: "avocados" },
        { name: t('test.yams') + "/山芋", value: "yams" },
        { name: t('test.rice') + "/米", value: "rice" },
        { name: t('test.tofu') + "/豆腐", value: "tofu" },
        { name: t('test.molded') + "/カビ", value: "molded" },
        { name: t('test.chicken') + "/鶏肉", value: "chicken" },
        { name: t('test.pork') + "/豚肉", value: "pork" },
        { name: t('test.beef') + "/牛肉", value: "beef" },
        { name: t('test.penicillin') + "/抗菌剤・抗生物質", value: "penicillin" },
        { name: t('test.antiviral') + "/抗ウイルス薬", value: "antiviral" },
        { name: t('test.painkillers') + "/鎮痛剤・抗炎症薬", value: "painkillers" },
        { name: t('test.OTC') + "/市販の風邪薬", value: "OTC" },
        { name: t('test.others') + "/その他", value: "others" },
    ];
    const [checkedAllergies, setCheckedAllergies] = React.useState([]);
    const handleToggleAllergies = (object) => () => {
        const currentIndex = values.allergies.indexOf(object.value);
        const newChecked = [...values.allergies];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAllergies(newChecked);
        setValues({ ...values, allergies: newChecked })
    };
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            更衣({t('test.title.dressing')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.reviewQuestionnaire}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>問診表の確認をします<br />{t('test.dressing.review questionnaire')}</h5> </strong>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                        value={values.takeSlipDressing}
                        control={<Checkbox />}
                        labelPlacement="start"
                        label={<strong><h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5> </strong>} />
 
                    </Paper>
                </Grid>

            <Grid item xs={12}>
                <Paper className={classes.paper}><strong><h5>体内に金属が入るような手術をしたことはないでしょうか？<br />{t('test.dressing.surgeries to insert a metal')}</h5></strong></Paper>
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">

                    <RadioGroup row aria-label="insertMetal" name="insertMetal" value={values.surgeriesToInsertAMetal} onChange={handleChangeInsertMetal}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>}/>
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                    </RadioGroup>
                </FormControl>
            </Grid>    

        <Grid item xs={12}>
            <Paper className={classes.paper}><strong><h5>入れ歯、補聴器のような外せるものはないでしょうか？<br />{t('test.dressing.dentures or hearing aid')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="dentures" name="dentures" value={values.denturesOrHearingAid} onChange={handleChangeDentures}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.dressing.Ihave')}<br />あります</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.dressing.not have')}<br />ありません</h5>} />
                </RadioGroup>
            </FormControl>
        </Grid>    
        <Grid item xs={11}>
            <div>
                {values.denturesOrHearingAid === 'yes' ? (

                    <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1" }}>
                        <FormControlLabel
                            value={values.takeOff}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>外してください<br />{t('test.dressing.TakeOff')}</h5> </strong>} />

                    </Paper>
                ) : (
                        <nav> &apos; </nav>
                    )}
            </div>
        </Grid>
        <Grid item xs={12}>
            <Paper className={classes.paper}><strong><h5>食事はとっていないでしょうか？<br />{t('test.dressing.eaten any meal')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="eatenAnyMeal" name="eatenAnyMeal" value={values.eatenAnyMeal} onChange={handleChangeEatenAnyMeal}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.dressing.didnot take')}<br />食べていない</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.dressing.I did')}<br />食べた</h5>} />
                </RadioGroup>
            </FormControl>
        </Grid>    
        <Grid item xs={12}>
            <Paper className={classes.paper}><strong><h5>過去に造影剤を使用したことはありますか？<br />{t('test.dressing.contrast medium before')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="ContrastMediumBefore" name="ContrastMediumBefore" value={values.contrastMediumBefore} onChange={handleChangeContrastMediumBefore}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                </RadioGroup>
            </FormControl>
        </Grid>    

        <Grid item xs={12}>

            <Paper className={classes.paper}><strong><h5>造影剤を使用した際に気分が悪くなったということはないでしょうか？<br />{t('test.dressing.felt sick after contrast medium')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="FeltSickAfterContrastMedium" name="FeltSickAfterContrastMedium" value={values.feltSickAfterContrastMedium} onChange={handleChangeFeltSickAfterContrastMedium}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />ある</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />ない</h5>} />
                </RadioGroup>
            </FormControl>
        </Grid> 
        <Grid item xs={12}>

            <Paper className={classes.paper}><strong><h5>気管支喘息やアレルギーはないでしょうか？<br />{t('test.dressing.asthma or allergies')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="AsthmaOrAllergies" name="AsthmaOrAllergies" value={values.asthmaOrAllergies} onChange={handleChangeAsthmaOrAllergies}>
                        <FormControlLabel value="asthma" control={<Radio />} label={<h5>{t('test.dressing.haveAsthma')}<br />気管支喘息です</h5>} />
                        <FormControlLabel value="allergies" control={<Radio />} label={<h5>{t('test.dressing.haveAllergies')}<br />アレルギーがあります</h5>} />
                </RadioGroup>
            </FormControl>
            </Grid> 
            <div>
                {values.asthmaOrAllergies === 'allergies' ? (
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>どのようなものに対してアレルギーがありますか？<br />{t('test.dressing.what allergic')}</h4></strong></Paper>
                    </Grid>

                    <Grid container spacing={3} style={{ padding: 20 }}>
                        <GridList container style={{ height: 440, padding: 20 }} cellHeight={20} className={classes.gridList} cols={2}>
                            {allergies.map((value) => {
                                const labelId = `checkbox-list-label-${value.name}`;

                                return (
                                    <ListItem key={value.name} role={undefined} dense button onClick={handleToggleAllergies(value)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={values.allergies.indexOf(value.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={`${value.name}`} />

                                    </ListItem>
                                );
                            })}
                        </GridList>
          
                    </Grid>
                </Grid>

            </div>
                ) : (
                        <nav> &apos; </nav>
                    )}
            </div>
        
        <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.dringContrastMedium}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>造影剤を飲んでいただきます<br />{t('test.dressing.drink contrast medium')}</h5> </strong>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>現在、胃や腸などの消化器官に穴が開いて治療しているといったことはないでしょうか？<br />{t('test.dressing.is there any opened wouned')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="openedWouned" name="openedWouned" value={values.openedWouned} onChange={handleChangeOpenedWouned}>
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />ない</h5>} />
                            <FormControlLabel value="gettingTreatment" control={<Radio />} label={<h5>{t('test.dressing.Im getting treatment')}<br />現在治療中である</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid> 
                <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>先生から水分の制限をされていないでしょうか？<br />{t('test.dressing.prohibit liquid')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="prohibitLiquid" name="prohibitLiquid" value={values.prohibitLiquid} onChange={handleChangeProhibitLiquid}>
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />ない</h5>} />
                            <FormControlLabel value="restriction" control={<Radio />} label={<h5>{t('test.dressing.restriction')}<br />水分を制限されています</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid> 
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.slowlyDrink}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>ゆっくりで構わないのでなるべく全部飲んでください<br />{t('test.dressing.slowly drink')}</h5> </strong>} />

                    </Paper>
                </Grid>
            </Grid>
            <div style={{ margin: 20 }}>

                <Link to="mri1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >MRI HOME</Button></Link>
                <Link to="mri4" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                 </div>
            <Footer />

        </div>
                
    );
}
 
export default Mri3;