import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray16() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(XrayContext);
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray16 - Both lower limbs/back thigh",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const language = i18n.language;
    const [playXRAYmulti12_1] = useSound('/sound/12/'+language+'12/12-01.mp3');
    const [playXRAYmulti12_2] = useSound('/sound/12/' + language + '12/12-02.mp3');
    const [playXRAYmulti12_7] = useSound('/sound/12/' + language + '12/12-07.mp3');
    const [playXRAYmulti12_8] = useSound('/sound/12/' + language + '12/12-08.mp3');
    const [playXRAYmulti12_9] = useSound('/sound/12/' + language + '12/12-09.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/' + language + '13/13-14.mp3');
    
    const [playXRAYjp12_1] = useSound('/sound/12/jp12/12-01.mp3');
    const [playXRAYjp12_2] = useSound('/sound/12/jp12/12-02.mp3');
    const [playXRAYjp12_7] = useSound('/sound/12/jp12/12-07.mp3');
    const [playXRAYjp12_8] = useSound('/sound/12/jp12/12-08.mp3');
    const [playXRAYjp12_9] = useSound('/sound/12/jp12/12-09.mp3');
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            全下肢・大腿({t('test.title.both lower limbs/back thigh')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.photoWholeLeg}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>足全体の撮影をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo whole leg')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.photoOfLowerLimbs}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>全下肢の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of lower limbs')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.wrapAround}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>生殖腺防護のために鉛のゴムを置き（巻きつけ）ます
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.wrap around')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.standingUpOnTheStand}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>台の上に立って撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.standing up on the stand')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.feetInwards}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し内股にします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.feet inwards')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lowerLimbs.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray16;