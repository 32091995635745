import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { MedContext } from '../context/internalMedContext';
import UserContext from '../context/UserContext';
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    sidecheck:{
        paddingTop:11, 
        marginLeft:-13, 
        marginRight:5, 
        color:"blue"
    },
    gridList: {
        width: 1000,

        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useContext(MedContext);
    const classes = useStyles();
    const [female, setFemale] = React.useState('No');
    const [breastFeeding, setBreastFeeding] = React.useState('No');
    const [SpecialRequest, setSpecialRequest] = React.useState({
        medicalexpenses: false,
        haveinterpreter: false,
        others: false
    });
    const { userData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    });

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    const logFunction = async (question) =>{
        const loginfo = {
            inteviewName: "Internal Medcine form 7",
            //userName: userData.user.userName,
            language: i18n.language,
            contentSentence: question,
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
    }  

    const handleChangeCheckSpecialRequest = (event) => {
        //setSpecialRequest({ ...SpecialRequest, [event.target.name]: event.target.checked });
        setValues({...values, [event.target.name]:event.target.checked})
        logFunction("Special request concerning consoltation");
    };

    const handleChange = (event) => {
        //setFemale(event.target.value);
        setValues({...values, pregnant:event.target.value});
        logFunction("Are you pregnant or possibly pregnant");
    };

    const handleChangebreastFeeding = (event) => {
        //setBreastFeeding(event.target.value);
        setValues({...values, breastfeeding:event.target.value})
        logFunction("Are you breastfeeding")
    };
console.log(values)
    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.Iffemale')}? <br /><i style={{color:"blue"}}>/女性の方のみお答えください。妊娠していますか、またその可能性はありますか。</i></h5></strong></Paper>
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="gender" name="gender1" value={values.pregnant} onChange={handleChange}>
                            <FormControlLabel value="yes" control={<Radio />} label={t('internalMedcine.yes')} /><i className={classes.sidecheck}>/はい</i>
                            <FormControlLabel value="No" control={<Radio />} label={t('internalMedcine.No')} /><i className={classes.sidecheck}>/いいえ</i>
                            <FormControlLabel value="Donotknow" control={<Radio />} label={t('internalMedcine.Donotknow')} /><i className={classes.sidecheck}>/わからない</i>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.Areyoubreastfeeding')}? <br /><i style={{color:"blue"}}>/現在、授乳中ですか。</i></h5></strong></Paper>
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="gender" name="gender1" value={values.breastfeeding} onChange={handleChangebreastFeeding}>
                            <FormControlLabel value="yes" control={<Radio />} label={t('internalMedcine.yes')} /><i className={classes.sidecheck}>/はい</i>
                            <FormControlLabel value="No" control={<Radio />} label={t('internalMedcine.No')} /><i className={classes.sidecheck}>/いいえ</i>

                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.specialrequestconcerningconsultation')}? <br /><i style={{color:"blue"}}>/診察でのご希望がある場合は、☑をしてください。</i></h5></strong></Paper>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.medicalexpenses}
                                onChange={handleChangeCheckSpecialRequest}
                                name="medicalexpenses"
                            />
                        }
                        label={t('internalMedcine.informedonestimatedexpenses')}
                    />
                    <nav style={{paddingTop:11, color:"blue"}}>/推定医療費を事前に知らせたい</nav>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.haveinterpreter}
                                onChange={handleChangeCheckSpecialRequest}
                                name="haveinterpreter"
                            />
                        }
                        label={t('internalMedcine.interpreterinterpreterservice')}
                    />
                    <nav style={{paddingTop:11, color:"blue"}}>/通訳サービスがあれば通訳が欲しいのですが</nav>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.otherssss}
                                onChange={handleChangeCheckSpecialRequest}
                                name="otherssss"
                            />
                        }
                        label={t('internalMedcine.Other')}
                        
                    />
                    
                    <Grid className="navCheckbox">
                    <span style={{color:"blue"}}>/(s)/その他</span>
                    </Grid>
                    
                </Grid>

            </Grid>
        </div>
    )
}
export default CenteredGrid;