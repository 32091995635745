import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray21() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(XrayContext);

    const [finish, setFinish] = React.useState('');

    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray21 - Leaving the room after the inspection has finished",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});
    
    const handleChangeFinish = event => {
        setFinish(event.target.value);
        setValues({ ...values, finish: event.target.value })
    };
   

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査終了後の退室({t('test.title.leaving')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.thatsAll}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>これで終了です。お疲れ様でした。<br />{t('test.leaving.thats all')}</h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.inspectionEnded}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>これで撮影は終わりです<br />{t('test.leaving.inspection ended')}</h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.stopByReception}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>着替えが終わりましたら、放射線部の受付に寄ってください<br />{t('test.leaving.stop by reception')}</h5> } />
 
                    </Paper>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.handThisIn}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ファイルを受付に出してください<br />{t('test.leaving.hand this in')}</h5>} />

                    </Paper>
                </Grid>
                
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray21;