import React, { useContext } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, FormControlLabel, Checkbox } from '@material-ui/core';
import { PediatricsContext } from '../context/pediatricsContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 1000,
        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    sidecheck: {
        paddingTop: 11,
        marginLeft: -13,
        marginRight: 90,
        color: "blue"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    label: {
        width: "100%",
        textAlign: "center",
        lineHeight: 1.5
    },

}));

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Form5 = () => {
    const { t, i18n } = useTranslation();


    const [values, setValues] = useContext(PediatricsContext);
    const classes = useStyles();

    const handleChangeCheckSpecialRequest = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked })

    };

    const handleChangeCheckSpecialRequest1 = (event) => {
        if (event.target.name === "MilkFeeding") {
            setValues({ ...values, MilkFeeding: event.target.checked, BabyFood: '', SolidFood: '' })
        } else if (event.target.name === "BabyFood") {
            setValues({ ...values, BabyFood: event.target.checked, MilkFeeding: '', SolidFood: '' })
        } else if (event.target.name === "SolidFood") {
            setValues({ ...values, SolidFood: event.target.checked, MilkFeeding: '', BabyFood: '' })
        }

    };

    const update = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    }

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
    };

    console.log(values.MilkFeeding)
    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20, flex: 1, }}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}><strong><h5>{t('Pediatric.Describefeedingmethod')} <br /><i style={{ color: "blue" }}>/ 現在の食事の形態を教えてください。</i></h5></strong></Paper>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: "1px solid black", marginLeft: 14, marginRight: 14 }}>
                    <Grid container item xs={12} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.MilkFeeding}
                                    onChange={handleChangeCheckSpecialRequest1}
                                    name="MilkFeeding"
                                />
                            }
                            label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.MilkFeeding')}</h6><i style={{ color: "blue", marginTop: -2 }}>/哺乳</i></div>}
                        />
                    </Grid>
                    <div >

                        {/* Milk feeding */}

                        {values.MilkFeeding === true ? (
                            <Grid container spacing={3} item xs={12} style={{ marginLeft: 60 }}>
                                <Grid item xs={12} style={{ display: "inherit" }}>
                                    <FormControl component="fieldset" >

                                        <RadioGroup row aria-label="gender" name="MilkFeedingType" value={values.MilkFeedingType} onChange={handleChange}>
                                            <FormControlLabel value="BreastFeedingOnly" control={<Radio />} label={t('Pediatric.BreastFeedingOnly')} /><i className={classes.sidecheck}>/母乳栄養</i>
                                            <FormControlLabel value="BreastPlusFormulaMilk" control={<Radio />} label={t('Pediatric.BreastPlusFormulaMilk')} /><i className={classes.sidecheck}>/混合栄養</i>
                                            <FormControlLabel value="FormulaMilkOnly" control={<Radio />} label={t('Pediatric.FormulaMilkOnly')} /><i className={classes.sidecheck}>/人工栄養</i>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>



                                <Grid container item xs={3} style={{ marginRight: 18, marginLeft: 34, marginTop: -34 }}>
                                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "80%" }}>
                                        <InputLabel htmlFor="mlday" style={{ left: 110 }}>{t('Pediatric.ml/day/')} <i style={{ color: "#0000ffbf" }}>日</i></InputLabel>
                                        <Input
                                            // disabled={() =>{if (values.breastfeedingOnly === true){ return true} }}
                                            id="mlday"
                                            type="number"
                                            values={values.mlday}
                                            defaultValue={values.mlday}
                                            onChange={e => update(e)}
                                            name="mlday"
                                        />
                                    </FormControl>

                                </Grid>


                                <Grid container item xs={3} style={{ marginRight: -20, marginLeft: 81, marginTop: -34 }}>
                                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%" }}>
                                        <InputLabel htmlFor="timeday" style={{ left: 100 }}>{t('Pediatric.time(s)/day')} <i style={{ color: "#0000ffbf" }}>/回/日</i></InputLabel>
                                        <Input
                                            // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                            // disabled={values.BreastPlusFormulaMilk}
                                            id="timeday"
                                            type="number"
                                            values={values.timeday}
                                            defaultValue={values.timeday}
                                            onChange={e => update(e)}
                                            name="timeday"
                                        />
                                    </FormControl>

                                </Grid>

                            </Grid>
                        ) : (
                                <>  </>
                            )}
                    </div>

                </Grid>

                {/* Baby food */}

                <Grid container item xs={12} style={{ borderBottom: "1px solid black", marginLeft: 14, marginRight: 14, display: "-webkit-box" }}>
                    <Grid container item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.BabyFood}
                                    onChange={handleChangeCheckSpecialRequest1}
                                    name="BabyFood"
                                />
                            }
                            label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.BabyFood')}</h6><i style={{ color: "blue", marginTop: -2 }}>/離乳食</i></div>}
                        />
                    </Grid>
                    <div >

                        {values.BabyFood === true ? (
                            <Grid item xs={12}>
                                <Grid container spacing={3} item xs={12} style={{ marginLeft: 128 }}>

                                    <Grid item xs={12} style={{ display: "inherit" }}>
                                        <FormControl component="fieldset" >

                                            <RadioGroup row aria-label="gender" name="BabyFoodPeriod" value={values.BabyFoodPeriod} onChange={handleChange}>
                                                <FormControlLabel value="FirstPeriod" control={<Radio />} label={t('Pediatric.FirstPeriod')} /><i className={classes.sidecheck}>/初期</i>
                                                <FormControlLabel value="SecondPeriod" control={<Radio />} label={t('Pediatric.SecondPeriod')} /><i className={classes.sidecheck}>/中期</i>
                                                <FormControlLabel value="ThirdPeriod" control={<Radio />} label={t('Pediatric.ThirdPeriod')} /><i className={classes.sidecheck}>/後期</i>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} item xs={12}>
                                    <Grid container style={{ marginLeft: 28, marginRight: 43 }} item xs={12}>
                                        <div style={{ display: "flex" }}>* <h6 style={{ fontWeight: 600 }}>{t('Pediatric.MealFrequency')}</h6><i style={{ color: "blue" }}>/食事回数</i></div>
                                    </Grid>

                                    <Grid item xs={12} style={{ display: "inherit" }}>
                                        <FormControl component="fieldset" >

                                            <RadioGroup row aria-label="gender" name="BabyFoodFrequency" value={values.BabyFoodFrequency} onChange={handleChange}>
                                                <FormControlLabel value="OnceADay" control={<Radio />} label={t('Pediatric.OnceADay')} /><i className={classes.sidecheck}>/1日1回</i>
                                                <FormControlLabel value="TwiceADay" control={<Radio />} label={t('Pediatric.TwiceADay')} /><i className={classes.sidecheck}>/1日2回</i>
                                                <FormControlLabel value="timesADay" control={<Radio />} label={t('Pediatric.timesADay')} /><i className={classes.sidecheck}>/1日3回</i>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid container item xs={3} style={{ width: '80%', marginTop: -46 }}>
                                        <FormControl fullWidth className='' style={{ marginTop: 14, }}>
                                            <InputLabel htmlFor="mldayMealFrequency" style={{ left: 161 }}>{t('Pediatric.ml/day/')} <i style={{ color: "#0000ffbf" }}>日</i></InputLabel>
                                            <Input
                                                // disabled={() =>{if (values.breastfeedingOnly === true){ return true} }}
                                                id="mldayMealFrequency"
                                                type="number"
                                                values={values.mldayMealFrequency}
                                                defaultValue={values.mldayMealFrequency}
                                                onChange={e => update(e)}
                                                name="mldayMealFrequency"
                                            />
                                        </FormControl>

                                    </Grid>


                                    <Grid container item xs={3} style={{ width: '80%', marginTop: -46 }}>
                                        <FormControl fullWidth className='' style={{ marginTop: 14, }}>
                                            <InputLabel htmlFor="timedayMealFrequency" style={{ left: 100 }}>{t('Pediatric.time(s)/day')} <i style={{ color: "#0000ffbf" }}>/回/日</i></InputLabel>
                                            <Input
                                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                                // disabled={values.BreastPlusFormulaMilk}
                                                id="timedayMealFrequency"
                                                type="number"
                                                values={values.timedayMealFrequency}
                                                defaultValue={values.timedayMealFrequency}
                                                onChange={e => update(e)}
                                                name="timedayMealFrequency"
                                            />
                                        </FormControl>

                                    </Grid>

                                </Grid>
                            </Grid>

                        ) : (
                                <>  </>
                            )}
                    </div>


                </Grid>

                {/* Solid Food */}

                <Grid container item xs={12} style={{ borderBottom: "1px solid black", marginLeft: 14, marginRight: 14, display: "-webkit-box" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.SolidFood}
                                onChange={handleChangeCheckSpecialRequest1}
                                name="SolidFood"
                            />
                        }
                        label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.SolidFood')}</h6><i style={{ color: "blue", marginTop: -2 }}>/固形食</i></div>}
                    />

                    <div>
                        {values.SolidFood === true ? (
                            <Grid container>
                                <Grid container style={{ marginLeft: 28, marginRight: 43 }} item xs={12}>
                                    <div style={{ display: "flex" }}>* <h6 style={{ fontWeight: 600 }}>{t('Pediatric.MealFrequency')}</h6><i style={{ color: "blue" }}>/食事回数</i></div>
                                </Grid>
                                <Grid container spacing={3} item xs={12} style={{ marginLeft: 128 }}>

                                <Grid item xs={12} style={{ display: "inherit" }}>
                                        <FormControl component="fieldset" >

                                            <RadioGroup row aria-label="gender" name="SolidFoodType" value={values.SolidFoodType} onChange={handleChange}>
                                                <FormControlLabel value="OnceADaySolidFood" control={<Radio />} label={t('Pediatric.OnceADay')} /><i className={classes.sidecheck}>/1日1回</i>
                                                <FormControlLabel value="TwiceADaySolidFood" control={<Radio />} label={t('Pediatric.TwiceADay')} /><i className={classes.sidecheck}>/1日2回</i>
                                                <FormControlLabel value="timesADaySolidFood" control={<Radio />} label={t('Pediatric.timesADay')} /><i className={classes.sidecheck}>/1日3回</i>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    
                                </Grid>

                            </Grid>
                        ) : (
                                <>  </>
                            )}
                    </div>
                </Grid>
            </Grid>

        </div >
    );
};

export default Form5;