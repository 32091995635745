import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import FormLabel from '@material-ui/core/FormLabel';
import { PediatricsContext } from '../context/pediatricsContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 1000,
        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    sidecheck: {
        paddingTop: 11,
        marginLeft: -13,
        marginRight: 5,
        color: "blue"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    label: {
        width: "100%",
        textAlign: "center",
        lineHeight: 1.5
    }
}));

const Form4 = () => {
    const { t, i18n } = useTranslation();

    const onmedications = [
        { name: t('Pediatric.Antitussives'), text: '/ 鎮咳薬(咳の薬)', value: "Coldmedicine" },
        { name: t('Pediatric.Expectorants'), text: '/ 去痰薬(痰の薬)', value: "Feverreducer" },
        { name: t('Pediatric.AntiAllergy'), text: '/ 抗アレルギー薬(鼻水の薬)', value: "AAntiAllergy" },
        { name: t('Pediatric.BowelControl'), text: '/ 整腸剤', value: "BowelControl" },
        { name: t('Pediatric.AntiNausear'), text: '/吐き気止め', value: "AntiNausear" }
    ]

    const problem = [
        { name: t('Pediatric.BreechPresentation'), text: '/ 骨盤位', value: "Breech presentation" },
        { name: t('Pediatric.LoopOfUmbilicalCord'), text: '/ 臍帯巻絡', value: "Loop of umbilical cord" },
        { name: t('Pediatric.CaesareanSection'), text: '/ 帝王切開', value: "Caesarean section" },
        { name: t('Pediatric.NeonatalAsphyxia'), text: '/ 新生児仮死', value: "Neonatal asphyxia" },

    ]

    const [values, setValues] = useContext(PediatricsContext);
    const classes = useStyles();

    const handleToggleMedicationType = (object) => () => {
        const currentIndex = values.onmedications.indexOf(object.value);
        const newChecked = [...values.onmedications];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setValues({ ...values, onmedications: newChecked })
    };

    const handleToggleDescribProblem = (object) => () => {
        const currentIndex = values.conditionAtBirths.indexOf(object.value);
        const newChecked = [...values.conditionAtBirths];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setValues({ ...values, conditionAtBirths: newChecked })
    };

    const handleChange = (event) => {
        if (event.target.value === "Yes") {
            setValues({ ...values, onMedication: event.target.value })
        } else {
            setValues({ ...values, onMedication: event.target.value, onmedications: [] })
        }
    };

    const handleChangeConditionAtBirth = (event) => {
        if (event.target.value === "Yes") {
            setValues({ ...values, conditionAtBirth: event.target.value })
        } else {
            setValues({ ...values, conditionAtBirth: event.target.value, conditionAtBirths: [] })
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20, flex: 1, }}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}><strong><h5>{t('Pediatric.currentlyonmedication')} <br /><i style={{ color: "blue" }}>/ 現在、飲んでいる薬はありますか？　※ビタミン、栄養剤、サプリメントも含みます。</i></h5></strong></Paper>
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="gender" name="onMedication" value={values.onMedication} onChange={handleChange}>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" /><i className={classes.sidecheck}>/はい</i>
                            <FormControlLabel value="No" control={<Radio />} label="No" /><i className={classes.sidecheck}>/いいえ</i>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid style={{ width: "inherit", flex: 1 }}>
                    <div >
                        {values.onMedication === 'Yes' ? (
                            <div>
                                <FormLabel className={classes.label}><strong>{t('internalMedcine.currentlyonmedicationyesno')}  <br /><i style={{ color: "blue" }}>/「はい」に☑した人は、当てはまるものに、、チェックをしてください</i></strong></FormLabel>
                                <GridList style={{ height: 80, paddingLeft: 20, paddingRight: 20, paddingTop: 10, width: "inherit" }} cellHeight={10} className={classes.gridList} cols={4}>
                                    {onmedications.map((value) => {
                                        const labelId = `checkbox-list-label-${value.name}`;

                                        return (
                                            <ListItem key={value.name} role={undefined} dense button onClick={handleToggleMedicationType(value)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={values.onmedications.indexOf(value.value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} >
                                                    {value.name}
                                                    <span style={{ color: "blue" }}>{value.text}</span>
                                                </ListItemText>

                                            </ListItem>
                                        );
                                    })}
                                </GridList>
                            </div>
                        ) : (
                                <>  </>
                            )}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ marginTop: -20 }}><strong><h5>{t('Pediatric.WriteAboutYourConditionsAtBirth')}? <br /><i style={{ color: "blue" }}>/現在治療中、または過去の病気があればお書きください。</i></h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <strong><h6 style={{ textAlign: "left" }}>{t('Pediatric.DidYouHaveAnyProblemsdDuringLaborOrDelivery')} <i style={{ color: "blue" }}>/分娩時に異常がありましたか。</i></h6></strong>
                </Grid>

                <Grid style={{ padding: 0 }} item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="gender" name="conditionAtBirth" value={values.conditionAtBirth} onChange={handleChangeConditionAtBirth}>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" /><i className={classes.sidecheck}>/はい</i>
                            <FormControlLabel value="No" control={<Radio />} label="No" /><i className={classes.sidecheck}>/いいえ</i>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid style={{ width: "inherit", flex: 1 }}>
                    <div>
                        {values.conditionAtBirth === 'Yes' ? (
                            <div>
                                <FormLabel className={classes.label}><strong>{t('Pediatric.IfYesDescribeTheProblem')}  <br /><i style={{ color: "blue" }}>/「はい」に☑された方は、どのような異常があったか書いてください。</i></strong></FormLabel>
                                <GridList style={{ height: 80, paddingLeft: 20, paddingRight: 20, paddingTop: 10, width: "inherit" }} cellHeight={10} className={classes.gridList} cols={4}>
                                    {problem.map((value) => {
                                        const labelId = `checkbox-list-label-${value.name}`;

                                        return (
                                            <ListItem key={value.name} role={undefined} dense button onClick={handleToggleDescribProblem(value)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={values.conditionAtBirths.indexOf(value.value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} >
                                                    {value.name}
                                                    <span style={{ color: "blue" }}>{value.text}</span>
                                                </ListItemText>

                                            </ListItem>
                                        );
                                    })}
                                </GridList>
                            </div>
                        ) : (
                                <>  </>
                            )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Form4;