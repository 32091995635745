import React, { useEffect, useState, useContext } from 'react';
import { CovidContext } from "./context/covid19Context"
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import Footer from "./footer";
import Nav from "./nav";
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from "axios"
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 20,

    },
    h4: {
        fontSize: 20,
        textAlign: "center"
    },
    paper1: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
}));

function createData(name, calories) {
    return { name, calories};
}

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 45 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const Centered = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = useContext(CovidContext);
    const history = useHistory();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const base_url = process.env.REACT_APP_BACKEND_URL;

    const rows = [
        createData('checkBoxApplicable1', values.checkBoxApplicable1),
        createData('checkBoxApplicable2', values.checkBoxApplicable2),
        createData('checkBoxApplicable3', values.checkBoxApplicable3),
        createData('haveSymptoms1', values.haveSymptoms1),
        createData('Cough', values.Cough),
        createData('Phlegm', values.Phlegm),
        createData('Shortnessofbreath', values.Shortnessofbreath),
        createData('Senseoffatigue', values.Senseoffatigue),
        createData('Abnormalityinthesenseofsmell', values.Abnormalityinthesenseofsmell),
        createData('Unabletoappreciatetaste', values.Unabletoappreciatetaste),
        createData('VomitingNausea', values.VomitingNausea),
        createData('Diarrhea', values.Diarrhea),
        createData('Noappetite', values.Noappetite)
    ];
    const initValue = {
        checkBoxApplicable1:"false",
        checkBoxApplicable2:"false",
        checkBoxApplicable3:"false",
        haveSymptoms1:"false",
        Cough:"false",
        Phlegm:"false",
        Shortnessofbreath:"false",
        Senseoffatigue:"false",
        Abnormalityinthesenseofsmell:"false",
        Unabletoappreciatetaste:"false",
        VomitingNausea:"false",
        Diarrhea:"false",
        Noappetite:"false",
        email : '',
        emailCheck : '',
        language:''
    }

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
        console.log(values)
    };

const handleBack = () => {
    history.push("/");
}
const init = () => {
    setValues(initValue)
    history.push("/homepage");
}


    const handleConfirm = () => {
        try{
            setValues({ ...values, language: i18n.language })
           // axios.post('http://localhost:5000/mail/covid19', values)
             axios.post(base_url+'/mail/covid19', values)
                .then(res => {
                     //history.push("/");
                     // window.location = '/homepage';
                    
                 });
                 setOpen(true);
            //window.location = '/covid19Result';
        }catch(error){
            console.log(error)
        }
       
    }

    

    const body = (
        <div style={modalStyle} className={classes.paper1}>
          <h4 id="simple-modal-title"><span style={{fontSize:16}}>{t("covid-19.COVI-19Question")}</span> <i style={{ color: "blue", fontSize:16}}>/新型コロナウイルス感染症に関する問診票</i></h4>
          <p id="simple-modal-description">
            Your inteview form has been send to the following email: <span style={{fontWeight:"bold"}}>{values.email} </span>
          </p>
          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={init}
                                            className={classes.button}
                                        >
                                          OK 
                                        </Button>
        </div>
      );

      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
            <Nav />
            <Container component="main" maxWidth="lg">
            <CssBaseline />
                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.PersonnalInformation')}</h4></strong>
                </Grid>

               
                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table" size="small">

                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        <strong>{t("covid-19." + row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={JSON.parse(row.calories)}
                                                    onChange={handleChange}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                        />

                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container>
                <Grid item xs={6} style={{ textAlign: "left", paddingTop:10}}>
                        <Button color="p-Light" variant="contained" style={{ width: 150 }} onClick={handleBack}>
                        {t('internalMedcine.Back') }
                    </Button>
                    </Grid>
                <Grid item xs={6} style={{ textAlign: "right", paddingTop:10}}>
                        <Button color="primary" variant="contained" style={{ width: 150 }} onClick={handleConfirm}>
                        {t('internalMedcine.confirm') }
                    </Button>
                    </Grid>
                    </Grid>
                   
            </Container>
            <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                        >
                                            {body}
                                        </Modal>
            <Footer />
        </div>
    )
}
export default Centered;