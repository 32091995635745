import React, { useState, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Nav from '../nav';
import Footer from "../footer";
import { useTranslation } from 'react-i18next';
import Form2 from './form2'
import Form1 from './form1'
import Form3 from './form3'
import Form4 from './form4'
import Form5 from './form5'
import Form6 from './form6'
import Form7 from './form7'
import Form8 from './form8'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { ObstetricContext } from '../context/ObstetricgynecologyContext';
import axios from "axios"
import Errormsg from '../auth/errormsg';
import Modal from '@material-ui/core/Modal';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function getSteps() {
    return ['', '', '', '', '', '', '', ''];
}

function GetStepContent({ componentRef, activeStep, name, setValues }) {
    //console.log("this is the name "+ values.name);

    switch (activeStep) {
        case 0:
            return <Form1 />;
        case 1:
            return <Form2 />;
        case 2:
            return <Form3 />;
        case 3:
            return <Form4 />;
        case 4:
            return <Form5 />;
        case 5:
            return <Form6 />;
        case 6:
            return <Form7 />;
        case 7:
            return <Form8 />;
        default:
            return 'Unknown step';
    }
}

export default function HorizontalLinearStepper() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const [values, setValues] = React.useState({});
    const [datas, setDatas] = useContext(ObstetricContext);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const componentRef = useRef();
    const [error, setError] = useState("");
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const base_url = process.env.REACT_APP_BACKEND_URL;

    const initValue = {
        name: '',
        lastName: '',
        firstName: '',
        DateOfBirth: new Date(),
        height: '',
        weight: '',
        sex: '',
        email: '',
        emailCheck: '',
        allergis: {
            foods: [],
            medcine: [],
        },
        problemtoday: [],
        menstrualPeriod: {
            ageStart: '',
            ageLast: '',
            daysCycle: '',
            irregular: false,
            periodsLast: '',
            usualFlow: '',
            painduringyourperiods: false,
            painkiller: '',
            dateLastPeriod: new Date(),
        },
        haveSexualIntercourse: false,
        hadUterineCancerTest: false,
        dateHadUterineCancerTest: new Date(),
        takenBirthControlPills: false,
        pregnantOrPossiblyPregnant: '',
        noWeeks: '',
        doNotKnow: false,
        Areyoubreastfeeding: false,
        PregnantHistory: '',
        YesPregnancyHistory: [{
            date: new Date(),
            delivery: "",
            hadMiscarriage: "",
            hadAbnomalPregnancy: "",
            weeksPregnanncy: ""
        }],
        problemDuringDelivery: false,
        problemDelivery: [],
        haveBabyAtThisHuspital: false,
        symptomLike: false,
        symptomLikeValue: [],
        symptomstartDate: new Date(),
        symptomStartTime: "",

        onMedication: '',
        onmedications: [],

        doctorcare: '',
        doctorCare: [],

        hadsurgerys: [],
        hadsurgery: '',

        smokeregularly: '',
        smokeday: {
            amount: '',
            duration: '',
            yearStop: '',
        },
        drinkregularly: '',
        drinkeday: {
            beer: false,
            nobeer: '',
            japsake: false,
            nojapsake: '',
            wisky: false,
            nowisky: '',
            wine: false,
            nowine: '',
            other: false,
            noOther: '',
        },
        medicalexpenses: false,
        haveinterpreter: false,
        otherssss: false
    }

    const isStepOptional = (step) => {
        return step === 10;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = (e) => {
        e.preventDefault();
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = () => {
        //console.log(datas)
        try {
            // axios.post(base_url + '/mail/send', datas)
            //     .then(res => {
            //         console.log(res.data)
            //         // window.locatlion = '/homepage';
            //     });
            setOpen(true);
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
            console.log(err)
        }

    }

    const init = () => {
        setDatas(initValue)
        //console.log(initValue);
        history.push("/homepage");
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
          <h4 id="simple-modal-title"><span style={{fontSize:16}}>{t("internalMedcine.InternalMedicineQuestionnaire")}</span> <i style={{ color: "blue", fontSize:16}}>/内科 問診票</i></h4>
          <p id="simple-modal-description">
          {t("internalMedcine.formSend")} <span style={{fontWeight:"bold"}}>{datas.email} </span>
          </p>
          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={init}
                                            className={classes.button}
                                        >
                                           {t("internalMedcine.Ok")} 
                                        </Button>
        </div>
      );

      const handleClose = () => {
        setOpen(false);
      };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container >
                <div className={classes.root}>
                    <Nav />
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
            </Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
            </Button>
                            </div>
                        ) : (
                                <div>
                                    <div className={classes.instructions}>

                                        { /*{getStepContent(activeStep, values, classes, t, setValues, setSelectedDate, selectedDate)}*/}
                                        <div>
                                            <i>{t("obstetricGynecology.ObstetricsandGynecologyQuestionnaire")}</i><i style={{ color: "blue" }}>/産婦人科 問診票</i>
                                        </div>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                        >
                                            {body}
                                        </Modal>

                                        <GetStepContent activeStep={activeStep} name={values.name} setValues={setValues} />

                                    </div>
                                    <div>
                                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        {t("internalMedcine.Back")}
              </Button>
                                        {isStepOptional(activeStep) && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSkip}
                                                className={classes.button}
                                            >
                                                {t("internalMedcine.Skip")}
                                            </Button>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? t("test.title.finish") : t("internalMedcine.Next")}
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment>
    );
}


