import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { SpychiatryContext } from '../context/spychiatryContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,
       
        textAlign: 'center',
        margin:10,
        padding:3
      },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const problem = [
        { name: t('spychiatry.Insomnia'), text:' /不眠',  value: 'Insomnia' },
        { name: t('spychiatry.Anxiety'), text:' /不安', value: 'Anxiety' },
        { name: t('spychiatry.Nervous'), text:'/緊張する', value: 'Nervous' },
        { name: t('spychiatry.Feeldepressed'), text:'/気分が滅入る', value: 'Feel depressed' },
        { name: t('spychiatry.Lowenergy'), text:'/やる気が出ない', value: 'Low energy' },
        { name: t('spychiatry.Loweredconcentration'), text:'/集中力低下', value: 'Lowered concentration' },
        { name: t('spychiatry.Problemwithfamily'), text:'/家庭の悩み', value: 'Problem with family' },
        { name: t('spychiatry.Problematwork'), text:'/職場の悩み', value: 'Problem at work' },
        { name: t('spychiatry.Problemwithschool'), text:'/学校の悩み', value: 'Problem with school' },
        { name: t('spychiatry.Abnormalappetite'), text:'/食欲異常', value: 'Abnormal appetite' },
        { name: t('spychiatry.Fearofotherpeople'), text:'/人が怖い', value: 'Fear of other people' },
        { name: t('spychiatry.Auditoryhallucination'), text:'/幻聴・幻覚', value: 'Auditory hallucination' },
        { name: t('spychiatry.Palpitation'), text:'/動悸', value: 'Palpitation' },
        { name: t('spychiatry.Havephysicalsymptoms'), text:'/身体の症状がある', value: 'Have physical symptoms' },
        { name: t('spychiatry.likewantodie'), text:'/死にたくなる', value: 'I feel like I want to die.' },
        { name: t('spychiatry.Difficultybreathing'), text:'/息苦しさ', value: 'Difficulty breathing' },
        { name: t('spychiatry.Sweatinthehands'), text:'/手に汗をかく', value: 'Sweat in the hands' },
        { name: t('spychiatry.bodymovesspontaneously'), text:'/身体が勝手に動く', value: 'The body moves spontaneously' },
        { name: t('spychiatry.Loseweight'), text:'/体重が減る', value: 'Lose weight' },
        { name: t('spychiatry.Cannotmovethebody'), text:'/体重が減る', value: 'Cannot move the body' },
        { name: t('gastroenterology.Other'), text:'/その他', value: 'Other(s)' },
        { name: t('gastroenterology.advisedbyanotherclinic'), text:'/他の医療機関から受診するように勧められた(健診含む)', value: 'I was advised by another clinic/hospital (or at a regular check-up) to come here.' },
    ]

    const symptomlikes = [
        { name: t('internalMedcine.Constant'), text:'/ 絶え間なく、続いている', value: "Constant" },
        { name: t('internalMedcine.symptomcomesgoes'), text:'/ 症状が出たり消えたりしている', value: "symptomcomesgoes" },
        { name: t('internalMedcine.symptomgraduallyworsening'), text:'/ 絶え間なく、続いている', value: "symptomgraduallyworsening" },
        { name: t('internalMedcine.Other'), text:'/ その他', value: "Other" },
    ]

    const [values, setValues] = useContext(SpychiatryContext);
    const classes = useStyles();
    const [checkedStool, setCheckedStool] = React.useState([]);
    const [frequency, setFrequency] = React.useState('');

    const handleToggleProblem = (value) => () => {
        const currentIndex = values.problemtoday.indexOf(value.value);
        const newChecked = [...values.problemtoday];

        if (currentIndex === -1) {
            newChecked.push(value.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        
        
    };

    const handleToggleSymptomlikes = (object) => () => {
        const currentIndex = values.symptomlike.indexOf(object.value);
        const newChecked = [...values.symptomlike];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setValues({...values, symptomlike:newChecked})
    };

    const updateFrequency = (event) => {
        setFrequency(event.target.value);               
        setValues({...values, stoolfrequency:event.target.value});
        
    };

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.problemtoday')}<br/><i style={{color:"blue"}}>/今日はどのような症状がありますか。（複数ある方は複数☑してください.)</i></h4></strong></Paper>
                    </Grid>
                    
                    <Grid  style={{ width:"inherit", flex:1 }}>
                    <GridList  style={{  width:"inherit", height: 310, paddingLeft: 20,paddingRight: 20}} cellHeight={10} className={classes.gridList} cols={3}>
                        {problem.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem  key={value.name} role={undefined} dense button onClick={handleToggleProblem(value)}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            checked={values.problemtoday.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </GridList>

                    
                        </Grid>

                        <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>Check all that apply about your stool. <br/><i style={{color:"blue"}}>/ 便の性状に☑してください</i></h4></strong></Paper>
                    </Grid>
                        
                    <GridList container style={{height:107, padding: 20, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {symptomlikes.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleSymptomlikes(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.symptomlike.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                    </GridList>
                    <Grid item xs={6}>
                    <FormControl fullWidth xs={8} className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t('internalMedcine.Stoolfrequency')}<i style={{color:"#0000ffbf"}}>/ 一日の排便回数：</i></InputLabel>
                        <Select 
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.stoolfrequency}
                            onChange={updateFrequency}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>
                    </Grid>

            </div>
        </div>
    )
}
export default CenteredGrid;