import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
     root: {
        width: '100%',
        flexGrow: 1,
        textAlign:"center"
    },
    button: {
        margin: "25px",
        marginLeft: "29px",
        color: "white",
        fontSize: "26px",
        backgroundColor: "#F87053",
        minHeight: "175px",
        minWidth: "300px !important",
        borderRadius: "46px",
        "&:hover": {
            background: "#F9B1B1",
            textDecoration: "none"
           },
           "&:focus": {
            border:"none",
            outline:"none"
        }  
    },
    button2: {
        margin: "25px",
        marginLeft: "29px",
        color: "white",
        fontSize: "26px",
        backgroundColor: "#FFD306",
        minHeight: "175px",
        minWidth: "300px !important",
        borderRadius: "46px",
        "&:hover": {
            background: "#FFE66F",
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    link: {
        "&:hover": {
        textDecoration: "none"
        }
      },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: "center"
    },
    paper: {
        marginTop: theme.spacing(8),
        alignItems: 'center',
    },
    content: {
        paddingBottom: "600px",
        paddingTop: "39px"
    }
}));

function DialectTest() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (

        <div>
             <Nav />   
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>
                        <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                            {t('PleaseTell')}:
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <Container component="main" maxWidth="lg">
             <CssBaseline />
                <div className={classes.paper} >
                    <div className={classes.form}>
                        <Link to="/wakayama" className={classes.link}><Button className={classes.button} variant="contained" color="primary" disableElevation > {t('Wakayama')}</Button></Link>
                        <Link to="/okinawa" className={classes.link}><Button className={classes.button2} variant="contained" color="primary" disableElevation> {t('Okinawa')}</Button></Link>
                    </div>
                </div>
           {/* <div className={classes.foot}>
                <AppBar position="static" style={{ backgroundColor: "#eb5757b5", }}>
                    <Toolbar>
                        <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "133px" }}>
                            Copyright 2019 ETL (Eiko Takaoka Laboratory, Sophia University)
                            </Typography>
                    </Toolbar>
                </AppBar>
            </div> */}
            </Container>
            <Footer />
        
        </div>
    );
}
export default DialectTest;


