// JavaScript source code
import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserContext from '../context/UserContext';



const useStyles = makeStyles((theme) => ({
    button: {
        margin: "25px",
        marginLeft: "29px",
        color: "black",
        fontSize: "26px",
        backgroundColor: "#99ff99",
        minHeight: "175px",
        minWidth: "28%",
        borderRadius: "46px",
    },
   
    content: {
        paddingBottom: "11%",
        paddingTop: "11%",
    },
     link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
}));

function InspectionHome() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "Inspection Home page",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

    return (
        <div >
            <Nav />
           

            <div className={classes.content} style={{}}>


                <Link to="/ct1" className={classes.link}>
                    <Button className={classes.button} variant="contained" disableElevation > ＣＴ検査<br />{t('test.title.CT')}
                    </Button>
                </Link>
                <Link to="/mri1" className={classes.link}>
                    <Button className={classes.button} variant="contained" disableElevation> ＭＲＩ検査<br />{t('test.title.MRI')}
                    </Button>
                </Link>
                <Link to="/xray1" className={classes.link}>
                    <Button className={classes.button} variant="contained" disableElevation> 単純Ｘ線<br />{t('test.title.Xray')}
                    </Button>
                </Link>

            </div>
            <Footer />

        </div>
    );
}

export default InspectionHome;