import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from "axios"
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        //paddingBottom: "10%",
        //paddingTop: "15%",
    },

    foot:{
        bottom: 0
    },

    div1: {
        width: "95%",
        height: "auto",
        fontSize: "26px",
        backgroundColor: "#6CAFED",
        margin: "20px",
        marginLeft: "20px",
        borderRadius:"10px"
    },

     div2: {
        width: "95%",
         height: "fit-content",
         fontSize: "26px",
         backgroundColor: "#B4C0CB",
         margin: "20px",
         marginLeft: "20px",
         paddingBottom:"20px",
         borderRadius: "10px"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p1: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "44px",
        fontWeight:"bold",
        color: "white"
    },
    p2: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "22px",
        fontWeight: "bold",
        color: "white"
    },
    p3: {
        textAlign: "left",
        marginTop: "20px",
        marginLeft: "20px",
        fontWeight: "bold"
    },
    p4: {
        textAlign: "left",
        marginLeft: "80px"
    }
}));

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}


function WordDetails4() {

    const classes = useStyles();
    const { t, i18n } = useTranslation();;
    const base_url = process.env.REACT_APP_BACKEND_URL;
    const words = (decodeURI(getQueryVariable("word")));
    var way = words.split(",");
    const [values, setValues] = useState({
        text1: '',
        w1: way[0] || "",
        w2: way[1] || "",
        w3: way[2] || "",
        w4: way[3] || "",
        w5: way[4] || "",
        w6: way[5] || "",
    })
    const request = {       
        words: words,
        type: 2
    }
    const setText1 = (event) => {
        setValues({ ...values, text1: event.target.value });
    }
    const history = useHistory();
    const find = () => {
        var findVal = values.text1
        history.push("/wordDetails3?find=" + findVal);
    }

  //      axios.post(base_url + '/userAddWords/addWords', request);

        return (
            <div>            
                 <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                Word Detail
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
                <CssBaseline />   
                <div className={classes.content}>
                    <TextField className={classes.textFiled} defaultValue={values.text1} onChange={setText1} id="text1" variant="outlined" label="Input your word" style={{ width: "62%", marginTop: "20px", paddingLeft: "10px", borderRadius: "15px" }} />
                    <Button className={classes.button} variant="contained" color="primary" disableElevation style={{ marginTop: "20px" }} onClick={find}> {t('search')}</Button>
                     <Link to="/okinawadic"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ backgroundColor: "#B4C0CB", marginRight: "20px", marginTop: "20px" }} > {t('back')}</Button></Link>
                     <div className={classes.div1} id="div1">
                            <p className={classes.p1}> Sorry, we can't find the word</p >
                            <p className={classes.p2}> Do you want to find words like these:</p >
                            <Link to={"/wordDetails3?find=" + values.w1} className={classes.p2}><p>{values.w1}</p></Link>
                            <Link to={"/wordDetails3?find=" + values.w2} className={classes.p2}><p>{values.w2}</p></Link>  
                            <Link to={"/wordDetails3?find=" + values.w3} className={classes.p2}><p>{values.w3}</p></Link>  
                            <Link to={"/wordDetails3?find=" + values.w4} className={classes.p2}><p>{values.w4}</p></Link>  
                            <Link to={"/wordDetails3?find=" + values.w5} className={classes.p2}><p>{values.w5}</p></Link>  
                            <Link to={"/wordDetails3?find=" + values.w6} className={classes.p2}><p>{values.w6}</p></Link>  
                    </div >
                </div>
                </Container>
                <Footer />
            </div>
        );
   }

export default WordDetails4;
