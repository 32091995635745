import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from '../ctContext '
import CTimage1 from '../img/ct-image1.jpg';
import CTimage2 from '../img/ct-image2.jpg';
import CTimage3 from '../img/ct-image3.jpg';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from '@material-ui/pickers';
import { textAlign } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Ct6() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(CtContext);
    const [date, setDate] = useState('')
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct6 - During inspection",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

    const updateDate = (date) => {
        setDate(date)
        setValues({ ...values, DateOfBirth: date })
    }

    const [painFromNeedle, setPainFromNeedle] = React.useState('');

    const handleChangePainFromNeedle = event => {
        setPainFromNeedle(event.target.value);
        setValues({ ...values, painFromNeedle: event.target.value })
    };

    const [playCTjp2_1] = useSound('/sound/2/jp2/2-1.mp3');
    const [playCTjp2_2] = useSound('/sound/2/jp2/2-2.mp3');
    const [playCTjp2_3] = useSound('/sound/2/jp2/2-3.mp3');
    const [playCTjp2_4] = useSound('/sound/2/jp2/2-4.mp3');
    const [playCTjp2_5] = useSound('/sound/2/jp2/2-5.mp3');
    const [playCTjp2_6] = useSound('/sound/2/jp2/2-6.mp3');
    const [playCTjp2_7] = useSound('/sound/2/jp2/2-7.mp3');
    const [playCTjp2_8] = useSound('/sound/2/jp2/2-8.mp3');
    const [playCTjp2_9] = useSound('/sound/2/jp2/2-9.mp3');
    const [playCTjp2_10] = useSound('/sound/2/jp2/2-10.mp3');
    const [playCTjp2_11] = useSound('/sound/2/jp2/2-11.mp3');
    const [playCTjp2_12] = useSound('/sound/2/jp2/2-12.mp3');
    const [playCTjp2_13] = useSound('/sound/2/jp2/2-13.mp3');
    const [playCTjp2_14] = useSound('/sound/2/jp2/2-14.mp3');
    const language = i18n.language;
    const [playCTmulti2_1] = useSound('/sound/2/' + language + '2/2-1.mp3');
    const [playCTmulti2_2] = useSound('/sound/2/' + language + '2/2-2.mp3');
    const [playCTmulti2_3] = useSound('/sound/2/' + language + '2/2-3.mp3');
    const [playCTmulti2_4] = useSound('/sound/2/' + language + '2/2-4.mp3');
    const [playCTmulti2_5] = useSound('/sound/2/' + language + '2/2-5.mp3');
    const [playCTmulti2_6] = useSound('/sound/2/' + language + '2/2-6.mp3');
    const [playCTmulti2_7] = useSound('/sound/2/' + language + '2/2-7.mp3');
    const [playCTmulti2_8] = useSound('/sound/2/' + language + '2/2-8.mp3');
    const [playCTmulti2_9] = useSound('/sound/2/' + language + '2/2-9.mp3');
    const [playCTmulti2_10] = useSound('/sound/2/' + language + '2/2-10.mp3');
    const [playCTmulti2_11] = useSound('/sound/2/' + language + '2/2-11.mp3');
    const [playCTmulti2_12] = useSound('/sound/2/' + language + '2/2-12.mp3');
    const [playCTmulti2_13] = useSound('/sound/2/' + language + '2/2-13.mp3');
    const [playCTmulti2_14] = useSound('/sound/2/' + language + '2/2-14.mp3');
    
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査時({t('test.title.during inspection')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link} ><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.keepPlipDuring}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>受付票をお預かりいたします<br />{t('test.during.keep slip')}</h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlipDuring}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5>} />
                        &emsp;
                        <img src={CTimage1} width="400" hight="300"/>
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.layDownWithShoesOn}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を履いたまま仰向けに寝てください<br />{t('test.during.lay down with shoes on')}</h5>} />
                        &emsp;
                        <img src={CTimage2} />
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.lieFaceUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いで仰向けに寝てください<br />{t('test.during.lie face up')}</h5>} />
 
                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.usePillow}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちらが枕になります<br />{t('test.during.use pillow')}</h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.fastenTheBands}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>安全のために、お体をバンドで軽く押さえます<br />{t('test.during.fasten the bands')}</h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.fullname}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>確認のため、お名前をフルネームで教えていただけますか？<br />{t('test.during.fullname')}</h5>} />

                    </Paper>

                </Grid >

                <Grid item xs={12} >
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid item xs={9}>
                            <h5>生年月日を教えていただけますか？<br />{t('test.during.birth date')}</h5>
                        </Grid>
                        <Grid item xs={3}>
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>
                           
                            <KeyboardDatePicker
                                
                                margin="normal"
                                id="date-picker-dialog"
                                label={t('test.Dateofbirth')}
                                format="yyyy/MM/dd"
                                value={values.DateOfBirth}
                                onChange={updateDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />

                  
                            </MuiPickersUtilsProvider> 
                        </Grid> 
                      </Grid> 
                   </Paper>
                </Grid>
         
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.explainCT}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>それではCT検査の説明をしていきます
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.explain CT')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_1} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.raiseYourHands}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>まず両手を頭の上まで拳上し、肘を曲げないよう伸ばしてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.raise your hands')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_2} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
                        <img src={CTimage3} />
                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.touchYou}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ポジショニングのため、お体を触らせていただきます
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.touch you')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_3} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.hearDirentions}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影時に、装置のほうから呼吸のアナウンスが入ります
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.hear direntions')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_4} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.breatheInAndHold}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って止めてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.breathe in and hold')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_5} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                {t('test.pleaseRelax')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_7} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.breatheInThenOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って吐いて止めてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.breathe in then out')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_6} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                {t('test.pleaseRelax')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_7} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.feelHotSensation}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お薬（造影剤）注入時にお体が火照る感じがします
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.feel hot sensation')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_8} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.informWhenDonotFeelWell}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>もしご気分が悪くなってしまった場合はすぐ教えてください
                                 <IconButton aria-label="Play/pause" onClick={playCTjp2_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.inform when donot feel well')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_9} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.startCT}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>それでは検査をはじめていきます
                                 <IconButton aria-label="Play/pause" onClick={playCTjp2_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.start CT')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_10} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.finishedInjecting}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>只今、お薬（造影剤）を注入しております
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_11}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.finished injecting')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_11} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>点滴の針先は痛くないですか？
                        <IconButton aria-label="Play/pause" onClick={playCTjp2_12}>
                            <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                        </IconButton>
                        <br />{t('test.during.pain from needle')}
                        <IconButton aria-label="Play/pause" onClick={playCTmulti2_12} >
                            <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                        </IconButton>
                        </h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="painFromNeedle" name="painFromNeedle" value={values.painFromNeedle} onChange={handleChangePainFromNeedle}>
                            <FormControlLabel value="pain" control={<Radio />} label={<h5>{t('test.during.pain')}<br />痛い</h5>} />
                            <FormControlLabel value="nopain" control={<Radio />} label={<h5>{t('test.during.not pain')}<br />痛くない</h5>}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.everythingOk}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ご気分はお変わりないでしょうか？
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.everything ok')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_13} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.hearAnnouncement}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>もう一度呼吸のアナウンスが入ります
                                <IconButton aria-label="Play/pause" onClick={playCTjp2_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.hear announcement')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti2_14} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
         </Grid>
            <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary">CT HOME</Button></Link>
                <Link to="ct7" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                </div>
            <Footer />

        </div>
                
    );
}
 
export default Ct6;