import React, {useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { MedContext } from '../context/internalMedContext';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 20,

    },
    h4: {
        fontSize: 20,
        textAlign: "center"
    }
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



const CenteredGrid = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [values, setValues] = useContext(MedContext);

    const rows = [
        createData('FirstName', values.name),
        createData('LastName', values.name),
        createData('Dateofbirth', values.DateOfBirth.toLocaleDateString()),
        createData('Height', values.height),
        createData('Weight', values.weight),
        createData('Sex', values.sex),
    ];

    const rows0 = [
        createData('problemtoday1', values.problemtoday),
    ];

    const rows1 = [
        createData('FoodAllergie', values.allergis.foods),
        createData('MedicineAllergie', values.allergis.medcine),
    ];

    const rows22 = [
        createData('StoolTextureColore', values.stools),
    ]

    const rows2 = [
        createData('Stoolfrequency', values.stoolfrequency),
    ]

    const rows33 = [
        createData('whereSymptomsOccure', values.bodyPart),
        createData('symptomoccur', values.symptomoccur),
        createData('symptomlike', values.symptomlike),
    ]

    const rows3 = [
        createData('symptomScale', values.scale1to10),
        createData('symptomstart', values.symptomstart.toLocaleDateString()),
        createData('symptomStartTime', values.symtomstarttime.toLocaleTimeString()),

    ]

    const rows4 = [
        createData('currentlyonmedication', values.onMedication),
        createData('medication', values.onmedications),
    ]

    const rows5 = [
        createData('underdoctorcare', values.doctorcare),
        createData('ConditionDoctorCare', values.doctorCare),
    ]

    const rows6 = [
        createData('hadsurgery', values.hadsurgery),
        createData('placeWhereSurgeryTookPlace', values.hadsurgerys),
    ]

    const rows7 = [
        createData('smokeregularly', values.smokeregularly),
        createData('Cigaretteconsumption', values.smokeday.amount),
        createData('Durationofsmoking', values.smokeday.duration),
        createData('yearStopSmooking', values.smokeday.yearStop),
    ]

    const rows8 = [
        createData('Beer', values.drinkeday.beer, values.drinkeday.nobeer,),
        createData('Japanesesake', values.drinkeday.japsake, values.drinkeday.japsake,),
        createData('Whisky', values.drinkeday.wisky, values.drinkeday.nowisky,),
        createData('Wine', values.drinkeday.wine, values.drinkeday.nowine,),
        createData('Other', values.drinkeday.other, values.drinkeday.noOther,),
    ]

    const rows9 = [
        createData('pregnantOrPossiblyPregnant', values.pregnant),
        createData('Areyoubreastfeeding', values.breastfeeding),
        createData('informedonestimatedexpenses', values.medicalexpenses),
        createData('interpreterinterpreterservice', values.haveinterpreter),
        createData('Others', values.otherssss),
    ]
console.log(values.haveinterpreter)
    return (
        <div ref={ref}>
            <Container component="main" maxWidth="md">
                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.PersonnalInformation')}</h4></strong>
                </Grid>

                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{row.calories}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                       <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.problemtoday1')}<br/><i style={{color:"blue"}}>/今日はどのような症状がありますか。</i></h4></strong>
                </Grid>

                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows0.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    {values.problemtoday !== "" ?(
                                        
                                        row.calories.map((item, i) =>(
                                    <TableCell key={i}>
                                        {t('internalMedcine.'+item)}
                                        </TableCell>
                                        ))
                                        
                                        ) : (
                                            <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                        )}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.Allergie')} <i style={{color:"blue"}}>/アレルギー</i></h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows1.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    {values.allergis.foods !== "" || values.allergis.medcine !== "" ?(
                                        
                                        row.calories.map((item, i) =>(
                                    <TableCell key={i}>
                                        {t('internalMedcine.'+item)}
                                        </TableCell>
                                        ))
                                        
                                        ) : (
                                            <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                        )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.allApplyStool')} <i style={{color:"blue"}}>/便の性状に☑してください</i></h4></strong>
                </Grid>
                    
                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows2.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows22.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    {values.stools !== "" ?(
                                        
                                        row.calories.map((item, i) =>(
                                    <TableCell key={i}>
                                        {t('internalMedcine.'+item )}
                                        </TableCell>
                                        ))
                                        
                                        ) : (
                                            <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                        )}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.Symptoms')}</h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows33.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    {values.bodyPart !== "" ?(
                                        
                                        row.calories.map((item, i) =>(
                                    <TableCell key={i}>
                                        {t('internalMedcine.'+item )}
                                        </TableCell>
                                        ))
                                        
                                        ) : (
                                            <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                        )}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows3.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                
                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.currentlyonmedication')} </h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows4.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.underdoctorcare')}</h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows5.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.hadsurgery')}</h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows6.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.smokeregularly')}</h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            {rows7.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                    <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.drinkregularly')}</h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell>{t('registration.name')}</TableCell>
                                <TableCell>{t('registration.yesNo')}</TableCell>
                                <TableCell align="left">{t('registration.Quantity')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows8.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                    <TableCell align="left">{t('internalMedcine.'+row.fat)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={12}>
                   <strong className={classes.h4}><h4 className={classes.paper}>{t('internalMedcine.Iffemale')} <br /><i style={{color:"blue"}}>/女性の方のみお答えください。妊娠していますか、またその可能性はありますか。</i></h4></strong>
                </Grid>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows9.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                    <strong>{t('internalMedcine.'+row.name)}</strong>
                                    </TableCell>
                                    {row.calories == true ?(
                                    <TableCell align="left">{t('internalMedcine.yes')}</TableCell>
                                    ):(
                                    <TableCell align="left">{t('internalMedcine.'+row.calories)}</TableCell>
                                    )
                                    
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    )
});
export default CenteredGrid;