import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { MriContext } from "../mriContext";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from '@material-ui/pickers';
import { textAlign } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Mri5() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(MriContext);
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "mri5 - During inspection",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});
    

    const [date, setDate] = useState('')
    const updateDate = (date) => {
        setDate(date)
        setValues({ ...values, DateOfBirth: date })
    }

    const [painFromNeedle, setPainFromNeedle] = React.useState('');

    const handleChangePainFromNeedle = event => {
        setPainFromNeedle(event.target.value);
        setValues({ ...values, painFromNeedle: event.target.value })
    };
    const language = i18n.language;
    const [playMRImulti4_1] = useSound('/sound/4/'+language+'4/4-01.mp3');
    const [playMRImulti4_2] = useSound('/sound/4/' + language + '4/4-02.mp3');
    const [playMRImulti4_3] = useSound('/sound/4/' + language + '4/4-03.mp3');
    const [playMRImulti4_4] = useSound('/sound/4/' + language + '4/4-04.mp3');
    const [playMRImulti4_5] = useSound('/sound/4/' + language + '4/4-05.mp3');
    const [playMRImulti4_6] = useSound('/sound/4/' + language + '4/4-06.mp3');
    const [playMRImulti4_7] = useSound('/sound/4/' + language + '4/4-07.mp3');
    const [playMRImulti4_8] = useSound('/sound/4/' + language + '4/4-08.mp3');
    const [playMRImulti4_9] = useSound('/sound/4/' + language + '4/4-09.mp3');
    const [playMRImulti4_10] = useSound('/sound/4/' + language + '4/4-10.mp3');
    const [playMRImulti4_11] = useSound('/sound/4/' + language + '4/4-11.mp3');
    const [playMRImulti4_12] = useSound('/sound/4/' + language + '4/4-12.mp3');
    const [playMRImulti4_13] = useSound('/sound/4/' + language + '4/4-13.mp3');
    const [playMRImulti4_14] = useSound('/sound/4/' + language + '4/4-14.mp3');
    const [playMRImulti4_15] = useSound('/sound/4/' + language + '4/4-15.mp3');
    const [playMRImulti4_16] = useSound('/sound/4/' + language + '4/4-16.mp3');
    const [playMRImulti4_17] = useSound('/sound/4/' + language + '4/4-17.mp3');
    const [playMRImulti4_18] = useSound('/sound/4/' + language + '4/4-18.mp3');
    const [playMRImulti4_19] = useSound('/sound/4/' + language + '4/4-19.mp3');
    const [playMRImulti4_20] = useSound('/sound/4/' + language + '4/4-20.mp3');
    const [playMRImulti4_21] = useSound('/sound/4/' + language + '4/4-21.mp3');
    
    const [playMRIjp4_1] = useSound('/sound/4/jp4/4-01.mp3');
    const [playMRIjp4_2] = useSound('/sound/4/jp4/4-02.mp3');
    const [playMRIjp4_3] = useSound('/sound/4/jp4/4-03.mp3');
    const [playMRIjp4_4] = useSound('/sound/4/jp4/4-04.mp3');
    const [playMRIjp4_5] = useSound('/sound/4/jp4/4-05.mp3');
    const [playMRIjp4_6] = useSound('/sound/4/jp4/4-06.mp3');
    const [playMRIjp4_7] = useSound('/sound/4/jp4/4-07.mp3');
    const [playMRIjp4_8] = useSound('/sound/4/jp4/4-08.mp3');
    const [playMRIjp4_9] = useSound('/sound/4/jp4/4-09.mp3');
    const [playMRIjp4_10] = useSound('/sound/4/jp4/4-10.mp3');
    const [playMRIjp4_11] = useSound('/sound/4/jp4/4-11.mp3');
    const [playMRIjp4_12] = useSound('/sound/4/jp4/4-12.mp3');
    const [playMRIjp4_13] = useSound('/sound/4/jp4/4-13.mp3');
    const [playMRIjp4_14] = useSound('/sound/4/jp4/4-14.mp3');
    const [playMRIjp4_15] = useSound('/sound/4/jp4/4-15.mp3');
    const [playMRIjp4_16] = useSound('/sound/4/jp4/4-16.mp3');
    const [playMRIjp4_17] = useSound('/sound/4/jp4/4-17.mp3');
    const [playMRIjp4_18] = useSound('/sound/4/jp4/4-18.mp3');
    const [playMRIjp4_19] = useSound('/sound/4/jp4/4-19.mp3');
    const [playMRIjp4_20] = useSound('/sound/4/jp4/4-20.mp3');
    const [playMRIjp4_21] = useSound('/sound/4/jp4/4-21.mp3');

    const [playMRIjp2_5] = useSound('/sound/2/jp2/2-5.mp3');
    const [playMRIjp2_7] = useSound('/sound/2/jp2/2-7.mp3');
    const [playMRImulti2_5] = useSound('/sound/2/'+language+'2/2-5.mp3');
    const [playMRImulti2_7] = useSound('/sound/2/' + language + '2/2-7.mp3');
   
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査時({t('test.title.during inspection')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.holdKey}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>更衣室の鍵をお預かりいたします
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.hold key')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_1} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.checkOneAnyMetals}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>もう一度確認です。金属類、貼り薬などはないでしょうか。
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.check one any metals')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_2} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
                       
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.lieFaceUpMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いでこちらに頭がくるよう仰向けに寝てください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.lie face up')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_3} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
                       
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.lieFaceDownMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いでこちらに頭がくるようにうつ伏せに寝てください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.lie face down')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_4} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.fastenTheBandsMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>安全のために、お体をバンドで軽く押さえます
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.fasten the bands')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_5} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.wearHeadphones}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>音がうるさいのでヘッドホンをします
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.wear headphones')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_6} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.fullnameMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>確認のため、お名前をフルネームで教えていただけますか？
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.during.fullname')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_7} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >

                <Grid item xs={12} >
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid item xs={9}>
                                <h5>生年月日を教えていただけますか？
                                    <IconButton aria-label="Play/pause" onClick={playMRIjp4_8}>
                                        <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                    </IconButton>
                                    <br />{t('test.during.birth date')}
                                    <IconButton aria-label="Play/pause" onClick={playMRImulti4_8} >
                                        <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                    </IconButton></h5>
                        </Grid>
                        <Grid item xs={3}>
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>
                           
                            <KeyboardDatePicker
                                
                                margin="normal"
                                id="date-picker-dialog"
                                label={t('test.Dateofbirth')}
                                format="yyyy/MM/dd"
                                value={values.DateOfBirth}
                                onChange={updateDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />

                  
                            </MuiPickersUtilsProvider> 
                        </Grid> 
                      </Grid> 
                   </Paper>
                </Grid>
         
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.explainMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>それでは検査の説明をしていきます
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.explain MRI')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_9} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.notMakeMovement}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>動きに弱い検査なので動かないように注意してください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.not make movement')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_10} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
                       
                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.ringBuzzer}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>途中で気分が悪いなど検査が続けられないと感じた際にはこのブザーを握ってください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_11}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.ring buzzer')}<IconButton aria-label="Play/pause" onClick={playMRImulti4_11} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.plaseAFilmingDevice}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影するための機械を身体の上に置きます
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_12}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.plase a filming device')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_12} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.touchYou}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ポジショニングのため、お体を触らせていただきます
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.touch you')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_13} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.duringMRIhearDirentions}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影時に、装置のほうから呼吸のアナウンスが入ります
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.duringMRI hear Directions')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_14} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.breatheInAndHold}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って止めてください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp2_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp2_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.breathe in and hold')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti2_5} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                {t('test.pleaseRelax')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti2_7} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                            </h5>}/>
                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.injectContrastMedium}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お薬（造影剤）は検査の途中で注入します
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_15}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.inject the contrast medium')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_15} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.pressBuzzer}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>もしご気分が悪くなってしまった場合はすぐブザーで教えてください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_16}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.press the buzzer')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_16} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.startMRI}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>それでは検査をはじめていきます
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_17}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.start MRI')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_17} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.finishedInjecting}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>只今、お薬（造影剤）を注入しております
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_18}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.finished injecting')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_18} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>点滴の針先は痛くないですか？
                        <IconButton aria-label="Play/pause" onClick={playMRIjp4_19}>
                            <PlayCircleOutlineIcon color="primary" fontSize="large" />
                        </IconButton>
                        <br />{t('test.during.pain from needle')}
                        <IconButton aria-label="Play/pause" onClick={playMRImulti4_19} >
                            <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                        </IconButton></h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="painFromNeedle" name="painFromNeedle" value={values.painFromNeedle} onChange={handleChangePainFromNeedle}>
                            <FormControlLabel value="pain" control={<Radio />} label={<h5>{t('test.during.pain')}<br />痛い</h5>} />
                            <FormControlLabel value="nopain" control={<Radio />} label={<h5>{t('test.during.not pain')}<br />痛くない</h5>}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.everythingOk}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ご気分はお変わりないでしょうか？
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_20}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.everything ok')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_20} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.donotMove}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>動きが強いので動かないようにお願いします
                                <IconButton aria-label="Play/pause" onClick={playMRIjp4_21}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.do not move')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti4_21} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="mri1" className={classes.link}><Button className={classes.button} variant="contained" color="primary">MRI HOME</Button></Link>
                <Link to="mri6" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                </div>
            <Footer />

        </div>
                
    );
}
 
export default Mri5;