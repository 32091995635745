import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from '../ctContext '
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Ct5() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(CtContext);

    const [alcoholAllergies, setAlcoholAllergies] = React.useState('');
    const [feelNumbness, setFeelNumbness] = React.useState('');
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct5 - Line Keep",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

    const handleChangeAlcoholAllergies = event => {
        setAlcoholAllergies(event.target.value);
        setValues({ ...values, alcoholAllergies: event.target.value })
    };
    const handleChangeFeelNumbness = event => {
        setFeelNumbness(event.target.value);
        setValues({ ...values, feelNumbness: event.target.value })
    };
    const language = i18n.language;
    const [playCTmulti1_1] = useSound('/sound/1/'+language+'1/1-1.mp3');
    const [playCTmulti1_2] = useSound('/sound/1/' + language + '1/1-2.mp3');
    const [playCTmulti1_3] = useSound('/sound/1/' + language + '1/1-3.mp3');
    const [playCTmulti1_4] = useSound('/sound/1/' + language + '1/1-4.mp3');
    const [playCTmulti1_5] = useSound('/sound/1/' + language + '1/1-5.mp3');
    const [playCTmulti1_6] = useSound('/sound/1/' + language + '1/1-6.mp3');
    const [playCTmulti1_7] = useSound('/sound/1/' + language + '1/1-7.mp3');
    const [playCTmulti1_8] = useSound('/sound/1/' + language + '1/1-8.mp3');
    const [playCTmulti1_9] = useSound('/sound/1/' + language + '1/1-9.mp3');

    const [playCTjp1_1] = useSound('/sound/1/jp1/1-1.mp3');
    const [playCTjp1_2] = useSound('/sound/1/jp1/1-2.mp3');
    const [playCTjp1_3] = useSound('/sound/1/jp1/1-3.mp3');
    const [playCTjp1_4] = useSound('/sound/1/jp1/1-4.mp3');
    const [playCTjp1_5] = useSound('/sound/1/jp1/1-5.mp3');
    const [playCTjp1_6] = useSound('/sound/1/jp1/1-6.mp3');
    const [playCTjp1_7] = useSound('/sound/1/jp1/1-7.mp3');
    const [playCTjp1_8] = useSound('/sound/1/jp1/1-8.mp3');
    const [playCTjp1_9] = useSound('/sound/1/jp1/1-9.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            ラインキープ({t('test.title.linekeep')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.restYourArm}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>点滴する方の腕をこの手台にのせてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />   {t('test.linekeep.rest your arm')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_1} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
                        
                    
                        
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.rubberBand}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>腕にゴムバンドをします
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.rubber band')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
                           
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.tightFist}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>手を握ってください
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.tight fist')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
 
                    </Paper>
                    
                </Grid>
            <Grid item xs={12}>

               <Paper className={classes.paper}>
                  <FormControlLabel
                            value={values.streilize}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>消毒をします
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.streilize')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>アルコールアレルギーはないですか？
                        <IconButton aria-label="Play/pause" onClick={playCTjp1_5}>
                            <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                        </IconButton>
                        <br />{t('test.linekeep.alcohol allergies')}
                        <IconButton aria-label="Play/pause" onClick={playCTmulti1_5}>
                            <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                        </IconButton>
                        </h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="alcoholAllergies" name="alcoholAllergies" value={values.alcoholAllergies} onChange={handleChangeAlcoholAllergies}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>    
                <Grid item xs={12}>

                 <Paper className={classes.paper}>
                  <FormControlLabel
                            value={values.injenting}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>針を刺します
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.injenting')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.relaxYourHand}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>手を楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.relax your hand')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>手にしびれ等はないですか？
                        <IconButton aria-label="Play/pause" onClick={playCTjp1_7}>
                            <PlayCircleOutlineIcon color="primary" fontSize="large" />
                        </IconButton>
                        <br />{t('test.linekeep.feel any numbness')}
                        <IconButton aria-label="Play/pause" onClick={playCTmulti1_7}>
                            <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                        </IconButton>
                        </h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="feelNumbness" name="feelNumbness" value={values.feelNumbness} onChange={handleChangeFeelNumbness}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid> 
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.waitOverThere}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査の順番まであちらでお待ちください
                                <IconButton aria-label="Play/pause" onClick={playCTjp1_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.linekeep.wait over there')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti1_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />

                    </Paper>

                </Grid >
         </Grid>
            <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >CT HOME</Button></Link>
                <Link to="ct6" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Ct5;