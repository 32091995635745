import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

class ForEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: []
        }
    }


    componentDidMount() {
        const page = Number(getQueryVariable("page")) || 1;
        const _this = this;  
        const request = {
            words: decodeURI(getQueryVariable("letter")),
            page: page
        }
    //const base_url = "http://localhost:5000";
    const base_url = process.env.REACT_APP_BACKEND_URL;

        axios.post(base_url +'/okinawaPhrase/find', request)
            .then((response) => {
                console.log(response.data);
                _this.setState({
                    datas: response.data,
                });
            });
    }
    
    render() {
        return (
            this.state.datas.map((data, i) => {             
                if (data.remark != 2) {
                    return (
                        <div style={{ width: "95%", fontSize: "26px", backgroundColor: "#F2F2F2", margin: "20px", marginLeft: "20px" }}>
                            <Link to={'/editSentence?on=' + data.phraseOri + '&down=' + data.phraseAft} key={i} style={{ float: "right", marginRight: "10px", fontSize: "20px" }}>編集</Link>
                            <p style={{ textAlign: "left", marginLeft: "10px" }} key={i}>{data.phraseOri}</p >
                            <p style={{ textAlign: "left", marginLeft: "10px", color: "#6CAFED" }} key={i}>{data.phraseAft}</p>
                        </div>
                    )
                } else {
                    return (
                        <div style={{ width: "95%", fontSize: "26px", backgroundColor: "#F2F2F2", margin: "20px", marginLeft: "20px" }}>
                            <Link to={'/editSentence?on=' + data.phraseOri + '&down=' + data.phraseAft} key={i} style={{ float: "right", marginRight: "10px", fontSize: "20px", textDecoration: "none", pointerEvents: "none", color: "#B4C0CB" }}>編集</Link>
                            <p style={{ textAlign: "left", marginLeft: "10px" }} key={i}>{data.phraseOri}</p >
                            <p style={{ textAlign: "left", marginLeft: "10px", color: "#6CAFED" }} key={i}>{data.phraseAft}</p>
                        </div>
                    )
                }                
            })
        )
    }
}

export default ForEdit;