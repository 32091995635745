import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from "axios";
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        marginTop: "18px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        paddingBottom: "10%",
        //paddingTop: "10%"
    },
    div1: {
        marginLeft: "60px",
        //paddingLeft: "30px",
        width: "300px",
        fontSize: "26px",
        float: "left"
    },

    div2: {
        padding: "10px",
        fontSize: "26px",
        border: "1px solid #6CAFED",
        float: "left"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p: {
        textAlign: "center",
        fontSize: "22px",
        display: "inline"
    }

}));



function Dictionary() {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
    letter : '',
    count : '',
    remark : '',
    text1: ''
});
const history = useHistory();

const find =()=>{
    var findVal = values.text1
    history.push("/wordDetails?find=" + findVal);
}

const setText1=(event)=>{
    setValues({ ...values, text1: event.target.value });
}

//const base_url = "http://localhost:5000";
const base_url = process.env.REACT_APP_BACKEND_URL;

axios.get(base_url + '/wakayamaLetters').then((response) => {
    const list = response.data.length;
    //console.log(list);
    console.log(response.data[0].letter);
    //console.log(response.data[0].letter);
    for (var i = 0; i < list; i++) {
        if (response.data[i].count == 0) {
            //document.getElementById(response.data[0].letter).href = "javascript:return false";
            document.getElementById(response.data[i].letter).style = "color: #B4C0CB; text-decoration: none;pointer-events: none;";
        }
    }
}).catch((error) => {
    console.log(error);
});

        return (
            <div >
                 <Nav />
                
                
                    <div>
                        <AppBar position="static" style={{ backgroundColor: "#F9B1B1", width: "auto"}}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                Wakayama Dialect Dictionary 
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
             <CssBaseline />
                <div className={classes.content}>
                    <TextField className={classes.textFiled} defaultValue={values.text1} onChange={setText1} id="text1" variant="outlined" label="Input your word" style={{ width: "82%", marginTop: "20px", paddingLeft: "10px", borderRadius: "15px" }} />
                    <Button className={classes.button} variant="contained" color="primary" disableElevation  onClick={find}> {t('search')}</Button>
                    
                    <br></br>
                        <div style={{ width: "100%", margin: "10px" }}>
                            <AppBar position="static" style={{ backgroundColor: "#6CAFED", height: "50px", }}>
                                <Toolbar>
                                    <Typography className={classes.title} variant="h6" noWrap style={{ marginTop: "-15px", color: "white" }}>
                                        Index of phrases
                            </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className={classes.div2}>
                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=あ" className={classes.a} id="あ">< a >あ</a></Link>&nbsp;
                                <Link to="/showWords?letter=い" className={classes.a} id="い">< a >い</a></Link>&nbsp;
                                <Link to="/showWords?letter=う" className={classes.a} id="う">< a >う</a></Link>&nbsp;
                                <Link to="/showWords?letter=え" className={classes.a} id="え">< a >え</a></Link>&nbsp;
                                <Link to="/showWords?letter=お" className={classes.a} id="お">< a >お</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=か" className={classes.a} id="か">< a >か</a></Link>&nbsp;
                                <Link to="/showWords?letter=き" className={classes.a} id="き">< a >き</a></Link>&nbsp;
                                <Link to="/showWords?letter=く" className={classes.a} id="く">< a >く</a></Link>&nbsp;
                                <Link to="/showWords?letter=け" className={classes.a} id="け">< a >け</a></Link>&nbsp;
                                <Link to="/showWords?letter=こ" className={classes.a} id="こ">< a >こ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=さ" className={classes.a} id="さ">< a >さ</a></Link>&nbsp;
                                <Link to="/showWords?letter=し" className={classes.a} id="し">< a >し</a></Link>&nbsp;
                                <Link to="/showWords?letter=す" className={classes.a} id="す">< a >す</a></Link>&nbsp;
                                <Link to="/showWords?letter=せ" className={classes.a} id="せ">< a >せ</a></Link>&nbsp;
                                <Link to="/showWords?letter=そ" className={classes.a} id="そ">< a >そ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=た" className={classes.a} id="た">< a >た</a></Link>&nbsp;
                                <Link to="/showWords?letter=ち" className={classes.a} id="ち">< a >ち</a></Link>&nbsp;
                                <Link to="/showWords?letter=つ" className={classes.a} id="つ">< a >つ</a></Link>&nbsp;
                                <Link to="/showWords?letter=て" className={classes.a} id="て">< a >て</a></Link>&nbsp;
                                <Link to="/showWords?letter=と" className={classes.a} id="と">< a >と</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=な" className={classes.a} id="な">< a >な</a></Link>&nbsp;
                                <Link to="/showWords?letter=に" className={classes.a} id="に">< a >に</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぬ" className={classes.a} id="ぬ">< a >ぬ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ね" className={classes.a} id="ね">< a >ね</a></Link>&nbsp;
                                <Link to="/showWords?letter=の" className={classes.a} id="の">< a >の</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=は" className={classes.a} id="は">< a >は</a></Link>&nbsp;
                                <Link to="/showWords?letter=ひ" className={classes.a} id="ひ">< a >ひ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ふ" className={classes.a} id="ふ">< a >ふ</a></Link>&nbsp;
                                <Link to="/showWords?letter=へ" className={classes.a} id="へ">< a >へ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ほ" className={classes.a} id="ほ">< a >ほ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=ま" className={classes.a} id="ま">< a >ま</a></Link>&nbsp;
                                <Link to="/showWords?letter=み" className={classes.a} id="み">< a >み</a></Link>&nbsp;
                                <Link to="/showWords?letter=む" className={classes.a} id="む">< a >む</a></Link>&nbsp;
                                <Link to="/showWords?letter=め" className={classes.a} id="め">< a >め</a></Link>&nbsp;
                                <Link to="/showWords?letter=も" className={classes.a} id="も">< a >も</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=や" className={classes.a} id="や">< a >や</a></Link>&nbsp;
                                <Link to="/showWords?letter=ゆ" className={classes.a} id="ゆ">< a >ゆ</a></Link>&nbsp;
                                <Link to="/showWords?letter=よ" className={classes.a} id="よ">< a >よ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=ら" className={classes.a} id="ら">< a >ら</a></Link>&nbsp;
                                <Link to="/showWords?letter=り" className={classes.a} id="り">< a >り</a></Link>&nbsp;
                                <Link to="/showWords?letter=る" className={classes.a} id="る">< a >る</a></Link>&nbsp;
                                <Link to="/showWords?letter=れ" className={classes.a} id="れ">< a >れ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ろ" className={classes.a} id="ろ">< a >ろ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=わ" className={classes.a} id="わ">< a >わ</a></Link>&nbsp;
                                <Link to="/showWords?letter=を" className={classes.a} id="を">< a >を</a></Link>&nbsp;
                                <Link to="/showWords?letter=ん" className={classes.a} id="ん">< a >ん</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=が" className={classes.a} id="が">< a >が</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぎ" className={classes.a} id="ぎ">< a >ぎ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぐ" className={classes.a} id="ぐ">< a >ぐ</a></Link>&nbsp;
                                <Link to="/showWords?letter=げ" className={classes.a} id="げ">< a >げ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ご" className={classes.a} id="ご">< a >ご</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=ざ" className={classes.a} id="ざ">< a >ざ</a></Link>&nbsp;
                                <Link to="/showWords?letter=じ" className={classes.a} id="じ">< a >じ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ず" className={classes.a} id="ず">< a >ず</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぜ" className={classes.a} id="ぜ">< a >ぜ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぞ" className={classes.a} id="ぞ">< a >ぞ</a></Link>&nbsp;
                            </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=だ" className={classes.a} id="だ">< a >だ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぢ" className={classes.a} id="ぢ">< a >ぢ</a></Link>&nbsp;
                                <Link to="/showWords?letter=づ" className={classes.a} id="づ">< a >づ</a></Link>&nbsp;
                                <Link to="/showWords?letter=で" className={classes.a} id="で">< a >で</a></Link>&nbsp;
                                <Link to="/showWords?letter=ど" className={classes.a} id="ど">< a >ど</a></Link>&nbsp;
                             </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=ば" className={classes.a} id="ば">< a >ば</a></Link>&nbsp;
                                <Link to="/showWords?letter=び" className={classes.a} id="び">< a >び</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぶ" className={classes.a} id="ぶ">< a >ぶ</a></Link>&nbsp;
                                <Link to="/showWords?letter=べ" className={classes.a} id="べ">< a >べ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぼ" className={classes.a} id="ぼ">< a >ぼ</a></Link>&nbsp;
                             </div>

                                <div className={classes.div1}>
                                    <Link to="/showWords?letter=ぱ" className={classes.a} id="ぱ">< a >ぱ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぴ" className={classes.a} id="ぴ">< a >ぴ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぷ" className={classes.a} id="ぷ">< a >ぷ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぺ" className={classes.a} id="ぺ">< a >ぺ</a></Link>&nbsp;
                                <Link to="/showWords?letter=ぽ" className={classes.a} id="ぽ">< a >ぽ</a></Link>&nbsp;
                             </div>
                            </div>
                        </div>
                        <Link to="/translate"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ float: "right" }}> {t('translate')}</Button></Link>
                        <p className={classes.p}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <Link to="/wakayama"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ float: "right",backgroundColor: "#B4C0CB", marginRight: "20px" }} > {t('back')}</Button></Link>

            </div>
                </Container>
                <Footer />
            </div>
        );
   }

export default Dictionary;
