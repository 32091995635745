import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { Howl }  from 'howler';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from '../ctContext '
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
    
}));
function Ct7() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(CtContext);
    const language = i18n.language;
    const [playCTjp3_1] = useSound('/sound/3/jp3/3-1.mp3');
    const [playCTjp3_2] = useSound('/sound/3/jp3/3-2.mp3');
    const [playCTjp3_3] = useSound('/sound/3/jp3/3-3.mp3');
    const [playCTjp3_4] = useSound('/sound/3/jp3/3-4.mp3');
    const [playCTmulti3_1] = useSound('/sound/3/'+language+'3/3-1.mp3');
    const [playCTmulti3_2] = useSound('/sound/3/' + language + '3/3-2.mp3');
    const [playCTmulti3_3] = useSound('/sound/3/' + language + '3/3-3.mp3');
    const [playCTmulti3_4] = useSound('/sound/3/' + language + '3/3-4.mp3');
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct7 - After examination",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });
   
   
   
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査後({t('test.title.after examination')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link} ><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.ctComplete}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査は終わりになりますが、ご気分にお変わりはないですか？

                                <IconButton aria-label="Play/pause" onClick={playCTjp3_1} >
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                   
                                </IconButton>
                                <br />{t('test.after.CT is complete')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti3_1} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                    
                                </IconButton>
                                </h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlipAfter}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この受付票をお持ちください<br />{t('test.after.take slip')}</h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.waitUntilBedLowerd}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台が安全の高さに下がるまで寝たままでお待ちください
                                <IconButton aria-label="Play/pause" onClick={playCTjp3_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.after.wait until bed lowerd')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti3_2} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.lowerHand}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>両手は下げてください
                                <IconButton aria-label="Play/pause" onClick={playCTjp3_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.after.lower hand')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti3_3} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />
 
                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.bedlowerGetOff}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台が下がりましたので、こちら側からお降りください
                                <IconButton aria-label="Play/pause" onClick={playCTjp3_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.after.bed lower get off')}
                                <IconButton aria-label="Play/pause" onClick={playCTmulti3_4} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                </h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.accountFile}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>会計ファイルをお持ちください<br />{t('test.after.account file')}</h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.restInFrontRoom}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>点滴終了までご様子をうかがわせていただきますので前室で休んでいてください<br />{t('test.after.rest in front room')}</h5>} />

                    </Paper>

                </Grid >
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.informWhenFeelWorse}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>もしご気分が悪くなってしまった場合はすぐ教えてください<br />{t('test.after.inform when feel worse')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeOffIV}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>点滴が終わりましたので、点滴を抜かせていただきます<br />{t('test.after.take off IV')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.press5minutes}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この部分（止血部分）を5分ほど親指で強く抑えていてください<br />{t('test.after.press 5minutes')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.after5minutes}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>5分ほどして止血確認後、着替えていただいて結構です<br />{t('test.after.after 5minutes')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.followInstructions}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>他の検査があるようでしたら、その指示に従ってください<br />{t('test.after.follow instructions')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.drinkWater}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ほかに検査がないようでしたら、水分補給をお願いします<br />{t('test.after.drink water')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.changeClothes}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>先ほどと同じように更衣をしていただきます<br />{t('test.after.change clothes')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.gownInLaundry}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>更衣後の検査着はこちらのランドリーボックスにいれてください<br />{t('test.after.gown in laundry')}</h5>} />

                    </Paper>

                </Grid >
         </Grid>
            <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >CT HOME</Button></Link>
             </div>
            <Footer />

        </div>
                
    );
}
 
export default Ct7;