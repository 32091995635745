import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray6() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray6 - Abdomen",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti7_1] = useSound('/sound/7/'+language+'7/7-01.mp3');
    const [playXRAYmulti7_2] = useSound('/sound/7/' + language + '7/7-02.mp3');
    const [playXRAYmulti7_3] = useSound('/sound/7/' + language + '7/7-03.mp3');
    const [playXRAYmulti7_4] = useSound('/sound/7/' + language + '7/7-04.mp3');
    const [playXRAYmulti7_5] = useSound('/sound/7/' + language + '7/7-05.mp3');
    
    const [playXRAYjp7_1] = useSound('/sound/7/jp7/7-01.mp3');
    const [playXRAYjp7_2] = useSound('/sound/7/jp7/7-02.mp3');
    const [playXRAYjp7_3] = useSound('/sound/7/jp7/7-03.mp3');
    const [playXRAYjp7_4] = useSound('/sound/7/jp7/7-04.mp3');
    const [playXRAYjp7_5] = useSound('/sound/7/jp7/7-05.mp3');

    const [playXRAYjp6_5] = useSound('/sound/6/jp6/6-05.mp3');
    const [playXRAYmulti6_5] = useSound('/sound/6/' + language + '6/6-05.mp3');
    
    const [playXRAYjp2_6] = useSound('/sound/2/jp2/2-6.mp3');
    const [playXRAYmulti2_6] = useSound('/sound/2/' + language + '2/2-6.mp3');
    
    const [playXRAYjp6_7] = useSound('/sound/6/jp6/6-07.mp3');
    const [playXRAYmulti6_7] = useSound('/sound/6/' + language + '6/6-07.mp3');
    
    const [playXRAYjp4_3] = useSound('/sound/4/jp4/4-03.mp3');
    const [playXRAYmulti4_3] = useSound('/sound/4/' + language + '4/4-03.mp3');
    
    const [playXRAYjp10_6] = useSound('/sound/10/jp10/10-06.mp3');
    const [playXRAYmulti10_6] = useSound('/sound/10/' + language + '10/10-06.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            腹部({t('test.title.abdomen')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.photoOfStomach}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お腹の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of stomach')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.machineWillGoDown}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>前の機械がさがります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.machine will go down')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.abdomen.holdStickOfMachine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>機械の横の棒を握っていてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.hold stick of machine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                   </Paper>
                    
                </Grid>
               
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.giveAsignal}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸の合図をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.give a signal')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.breatheInThenOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って吐いて止めてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp2_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.breathe in then out')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti2_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.relaxYourBreath}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸を楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.Xray.relax your breath')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large"/>
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.lieFaceUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いでこちらに頭がくるように仰向けに寝てください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp4_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.during.lie face up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti4_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.putHandsOnTheSide}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>手は体のわきに置いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put hands on the side')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.touchYourBody}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>位置合わせのために、お体を動かしたり、触れたりします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.touch your body')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.abdomen.ifFeelPain}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>痛かったら、教えてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp10_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.if feel pain')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti10_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > Finish</Button></Link>
                 </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray6;