import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { PediatricsContext } from '../context/pediatricsContext'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 20,

    },
    heading: {
        fontSize: 17,
        textAlign: "center",
        marginTop: 0,
        marginBottom: 0
    }
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const Form8 = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [values, setValues] = useContext(PediatricsContext);


    const rows3 = [
        createData('problemtoday1', values.problemtoday),
    ];
    const rows31 = [
        createData('ifAnotherClinic', values.anotherClinic),
    ];
    console.log(values)
    return (
        <div>
            <Container component="main" maxWidth="md">

                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table" size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('internalMedcine.LastName')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/姓(ファミリーネーム)</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.lastName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('internalMedcine.FirstName')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/名(ファーストネーム</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.firstName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('test.Dateofbirth')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/生年月日</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.DateOfBirth.toLocaleDateString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table1" size="medium">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('internalMedcine.Height')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/身長</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.height}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('internalMedcine.Weight')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/体重</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.weight}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('internalMedcine.Sex')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/性別</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.sex}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>


                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table" size="medium">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <strong>{t('internalMedcine.FoodAllergie')}</strong><br /> <i style={{ color: "#0000ffbf" }}>/食物アレルギー</i>
                                        </TableCell>
                                        {values.allergis.foods !== "" || values.allergis.medcine !== "" ? (

                                            values.allergis.foods.map((item, i) => (
                                                <TableCell key={i}>
                                                    {t('internalMedcine.' + item)}
                                                </TableCell>
                                            ))

                                        ) : (
                                                <TableCell align="left">{t('internalMedcine.FoodAllergie')}</TableCell>
                                            )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table" size="medium">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <strong>{t('internalMedcine.MedicineAllergie')}</strong><br /> <i style={{ color: "#0000ffbf" }}>/薬アレルギー</i>
                                        </TableCell>
                                        {values.allergis.foods !== "" || values.allergis.medcine !== "" ? (

                                            values.allergis.medcine.map((item, i) => (
                                                <TableCell key={i}>
                                                    {t('internalMedcine.' + item)}
                                                </TableCell>
                                            ))

                                        ) : (
                                                <TableCell align="left">{t('internalMedcine.MedicineAllergie')}</TableCell>
                                            )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table">

                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>{t('Pediatric.problemtoday1')}</strong><br /><i style={{ color: "blue" }}>/今日はどのような症状がありますか。</i>
                                    </TableCell>
                                    {values.problemtoday !== "" ? (

                                        values.problemtoday.map((item, i) => (
                                            <TableCell key={i}>
                                                {t('Pediatric.' + item)}
                                            </TableCell>
                                        ))

                                    ) : (
                                            <TableCell align="left">{t('Pediatric.problemtoday1')}</TableCell>
                                        )}

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div>
                        {
                            values.problemtoday.map((row) => {
                                if (row === "advisedanotherclinic")
                                    return (
                                        <TableContainer component={Paper} >
                                            <Table className={classes.table} aria-label="simple table">

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <strong>{t('Pediatric.ifAnotherClinic')}</strong>
                                                        </TableCell>
                                                        {values.problemtoday !== "" ? (

                                                            row.calories.map((item, i) => (
                                                                <TableCell key={i}>
                                                                    {t('Pediatric.' + item)}
                                                                </TableCell>
                                                            ))

                                                        ) : (
                                                                <TableCell align="left">{t('Pediatric.ifAnotherClinic')}</TableCell>
                                                            )}

                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                            })
                        }
                    </div>
                </Grid>

                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table1" size="medium">

                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        <strong>{t('Pediatric.Haveanydiseasesbeenspreadinginyourschool')}</strong><br /><i style={{ color: "blue" }}>/学校や保育所、家族内で流行している病気はありますか。</i>
                                    </TableCell>
                                    <TableCell align="left">{values.diseasesSpreadSchool}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container item xs={12}>
                    {
                        values.diseasesSpreadSchool === "Yes" &&

                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <strong>{t('internalMedcine.currentlyonmedicationyesno')}</strong> <br /><i style={{ color: "blue" }}>/「はい」に☑した人は、当てはまるものに、、チェックをしてください。</i>
                                        </TableCell>
                                        {values.diseasesSpreadSchools !== "" ? (

                                            values.diseasesSpreadSchools.map((item, i) => (
                                                <TableCell key={i}>
                                                    {t('Pediatric.' + item)}
                                                </TableCell>
                                            ))

                                        ) : (
                                                <TableCell align="left">{t('internalMedcine.currentlyonmedicationyesno')}</TableCell>
                                            )}

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                    }
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table1" size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.SymptomStartDate')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/症状の始まった日</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.symptomstart.toLocaleDateString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table1" size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.SymptomStartTime')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/症状の始まった時間</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.symtomstarttime.toLocaleTimeString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table1" size="medium">

                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        <strong>{t('Pediatric.currentlyonmedication')}</strong><br /><i style={{ color: "blue" }}>/学現在、飲んでいる薬はありますか？　※ビタミン、栄養剤、サプリメントも含みます。</i>
                                    </TableCell>
                                    <TableCell align="left">{values.onMedication}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container item xs={12}>
                    {
                        values.onMedication === "Yes" &&

                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <strong>{t('internalMedcine.currentlyonmedicationyesno')}</strong> <br /><i style={{ color: "blue" }}>/「はい」に☑した人は、当てはまるものに、、チェックをしてください。</i>
                                        </TableCell>
                                        {values.onmedications !== "" ? (

                                            values.onmedications.map((item, i) => (
                                                <TableCell key={i}>
                                                    {t('internalMedcine.' + item)}
                                                </TableCell>
                                            ))

                                        ) : (
                                                <TableCell align="left">{t('internalMedcine.currentlyonmedicationyesno')}</TableCell>
                                            )}

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                    }
                </Grid>

                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table1" size="medium">

                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        <strong>{t('Pediatric.DidYouHaveAnyProblemsdDuringLaborOrDelivery')}</strong><br /><i style={{ color: "blue" }}>/分娩時に異常がありましたか。</i>
                                    </TableCell>
                                    <TableCell align="left">{values.onMedication}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container item xs={12}>
                    {
                        values.onMedication === "Yes" &&

                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table">

                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <strong>{t('internalMedcine.currentlyonmedicationyesno')}</strong> <br /><i style={{ color: "blue" }}>/「はい」に☑した人は、当てはまるものに、、チェックをしてください。</i>
                                        </TableCell>
                                        {values.onmedications !== "" ? (

                                            values.onmedications.map((item, i) => (
                                                <TableCell key={i}>
                                                    {t('internalMedcine.' + item)}
                                                </TableCell>
                                            ))

                                        ) : (
                                                <TableCell align="left">{t('internalMedcine.currentlyonmedicationyesno')}</TableCell>
                                            )}

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table1" size="medium">

                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" component="th" scope="row" style={{ fontSize: 23 }}>
                                        <strong>{t('Pediatric.Birth')}</strong><br /><i style={{ color: "blue" }}>/出生時</i>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table1" size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.weeks')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/週</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.WeeksPregnancyChildbirth}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.Weight')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/体重</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.Weight}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.HeadCircumference')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/頭囲</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.HeadCircumference}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} aria-label="simple table1" size="medium">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.days')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/日</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.DaysPregnancyChildbirth}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.Height')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/身長</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.Height}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 180 }}>
                                            <strong>{t('Pediatric.ChestCircumference')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/胸囲</i>
                                        </TableCell>
                                        <TableCell align="left" style={{ width: 180 }}>{values.ChestCircumference}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="simple table1" size="medium">

                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        <strong>{t('Pediatric.haveBeentreatedAtOtherhospital')}</strong><br /><i style={{ color: "blue" }}>/当院に来るまでに、ほかの病院にかかられましたか。。</i>
                                    </TableCell>
                                    <TableCell align="left">{values.haveBeentreatedAtOtherhospital}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container item xs={12}>
                    {
                        values.haveBeentreatedAtOtherhospital === "true" &&
                        <Grid item xs={12}>
                            <TableContainer component={Paper} >
                                <Table className={classes.table} aria-label="simple table1" size="medium">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                <strong>{t('Pediatric.HospitalName')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/医療機関名</i>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                <strong>{t('Pediatric.DateOfConsoltation1')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/受診日</i>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                <strong>{t('Pediatric.Prescription')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/処方の有無</i>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                <strong>{t('Pediatric.DiseaseNames')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/疾患名</i>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                <strong>{t('Pediatric.TreatmentProgress')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/治療経過</i>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    {
                        values.haveBeentreatedAtOtherhospital === "true" &&
                        values.YeshaveBeentreatedAtOtherhospital.map((field, idx) => {
                            return (
                                <Grid item xs={12}>
                                    <div key={`${field}-${idx}`}>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper} >
                                                <Table className={classes.table} aria-label="simple table1" size="medium">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="left" style={{ width: 180 }}>{field.HospitalName}</TableCell>
                                                            <TableCell align="left" style={{ width: 180 }}>{field.date.toLocaleDateString()}</TableCell>
                                                            <TableCell align="left" style={{ width: 180 }}>{field.prescription}</TableCell>
                                                            <TableCell align="left" style={{ width: 180 }}>{field.diseaseName}</TableCell>
                                                            <TableCell align="left" style={{ width: 180 }}>{field.treatmentProgress}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid>
                    {
                        values.MilkFeeding === true &&
                        <Grid container item xs={12}>
                            <TableContainer component={Paper} >
                                <Table className={classes.table} aria-label="simple table1" size="medium">

                                    <TableBody>
                                        <TableRow >
                                            <TableCell component="th" scope="row">
                                                <strong>{t('Pediatric.MilkFeeding')}</strong><br /><i style={{ color: "blue" }}>/哺乳</i>
                                            </TableCell>
                                            <TableCell align="left">{t('Pediatric.' + values.MilkFeedingType)}</TableCell>
                                            {/* <TableCell align="left">{values.MilkFeeding.toString()}</TableCell> */}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <TableContainer component={Paper} >
                                        <Table className={classes.table} aria-label="simple table1" size="medium">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                        <strong>{t('Pediatric.ml/day/')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/日</i>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: 180 }}>{values.mlday}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <TableContainer component={Paper} >
                                        <Table className={classes.table} aria-label="simple table1" size="medium">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                        <strong>{t('Pediatric.time(s)/day')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/回/日</i>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: 180 }}>{values.timeday}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid>
                    {
                        values.BabyFood === true &&
                        <Grid container item xs={12}>
                            <TableContainer component={Paper} >
                                <Table className={classes.table} aria-label="simple table1" size="medium">

                                    <TableBody>
                                        <TableRow >
                                            <TableCell component="th" scope="row">
                                                <strong>{t('Pediatric.BabyFood')}</strong><br /><i style={{ color: "blue" }}>/固形食</i>
                                            </TableCell>
                                            <TableCell align="left">{t('Pediatric.' + values.BabyFoodPeriod)}</TableCell>
                                            {/* <TableCell align="left">{values.BabyFood.toString()}</TableCell> */}

                                        </TableRow>
                                        <TableRow >
                                            <TableCell component="th" scope="row">
                                                <strong>{t('Pediatric.MealFrequency')}</strong><br /><i style={{ color: "blue" }}>/食事回数</i>
                                            </TableCell>
                                            <TableCell align="left">{t('Pediatric.' + values.BabyFoodFrequency)}</TableCell>
                                           
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <TableContainer component={Paper} >
                                        <Table className={classes.table} aria-label="simple table1" size="medium">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                        <strong>{t('Pediatric.ml/day/')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/日</i>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: 180 }}>{values.mldayMealFrequency}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <TableContainer component={Paper} >
                                        <Table className={classes.table} aria-label="simple table1" size="medium">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ width: 180 }}>
                                                        <strong>{t('Pediatric.time(s)/day')}</strong> <br /> <i style={{ color: "#0000ffbf" }}>/回/日</i>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: 180 }}>{values.timedayMealFrequency}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid>
                    {
                        values.SolidFood === true &&
                        <Grid container item xs={12}>
                            <TableContainer component={Paper} >
                                <Table className={classes.table} aria-label="simple table1" size="medium">

                                    <TableBody>
                                        <TableRow >
                                            <TableCell component="th" scope="row">
                                                <strong>{t('Pediatric.SolidFood')}</strong><br /><i style={{ color: "blue" }}>/固形食</i> <br/>
                                                <strong>{t('Pediatric.MealFrequency')}</strong><br /><i style={{ color: "blue" }}>/食事回数</i>
                                            </TableCell>
                                            <TableCell align="left">{t('Pediatric.' + values.SolidFoodType)}</TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                </Grid>
            </Container>
        </div>
    );
};

export default Form8;