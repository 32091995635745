import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import FormLabel from '@material-ui/core/FormLabel';
import { MedContext } from '../context/internalMedContext';
import UserContext from '../context/UserContext';
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    sidecheck:{
        paddingTop:11, 
        marginLeft:-13, 
        marginRight:5, 
        color:"blue"
    },
    gridList: {
        width: 1000,

        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    label: {
        width:"100%",
        textAlign:"center",
        lineHeight:1.4
    }
}));
const CenteredGrid = () => {
    const { t, i18n } = useTranslation();

    const hadsurgerys = [
        {name: t('internalMedcine.Eye'), text:'/ 目', value:"Eye"},
        {name: t('internalMedcine.Ear'), text:'/ 耳', value:"Ear"},
        {name: t('internalMedcine.Nose'), text:'/ 鼻', value:"Nose"},
        {name: t('internalMedcine.Mouth'), text:'/ 口', value:"Mouth"},
        {name: t('internalMedcine.Throat'), text:'/ のど', value:"Throat"},
        {name: t('internalMedcine.neck'), text:'/ 首', value:"Neck"},
        {name: t('internalMedcine.Brest'), text:'/ 乳房', value:"Brest"},
        {name: t('internalMedcine.Esophagus'), text:'/ 食道', value:"Esophagus"},
        {name: t('internalMedcine.Stomach'), text:'/ 胃', value:"Stomach"},
        {name: t('internalMedcine.Intestines'), text:'/ 腸', value:"Intestines"},
        {name: t('internalMedcine.Heart'), text:'/ 心臓', value:"Heart"},
        {name: t('internalMedcine.Cecum'), text:'/ 盲腸', value:"Cecum"},
        {name: t('internalMedcine.Liver'), text:'/ 肝臓', value:"Liver"},
        {name: t('internalMedcine.Pancreas'), text:'/ 膵臓', value:"Pancreas"},
        {name: t('internalMedcine.kidney'), text:'/ 腎臓', value:"kidney"},
        {name: t('internalMedcine.Ovary'), text:'/ 卵巣', value:"Ovary"},
        {name: t('internalMedcine.Uterus'), text:'/ 子宮', value:"Uterus"},
        {name: t('internalMedcine.Upperlowerlimb'), text:'/ 上下肢', value:"EUpperlowerlimbye"},
        {name: t('internalMedcine.Others'), text:'/ その他', value:"Others"}
       ]

    const onmedications = [
        {name: t('internalMedcine.Coldmedicine'), text:'/ かぜ薬', value:"Coldmedicine"},
        {name: t('internalMedcine.Feverreducer'), text:'/ 解熱剤', value:"Feverreducer"},
        {name: t('internalMedcine.Painkiller'), text:'/ 痛み止め', value:"Painkiller"},
        {name: t('internalMedcine.Anti-suppuration'), text:'/ 化膿止め', value:"Anti-inflammatory medicine"},
        {name: t('internalMedcine.Antibiotics'), text:'/抗生物質', value:"Antibiotics"}
       ]

       const ondoctorcares = [
        {name: t('internalMedcine.bronchialasthma'), text:'/ 気管支喘息', value:"bronchialasthma"},
        {name: t('internalMedcine.highbloodpressure'), text:'/ 高血圧', value:"highbloodpressure"},
        {name: t('internalMedcine.Diabetesmellitus'), text:'/ 糖尿病', value:"Diabetesmellitus"},
        {name: t('internalMedcine.Tuberculosis'), text:'/ 結核', value:"Tuberculosis"},
        {name: t('internalMedcine.Hearttrouble'), text:'/ 心臓病', value:"Hearttrouble"},
        {name: t('internalMedcine.HepatitisB'), text:'/ Ｂ型肝炎', value:"HepatitisB"},
        {name: t('internalMedcine.epatitisC'), text:'/ Ｃ型肝炎', value:"epatitisC"},
        {name: t('internalMedcine.collagenConnectiveTissuedisease'), text:'/ 膠原病', value:"collagenConnectiveTissuedisease"},
        {name: t('internalMedcine.Thyroiddisease'), text:'/ 甲状腺の病気', value:"Thyroiddisease"},
        {name: t('internalMedcine.Cancertumor'), text:'/ 血液の病気', value:"Cancertumor"},
        {name: t('internalMedcine.glaucoma'), text:'/ 緑内障', value:"glaucoma"},
        {name: t('internalMedcine.Gastrointestinaldisorder'), text:'/ 胃腸の病気', value:"BlooGastrointestinaldisorderddisease"},
        {name: t('internalMedcine.Gonorrhea'), text:'/ 淋病', value:"Gonorrhea"},
        {name: t('internalMedcine.Syphilis'), text:'/ 梅毒', value:"Syphilis"},
        {name: t('internalMedcine.cancer-tumor'), text:'/ 癌・腫瘍', value:"cancer/tumor"},
        {name: t('internalMedcine.strockCerebral'), text:'/ 脳卒中（脳梗塞・脳出血）', value:"Stroke (cerebral infarction / hemorrhage)"}
       ]

    const [values, setValues] = useContext(MedContext);
    const classes = useStyles();
    const [medication, setMedication] = React.useState('');
    const [medicationType, setMedicationType] = React.useState([]);
    const [doctorcares, setDoctorcares] = React.useState('');
    const [doctorcaresType, setDoctorcaresType] = React.useState([]);
    const [hadsurgery, setHadsurgerys] = React.useState('');
    const [hadsurgerysType, setHadsurgerysType] = React.useState([]);
    const { userData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    });
    
    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    const logFunction = async (question) =>{
        const loginfo = {
            inteviewName: "Internal Medcine form 5",
            //userName: userData.user.userName,
            language: i18n.language,
            contentSentence: question,
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
    } 

    const handleToggleHadsurgerysType = (object) => () => {
        const currentIndex = values.hadsurgerys.indexOf(object.value);
        const newChecked = [...values.hadsurgerys];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setHadsurgerysType(newChecked);
        setValues({...values, hadsurgerys:newChecked});
        logFunction("Have had surgery option");
    };

    const handleChangeHadsurgerys = (event) => {
        setHadsurgerys(event.target.value);
        if (event.target.value === 'No'){
            setValues({...values, hadsurgerys:[], hadsurgery:event.target.value});
        }else{
            setValues({...values, hadsurgery:event.target.value});
        }
       
        logFunction("Have had surgery");
    };


    const handleToggleMedicationType = (object) => () => {
        const currentIndex = values.onmedications.indexOf(object.value);
        const newChecked = [...values.onmedications];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setMedicationType(newChecked);
        setValues({...values, onmedications:newChecked});
        logFunction("Are you curently on any medication option");
    };

    const handleChange = (event) => {
        setMedication(event.target.value);
        if (event.target.value === 'No'){
            setValues({...values, onmedications:[], onMedication:event.target.value})
        }else{
            setValues({...values, onMedication:event.target.value}) 
        }
        logFunction("Are you curently on any medication");
    };

    const handleToggleDoctorcaresType = (object) => () => {
        const currentIndex = values.doctorCare.indexOf(object.value);
        const newChecked = [...values.doctorCare];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setDoctorcaresType(newChecked);
        setValues({...values, doctorCare:newChecked});
        logFunction("Are you curently on doctor care option");
    };

    const handleChangeDoctorcare = (event) => {
        setDoctorcares(event.target.value);
        if (event.target.value === 'No'){
            setValues({...values, doctorCare:[], doctorcare:event.target.value});
        }else{
            setValues({...values, doctorcare:event.target.value});
        }
        
        logFunction("Are you curently on doctor care");
    };


    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.currentlyonmedication')} <br /><i style={{color:"blue"}}>/現在何か薬を飲んでいますか</i></h5></strong></Paper>
                </Grid>

                <Grid item xs={12}>
                <FormControl component="fieldset">
                    
                    <RadioGroup row aria-label="gender" name="gender1" value={values.onMedication} onChange={handleChange}>
                        <FormControlLabel value="yes" control={<Radio />} label={t('internalMedcine.yes')} /><i className={classes.sidecheck}>/はい</i>
                        <FormControlLabel value="No" control={<Radio />} label={t('internalMedcine.No')} /><i className={classes.sidecheck}>/いいえ</i>
                    </RadioGroup>
                </FormControl>
                </Grid>

                <Grid style={{width:"inherit", flex: 1}}>
                <div>
                    {values.onMedication === 'yes' ? (
                        <div>
                        <FormLabel className={classes.label}><strong>{t('internalMedcine.currentlyonmedicationyesno')}<br/> <i style={{color:"blue"}}>/「はい」に☑した人は、当てはまるものに、チェックをしてください</i></strong></FormLabel>
                        <GridList item xs={6} style={{ height: 80, paddingLeft: 20, paddingRight: 20, paddingTop:10, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {onmedications.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleMedicationType(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.onmedications.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                        </GridList>
                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.underdoctorcare')}? <br /><i style={{color:"blue"}}>/現在治療している病気、または過去に治療していたことはありますか？</i></h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                    
                    <RadioGroup row aria-label="gender" name="gender1" value={values.doctorcare} onChange={handleChangeDoctorcare}>
                        <FormControlLabel value="yes" control={<Radio />} label={t('internalMedcine.yes')}  /><i className={classes.sidecheck}>/はい</i>
                        <FormControlLabel value="No" control={<Radio />} label={t('internalMedcine.No')} /><i className={classes.sidecheck}>/いいえ</i>
                    </RadioGroup>
                </FormControl>
                </Grid>
                <Grid>
                <div>
                    {values.doctorcare === 'yes' ? (
                        <Grid container item xs={12}>
                        <FormLabel container className={classes.label}><strong >{t('internalMedcine.currentlyonmedicationyesno')} <br/><i style={{color:"blue"}}>/「はい」に☑した人は、疾患名リストから選択し、チェックをしてください</i></strong></FormLabel>
                        <GridList style={{ height: 158, paddingLeft: 20, paddingRight: 20, paddingTop:10, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {ondoctorcares.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem item xs={6} key={value.name} role={undefined} dense button onClick={handleToggleDoctorcaresType(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.doctorCare.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                        </GridList>
                        </Grid>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.hadsurgery')} <br /><i style={{color:"blue"}}>/手術をしたことはありますか？</i></h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                    
                    <RadioGroup row aria-label="gender" name="gender1" value={values.hadsurgery} onChange={handleChangeHadsurgerys}>
                        <FormControlLabel value="yes" control={<Radio />} label={t('internalMedcine.yes')} /><i className={classes.sidecheck}>/はい</i>
                        <FormControlLabel value="No" control={<Radio />} label={t('internalMedcine.No')} /><i className={classes.sidecheck}>/いいえ</i>
                    </RadioGroup>
                </FormControl>
                </Grid>
                <Grid>
                <div>
                    {values.hadsurgery === 'yes' ? (
                        <div style={{width:"inherit"}}> 
                            <FormLabel className={classes.label}><strong>{t('internalMedcine.currentlyonmedicationyesnosurgery')} <br/><i style={{color:"blue"}}>/手術した部分にチェックをしてください</i></strong></FormLabel>
                        <GridList style={{ height: 180, paddingLeft: 20, paddingRight: 20, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {hadsurgerys.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;
                           return (
                                <ListItem item xs={6} key={value.name} role={undefined} dense button onClick={handleToggleHadsurgerysType(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.hadsurgerys.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                        </GridList>
                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default CenteredGrid;