import React from 'react';
import Nav from "./nav.js";
import '../App.css';

function Inteview() {
  return (
    <div className="App">
         <Nav />
       <h1>inteview</h1>
    </div>
  );
}

export default Inteview;