import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from '../ctContext '


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Ct3() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(CtContext);
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct3 - Dressing",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });
    
   

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title}  variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            更衣({t('test.title.dressing')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.enterChangingRoom}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちらの更衣室にお入りください<br />{t('test.dressing.enter changing room')}</h5> } />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlipDressing}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5> } />
 
                    </Paper>
                    
                </Grid>
            <Grid item xs={12}>

                <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.clothesIntoTheBasket}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>脱いだ更衣はこちらのカゴに入れ、カゴごとロッカーに入れます<br />{t('test.dressing.clothes into the basket')}</h5>} />
 
                 </Paper>
                    
             </Grid>
        <Grid item xs={12}>

            <Paper className={classes.paper}>
                <FormControlLabel
                            value={values.lookTheLocker}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ロッカーには鍵をかけてください<br />{t('test.dressing.look the locker')}</h5>} />
 
                 </Paper>
                    
             </Grid >
        <Grid item xs={12}>

            <Paper className={classes.paper}>
                <FormControlLabel
                            value={values.afterChanging}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>更衣後、会計ファイル・受付票・ロッカーの鍵を手にお持ちになり、向かい側の前室に来てください<br />{t('test.dressing.after changing')}</h5>} />
 
                 </Paper>
                    
             </Grid >
         </Grid>
         <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >CT HOME</Button></Link>
             <Link to="ct4" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
             
         </div>
            <Footer />

        </div>
                
    );
}
 
export default Ct3;