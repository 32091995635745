import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext.js'


const useStyles = makeStyles((theme) => ({

    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray2() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [values, setValues] = React.useContext(XrayContext);
    const [xray, setXray] = React.useState('');
    const [passTicket, setPassTicket] = React.useState('');
    const [importCD, setImportCD] = React.useState('');
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray2 - Examination reception",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    
    const handleChangeXray = event => {
        setXray(event.target.value);
        setValues({ ...values, xray: event.target.value })    
    };
    const handleChangePassTicket = event => {
        setPassTicket(event.target.value);
        setValues({ ...values, passTicket: event.target.value })
    };
    const yesnoHandleChange = (event) => {
        setImportCD(event.target.value);
        setValues({ ...values, importCD: event.target.value })
    };

   
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査受付({t('test.title.reception')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>Ｘ線検査ですか？<br />{t('test.reception.get Xray?')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="xray" name="xray" value={values.xray} onChange={handleChangeXray}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>こちらの機械に診察券をお通しください<br />{t('test.reception.pass ticket')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup  name="anser1" value={values.passTicket} onChange={handleChangePassTicket}>
                            <FormControlLabel value="ans1" control={<Radio />} label={<strong><h6>{t('test.ans1')}<br />どうやってやればいいですか？</h6></strong>} />
                            <FormControlLabel value="ans2" control={<Radio />} label={<strong><h6>{t('test.ans2')}<br />わかりました。</h6></strong>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.bringPinkSlip}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>ピンクの受付票をお持ちになって、中の待合室で座っていてください<br />{t('test.reception.bring pink slip')}</h5> </strong>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.calledByNunber}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>番号で呼ばれます<br />{t('test.reception.called by number')}</h5> </strong>} />
 
                   </Paper>   
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>ＣＤ取り込みですか？<br />{t('test.reception.import CD')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="importCD" name="importCD" value={values.importCD} onChange={yesnoHandleChange}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div>
                        {values.importCD === 'yes' ? (


                            <Paper className={classes.paper}>
                                <FormControlLabel
                                    value={values.sitAndWait}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<strong><h5>ご用意いたしますので、近くの椅子に腰かけてお待ちください<br />{t('test.reception.sit and wait')}</h5> </strong>} />

                            </Paper>
                        ) : (
                                <nav> &apos; </nav>
                            )}
                   </div>
                </Grid>
                
             </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >X-ray HOME</Button></Link>
                <Link to="xray3" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                  </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray2;