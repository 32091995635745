import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray8() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray8 - Cervical vertebra",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti9_1] = useSound('/sound/9/' + language + '9/9-01.mp3');
    const [playXRAYmulti9_2] = useSound('/sound/9/' + language + '9/9-02.mp3');
    const [playXRAYmulti9_3] = useSound('/sound/9/' + language + '9/9-03.mp3');
    const [playXRAYmulti9_4] = useSound('/sound/9/' + language + '9/9-04.mp3');
    const [playXRAYmulti9_5] = useSound('/sound/9/' + language + '9/9-05.mp3');
    const [playXRAYmulti9_6] = useSound('/sound/9/' + language + '9/9-06.mp3');
    const [playXRAYmulti9_7] = useSound('/sound/9/' + language + '9/9-07.mp3');
    const [playXRAYmulti9_8] = useSound('/sound/9/' + language + '9/9-08.mp3');
    const [playXRAYmulti9_9] = useSound('/sound/9/' + language + '9/9-09.mp3');
    const [playXRAYmulti9_10] = useSound('/sound/9/' + language + '9/9-10.mp3');
    const [playXRAYmulti9_11] = useSound('/sound/9/' + language + '9/9-11.mp3');
    const [playXRAYmulti9_12] = useSound('/sound/9/' + language + '9/9-12.mp3');
    const [playXRAYmulti9_13] = useSound('/sound/9/' + language + '9/9-13.mp3');
    const [playXRAYmulti9_17] = useSound('/sound/9/' + language + '9/9-17.mp3');
   
    const [playXRAYjp9_1] = useSound('/sound/9/jp9/9-01.mp3');
    const [playXRAYjp9_2] = useSound('/sound/9/jp9/9-02.mp3');
    const [playXRAYjp9_3] = useSound('/sound/9/jp9/9-03.mp3');
    const [playXRAYjp9_4] = useSound('/sound/9/jp9/9-04.mp3');
    const [playXRAYjp9_5] = useSound('/sound/9/jp9/9-05.mp3');
    const [playXRAYjp9_6] = useSound('/sound/9/jp9/9-06.mp3');
    const [playXRAYjp9_7] = useSound('/sound/9/jp9/9-07.mp3');
    const [playXRAYjp9_8] = useSound('/sound/9/jp9/9-08.mp3');
    const [playXRAYjp9_9] = useSound('/sound/9/jp9/9-09.mp3');
    const [playXRAYjp9_10] = useSound('/sound/9/jp9/9-10.mp3');
    const [playXRAYjp9_11] = useSound('/sound/9/jp9/9-11.mp3');
    const [playXRAYjp9_12] = useSound('/sound/9/jp9/9-12.mp3');
    const [playXRAYjp9_13] = useSound('/sound/9/jp9/9-13.mp3');
    const [playXRAYjp9_17] = useSound('/sound/9/jp9/9-17.mp3');

    const [playXRAYjp11_9] = useSound('/sound/11/jp11/11-09.mp3');
    const [playXRAYmulti11_9] = useSound('/sound/11/' + language + '11/11-09.mp3');
   
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/' + language + '13/13-14.mp3');
    
    const [playXRAYjp6_5] = useSound('/sound/6/jp6/6-05.mp3');
    const [playXRAYmulti6_5] = useSound('/sound/6/' + language + '6/6-05.mp3');
   
    const [playXRAYjp2_6] = useSound('/sound/2/jp2/2-6.mp3');
    const [playXRAYmulti2_6] = useSound('/sound/2/'+language+'2/2-6.mp3');
   
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            頸椎({t('test.title.cervical vertebra')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.cervicalSpine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>頸椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large"/>
                                </IconButton>
                                <br />{t('test.Xray.cervical spine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.wholeBack}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>背中全部の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.whole back')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.cervicalVertebra.thoracicSpine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>胸椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.thoracic spine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                   </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.lumbarVertebra}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>腰椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.lumbar vertebra')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.scaralVertebra}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>仙椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.scaral vertebra')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.photoOfStanding}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>立って撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of standing')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.putYourBackOnBoard}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この板に背中を付けて立ってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put your back on board')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.raiseJaw}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>顎を挙げて上を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.raise jaw')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.tellMeIfHurts}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お体を少し動かしますので、痛かったら教えてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.tell me if hurts')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.nowTaleObliquePhoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は斜めの写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.now take oblique photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.stickOutChin}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>顎を突き出すようにしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.stick out chin')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_10}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.nowTakeFromSide}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は横向きの写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_11}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.now take from side')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_11}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.turnBody}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちらに体を向けて、しっかり板に体を寄せていてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_12}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.turn body')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_12}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.stayLookingUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>肩をうごかさずに出来る限り上を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.stay looking up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_13}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.stayLookingDown}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>肩をうごかさずに出来る限り下を向いてください<br />{t('test.Xray.stay looking down')}</h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.giveAsignal}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸の合図をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.give a signal')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.breatheInThenOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って吐いて止めてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp2_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.breathe in then out')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti2_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.cervicalVertebra.relaxYourBreath}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸を楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_17}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.relax your breath')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_17}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray8;