import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "16px",
        marginLeft: "20px",
        color: "black",
        fontSize: "16px",
        backgroundColor: "#99ff66",
        minHeight: "75px",
        minWidth: "28%",
        borderRadius: "46px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "1%",
        paddingTop: "1%",
    }
}));

    function Xray1() {
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray1 - Plain X-rays",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

        return(
            <div  >
                <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>

                            <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                                単純Ｘ線({t('test.title.Xray')})
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <div style={{ margin: 10 }}>
                    <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
                </div>
                 <div className={classes.content} style={{}}>

                    <Link to='/xray2' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation > 検査受付<br/>{t('test.title.reception')}</Button></Link>
                    <Link to='/xray3' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 入室<br/>{t('test.title.enter')}</Button></Link>
                    <Link to='/xray4' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 更衣<br/>{t('test.title.dressing')}</Button></Link>
                    <Link to='/xray5' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 胸部<br />{t('test.title.chest')}</Button></Link>
                    <Link to='/xray6' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 腹部<br />{t('test.title.abdomen')}</Button></Link>
                    <Link to='/xray7' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 頭部<br />{t('test.title.cranial')}</Button></Link>
                    <Link to='/xray8' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation > 頸椎<br />{t('test.title.cervical vertebra')}</Button></Link>
                    <Link to='/xray9' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 臥位<br />{t('test.title.lying-position')}</Button></Link>
                    <Link to='/xray10' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 骨盤<br />{t('test.title.pelvis')}</Button></Link>
                    <Link to='/xray11' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 肩<br />{t('test.title.shoulder')}</Button></Link>
                    <Link to='/xray12' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 鎖骨<br />{t('test.title.clavicle')}</Button></Link>
                    <Link to='/xray13' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation > 上腕<br />{t('test.title.upper arm')}</Button></Link>
                    <Link to='/xray14' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 肘・前腕<br />{t('test.title.elbow/forearm')}</Button></Link>
                    <Link to='/xray15' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 手<br />{t('test.title.hand')}</Button></Link>
                    <Link to='/xray16' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 前下肢・大腿<br />{t('test.title.both lower limbs/back thigh')}</Button></Link>
                    <Link to='/xray17' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 大腿<br />{t('test.title.back thigh')}</Button></Link>
                    <Link to='/xray18' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation > 膝・下腿<br />{t('test.title.knees/legs')}</Button></Link>
                    <Link to='/xray19' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 足<br />{t('test.title.foot')}</Button></Link>
                    <Link to='/xray20' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 終了<br />{t('test.title.finish')}</Button></Link>
                    <Link to='/xray21' className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 検査後の退室<br />{t('test.title.leaving')}</Button></Link>
                   
                 </div>
                
                <Footer />
                </div>
    );
}
export default Xray1;