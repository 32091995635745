import React, { useContext, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from './list';



const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        marginTop: "18px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        paddingBottom: "10%",
        //paddingTop: "10%"
    },
    div1: {
        fontSize: "20px",
        float: "left",
        backgroundColor: "#6CAFED",
        width: "25%",
        marginTop: "20px"
    },

     div2: {
         width: "45%",
         backgroundColor: "#6CAFED",
    // fontSize: "26px",
        float: "right"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p: {
        textAlign: "center",
        fontSize: "22px",
        display: "inline"
    }

}));

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

const words = (decodeURI(getQueryVariable("letter")));
//PubSub.publish('words', words);

function ShowWords() {
    const letter = (decodeURI(getQueryVariable("letter")));
    const classes = useStyles();
    const request = {
        words: words
    }
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        text1: '',
        test : [],
    });
    const history = useHistory();
    const find =()=>{
        var findVal = values.text1
        history.push("/wordDetails?find=" + findVal);
    }
    //const base_url = "http://localhost:5000";
    const base_url = process.env.REACT_APP_BACKEND_URL;

/*   axios.post(base_url + '/wakayamaWord/indexWords', request).then((response) => {
        const list = response.data.length;
        const data = response.data;
        const div1 = document.getElementById('div1');
        const div2 = document.getElementById('div2');
        const div3 = document.getElementById('div3');
        const div4 = document.getElementById('div4');
        var content1 = "";
        var content2 = "";
        var content3 = "";
        var content4 = "";
        for (var i = 0; i < list; i++) {
            if (i % 4 == 0) {
                content1 +=
                    '<a><p style="text-align: left; margin-left: 20px; text-decoration= underline;  font-size: 20px">' + data[i].words + '</p ></a>';
            } else if (i % 4 == 1) {
                content2 +=
                    '<p style="text-align: left; margin-left: 20px;  font-size: 20px">' + data[i].words + '</p >';
            } else if (i % 4 == 2) {
                content3 +=
                    '<p style="text-align: left; margin-left: 20px;  font-size: 20px">' + data[i].words + '</p >';
            } else if (i % 4 == 3) {
                content4 +=
                        '<p style="text-align: left; margin-left: 20px;  font-size: 20px">' + data[i].words + '</p >';
            }
       }
        //div1.innerHTML = content1;
        div2.innerHTML = content2;
        div3.innerHTML = content3;
        div4.innerHTML = content4;

    }).catch((error) => {
        console.log(error);
    });*/
    const setText1=(event)=>{
        setValues({ ...values, text1: event.target.value });
    }

        return (
            <div >
                 <Nav />
                    <div>
                        <AppBar position="static" style={{ backgroundColor: "#F9B1B1", width: "auto"}}>
                            <Toolbar>
                                <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                    Wakayama Dialect Dictionary 
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <div className={classes.content}>
                        <TextField className={classes.textFiled} defaultValue={values.text1} onChange={setText1} id="text1" variant="outlined" label="Input your word" style={{ width: "82%", marginTop: "20px", paddingLeft: "10px", borderRadius: "15px" }} />
                        <Button className={classes.button} variant="contained" color="primary" disableElevation  onClick={find}> {t('search')}</Button>  
                        <br></br>
                        <AppBar position="static" style={{ backgroundColor: "#6CAFED", height: "50px", }}>
                            <Toolbar>
                                <Typography className={classes.title} variant="h6" noWrap style={{ marginTop: "-15px", color: "white" }}>
                                    Index of「{letter}」
                            </Typography>
                            </Toolbar>
                        </AppBar>
                        <div id="div5" style={{ marginTop: "20px", backgroundColor: "#6CAFED", overflow: "hidden" }}>
                                <List />
                        </div>
                        <Link to="/translate"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ float: "right" }}> {t('translate')}</Button></Link>
                        <p className={classes.p}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <Link to="/dictionary"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ float: "right", backgroundColor: "#B4C0CB", marginRight: "20px" }} > {t('back')}</Button></Link>
                    </div>
                    </Container>
                    <Footer />
            </div>
        );
   }

export default ShowWords;
