import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray19() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray19 - Foot",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti13_1] = useSound('/sound/13/'+language+'13/13-01.mp3');
    const [playXRAYmulti13_2] = useSound('/sound/13/' + language + '13/13-02.mp3');
    const [playXRAYmulti13_3] = useSound('/sound/13/' + language + '13/13-03.mp3');
    const [playXRAYmulti13_4] = useSound('/sound/13/' + language + '13/13-04.mp3');
    const [playXRAYmulti13_5] = useSound('/sound/13/' + language + '13/13-05.mp3');
    const [playXRAYmulti13_6] = useSound('/sound/13/' + language + '13/13-06.mp3');
    const [playXRAYmulti13_7] = useSound('/sound/13/' + language + '13/13-07.mp3');
    const [playXRAYmulti13_8] = useSound('/sound/13/' + language + '13/13-08.mp3');
    const [playXRAYmulti13_9] = useSound('/sound/13/' + language + '13/13-09.mp3');
    const [playXRAYmulti13_10] = useSound('/sound/13/' + language + '13/13-10.mp3');
    const [playXRAYmulti13_11] = useSound('/sound/13/' + language + '13/13-11.mp3');
    const [playXRAYmulti13_12] = useSound('/sound/13/' + language + '13/13-12.mp3');
    const [playXRAYmulti13_13] = useSound('/sound/13/' + language + '13/13-13.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/' + language + '13/13-14.mp3');
    
    const [playXRAYjp13_1] = useSound('/sound/13/jp13/13-01.mp3');
    const [playXRAYjp13_2] = useSound('/sound/13/jp13/13-02.mp3');
    const [playXRAYjp13_3] = useSound('/sound/13/jp13/13-03.mp3');
    const [playXRAYjp13_4] = useSound('/sound/13/jp13/13-04.mp3');
    const [playXRAYjp13_5] = useSound('/sound/13/jp13/13-05.mp3');
    const [playXRAYjp13_6] = useSound('/sound/13/jp13/13-06.mp3');
    const [playXRAYjp13_7] = useSound('/sound/13/jp13/13-07.mp3');
    const [playXRAYjp13_8] = useSound('/sound/13/jp13/13-08.mp3');
    const [playXRAYjp13_9] = useSound('/sound/13/jp13/13-09.mp3');
    const [playXRAYjp13_10] = useSound('/sound/13/jp13/13-10.mp3');
    const [playXRAYjp13_11] = useSound('/sound/13/jp13/13-11.mp3');
    const [playXRAYjp13_12] = useSound('/sound/13/jp13/13-12.mp3');
    const [playXRAYjp13_13] = useSound('/sound/13/jp13/13-13.mp3');
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            足({t('test.title.foot')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.standingUpOnBed}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台の上に立って撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.standing up on bed')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.holdMachine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この機械にしっかりつかまってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.hold machine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.standWillMove}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>立っている台が動きます
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.stand will move')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.feetOnBoard}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>板に足を付けてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.feet on board')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.turnFeetInards}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し内股にします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.i will turn feet inards')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.turnFeetOutward}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し外股にします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.i will turn feet outward')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.weightOnMachine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>体重を乗せてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.weight on machine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.notMoveAndStay}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>そのまま動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.not move and stay')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.photoOfOpposite}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は反対向きに撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of opposite')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.photoOfSitting}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台に座って撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of sitting')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_10}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.feetPutOnBoard}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>足を板に乗せてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_11}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.feet puton board')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_11}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.tiltKneesInward}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は膝を内側に倒して足をすこし斜めにします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_12}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.tilt knees inward')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_12}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.tiltKneesOutward}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は膝を外側に倒して足をすこし斜めにします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.tilt knees outward')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_13}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.foot.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
                </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray19;