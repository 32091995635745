import React, { useState, useContext } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { PediatricsContext } from '../context/pediatricsContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 1000,
        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    sidecheck: {
        paddingTop: 11,
        marginLeft: -13,
        marginRight: 5,
        color: "blue"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    label: {
        width: "100%",
        textAlign: "center",
        lineHeight: 1.5
    }
}));
const Form7 = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useContext(PediatricsContext);
    const classes = useStyles();

    const handleChangeCheckSpecialRequest = (event) => {
        //setSpecialRequest({ ...SpecialRequest, [event.target.name]: event.target.checked });
        setValues({ ...values, [event.target.name]: event.target.checked })
        // logFunction("Special request concerning consoltation");

    };

    const update = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20, flex: 1, }}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}><strong><h6 style={{ fontSize: 19 }}>{t('Pediatric.CheckTheVaccinationHistoryBelowShowMedicalStaffTheMaternalAndChildHealthHandbookAndVaccinationRecordBook')} <br /><i style={{ color: "blue" }}>/ ワクチン接種歴を下記に書いてください。母子手帳、ワクチン手帳をお持ちの方は、スタッフに見せてください。。</i></h6></strong></Paper>
                </Grid>
                {/* Hib/Hib */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Hib}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Hib"
                                />
                            }
                            label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Hib/Hib')}</h6><i style={{ color: "blue", marginTop: -2 }}>/Hib/Hib</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="HibTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="HibTime"
                                type="number"
                                values={values.HibTime}
                                defaultValue={values.HibTime}
                                onChange={e => update(e)}
                                name="HibTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                {/* Rotavirus */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Rotavirus}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Rotavirus"
                                />
                            }
                            label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Rotavirus')}</h6><i style={{ color: "blue", marginTop: -2 }}>/ロタ</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="RotavirusTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="RotavirusTime"
                                type="number"
                                values={values.RotavirusTime}
                                defaultValue={values.RotavirusTime}
                                onChange={e => update(e)}
                                name="RotavirusTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                {/* Pneumococcus */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Pneumococcus}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Pneumococcus"
                                />
                            }
                            label={<div ><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Pneumococcus')}</h6><i style={{ color: "blue", marginTop: -2 }}>/肺炎球菌</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="PneumococcusTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="PneumococcusTime"
                                type="number"
                                values={values.PneumococcusTime}
                                defaultValue={values.PneumococcusTime}
                                onChange={e => update(e)}
                                name="PneumococcusTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                {/* Mumps */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Mumps}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Mumps"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Mumps')}</h6><i style={{ color: "blue", marginTop: -2 }}>/おたふくかぜ</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="MumpsTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="MumpsTime"
                                type="number"
                                values={values.MumpsTime}
                                defaultValue={values.MumpsTime}
                                onChange={e => update(e)}
                                name="MumpsTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                {/* Tetravaccine(DPT-IPV) */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Tetravaccine}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Tetravaccine"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Tetravaccine(DPT-IPV)')}</h6><i style={{ color: "blue", marginTop: -2 }}>/4種混合(DPT-IPV)</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="TetravaccineTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="TetravaccineTime"
                                type="number"
                                values={values.TetravaccineTime}
                                defaultValue={values.TetravaccineTime}
                                onChange={e => update(e)}
                                name="TetravaccineTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Triple vaccine (DPT) */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Triplevaccine}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Triplevaccine"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Triplevaccine(DPT)')}</h6><i style={{ color: "blue", marginTop: -2 }}>/3種混合(DPT)</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="TriplevaccineTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="TriplevaccineTime"
                                type="number"
                                values={values.TriplevaccineTime}
                                defaultValue={values.TriplevaccineTime}
                                onChange={e => update(e)}
                                name="TriplevaccineTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Hepatitis B */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.HepatitisB}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="HepatitisB"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.HepatitisB')}</h6><i style={{ color: "blue", marginTop: -2 }}>/B型肝炎</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="HepatitisBTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="HepatitisBTime"
                                type="number"
                                values={values.HepatitisBTime}
                                defaultValue={values.HepatitisBTime}
                                onChange={e => update(e)}
                                name="HepatitisBTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Oral poliovirus */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Oralpoliovirus}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Oralpoliovirus"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Oralpoliovirus')}</h6><i style={{ color: "blue", marginTop: -2 }}>/B型肝炎</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="OralpoliovirusTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="OralpoliovirusTime"
                                type="number"
                                values={values.OralpoliovirusTime}
                                defaultValue={values.OralpoliovirusTime}
                                onChange={e => update(e)}
                                name="OralpoliovirusTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* BCG */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Oralpoliovirus}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="BCG"
                                />
                            }
                            label={<div style={{ display: "flex" }}><h6 style={{ fontWeight: 600 }}>{t('Pediatric.BCG')}</h6><i style={{ color: "blue", marginTop: -2 }}>/BCG</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="BCGTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="BCGTime"
                                type="number"
                                values={values.BCGTime}
                                defaultValue={values.BCGTime}
                                onChange={e => update(e)}
                                name="BCGTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Inactivated poliovirus */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.InactivatedPoliovirus}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="InactivatedPoliovirus"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.InactivatedPoliovirus')}</h6><i style={{ color: "blue", marginTop: -2 }}>/不活化ポリオ</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="InactivatedPoliovirusTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="InactivatedPoliovirusTime"
                                type="number"
                                values={values.InactivatedPoliovirusTime}
                                defaultValue={values.InactivatedPoliovirusTime}
                                onChange={e => update(e)}
                                name="InactivatedPoliovirusTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                            {/* MR vaccine */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.MRvaccine}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="MRvaccine"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.MRvaccine')}</h6><i style={{ color: "blue", marginTop: -2 }}>/MR</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="MRvaccineTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="MRvaccineTime"
                                type="number"
                                values={values.MRvaccineTime}
                                defaultValue={values.MRvaccineTime}
                                onChange={e => update(e)}
                                name="MRvaccineTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Hepatitis A */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.HepatitisA}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="HepatitisA"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.HepatitisA')}</h6><i style={{ color: "blue", marginTop: -2 }}>/A型肝炎</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="HepatitisATime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="HepatitisATime"
                                type="number"
                                values={values.HepatitisATime}
                                defaultValue={values.HepatitisATime}
                                onChange={e => update(e)}
                                name="HepatitisATime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Chickenpox */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Chickenpox}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Chickenpox"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Chickenpox')}</h6><i style={{ color: "blue", marginTop: -2 }}>/水痘</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="ChickenpoxTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="ChickenpoxTime"
                                type="number"
                                values={values.ChickenpoxTime}
                                defaultValue={values.ChickenpoxTime}
                                onChange={e => update(e)}
                                name="ChickenpoxTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Measles */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Measles}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Measles"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Measles')}</h6><i style={{ color: "blue", marginTop: -2 }}>/麻疹</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="MeaslesTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="MeaslesTime"
                                type="number"
                                values={values.MeaslesTime}
                                defaultValue={values.MeaslesTime}
                                onChange={e => update(e)}
                                name="MeaslesTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Japanese encephalitis */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.JapaneseEncephalitis}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="JapaneseEncephalitis"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.JapaneseEncephalitis')}</h6><i style={{ color: "blue", marginTop: -2 }}>/日本脳炎</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="JapaneseEncephalitisTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="JapaneseEncephalitisTime"
                                type="number"
                                values={values.JapaneseEncephalitisTime}
                                defaultValue={values.JapaneseEncephalitisTime}
                                onChange={e => update(e)}
                                name="JapaneseEncephalitisTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Rubella */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Rubella}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Rubella"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Rubella')}</h6><i style={{ color: "blue", marginTop: -2 }}>/風疹</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="RubellaTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="RubellaTime"
                                type="number"
                                values={values.RubellaTime}
                                defaultValue={values.RubellaTime}
                                onChange={e => update(e)}
                                name="RubellaTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* MMR */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.MMR}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="MMR"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.MMR')}</h6><i style={{ color: "blue", marginTop: -2 }}>/MMR</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="MMRTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="MMRTime"
                                type="number"
                                values={values.MMRTime}
                                defaultValue={values.MMRTime}
                                onChange={e => update(e)}
                                name="MMRTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                            {/* Other(s) */}
                <Grid container item xs={4}>
                    <Grid container item xs={6} style={{ paddingRight: 0, marginRight: -6 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.Other}
                                    onChange={handleChangeCheckSpecialRequest}
                                    name="Other"
                                />
                            }
                            label={<div><h6 style={{ fontWeight: 600 }}>{t('Pediatric.Other(s)')}</h6><i style={{ color: "blue", marginTop: -2 }}>/MMR</i></div>}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <FormControl fullWidth className='' style={{ marginTop: -11, width: "97%", right: -60 }}>
                            <InputLabel htmlFor="OtherTime" style={{ left: 33 }}>{t('Pediatric.time(s)')} <i style={{ color: "#0000ffbf" }}>/回</i></InputLabel>
                            <Input
                                // disabled={() =>{if (values.BreastPlusFormulaMilk === true){ return true} }}
                                // disabled={values.BreastPlusFormulaMilk}
                                id="OtherTime"
                                type="number"
                                values={values.OtherTime}
                                defaultValue={values.OtherTime}
                                onChange={e => update(e)}
                                name="OtherTime"
                            />
                        </FormControl>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>{t('internalMedcine.specialrequestconcerningconsultation')}? <br /><i style={{color:"blue"}}>/診察でのご希望がある場合は、☑をしてください。</i></h5></strong></Paper>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.medicalexpenses}
                                onChange={handleChangeCheckSpecialRequest}
                                name="medicalexpenses"
                            />
                        }
                        label={t('internalMedcine.informedonestimatedexpenses')}
                    />
                    <nav style={{paddingTop:11, color:"blue"}}>/推定医療費を事前に知らせたい</nav>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.haveinterpreter}
                                onChange={handleChangeCheckSpecialRequest}
                                name="haveinterpreter"
                            />
                        }
                        label={t('internalMedcine.interpreterinterpreterservice')}
                    />
                    <nav style={{paddingTop:11, color:"blue"}}>/通訳サービスがあれば通訳が欲しいのですが</nav>
                </Grid>

                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.otherssss}
                                onChange={handleChangeCheckSpecialRequest}
                                name="otherssss"
                            />
                        }
                        label={t('internalMedcine.Other')}
                        
                    />
                    
                    <Grid className="navCheckbox">
                    <span style={{color:"blue"}}>/(s)/その他</span>
                    </Grid>
                    
                </Grid>

            </Grid>

        </div>
    );
};

export default Form7;