import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray9() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray9 - Lying-position",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti9_2] = useSound('/sound/9/' + language + '9/9-02.mp3');
    const [playXRAYmulti9_3] = useSound('/sound/9/' + language + '9/9-03.mp3');
    const [playXRAYmulti9_4] = useSound('/sound/9/' + language + '9/9-04.mp3');
    const [playXRAYmulti9_5] = useSound('/sound/9/' + language + '9/9-05.mp3');
    const [playXRAYmulti8_3] = useSound('/sound/8/' + language + '8/8-03.mp3');
    const [playXRAYmulti4_3] = useSound('/sound/4/' + language + '4/4-03.mp3');
    const [playXRAYmulti7_4] = useSound('/sound/7/'+language+'7/7-04.mp3');
    const [playXRAYmulti7_5] = useSound('/sound/7/' + language + '7/7-05.mp3');
    const [playXRAYmulti10_6] = useSound('/sound/10/' + language + '10/10-06.mp3');
    const [playXRAYmulti8_4] = useSound('/sound/8/' + language + '8/8-04.mp3');
    const [playXRAYmulti8_5] = useSound('/sound/8/' + language + '8/8-05.mp3');
    const [playXRAYmulti8_6] = useSound('/sound/8/' + language + '8/8-06.mp3');
    const [playXRAYmulti8_7] = useSound('/sound/8/' + language + '8/8-07.mp3');
    const [playXRAYmulti9_14] = useSound('/sound/9/' + language + '9/9-14.mp3');
    const [playXRAYmulti9_15] = useSound('/sound/9/' + language + '9/9-15.mp3');
    const [playXRAYmulti9_16] = useSound('/sound/9/' + language + '9/9-16.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/' + language + '13/13-14.mp3');
    const [playXRAYmulti6_5] = useSound('/sound/6/' + language + '6/6-05.mp3');
    const [playXRAYmulti2_6] = useSound('/sound/2/' + language + '2/2-6.mp3');
    const [playXRAYmulti9_17] = useSound('/sound/9/' + language + '9/9-17.mp3');
    
    const [playXRAYjp9_2] = useSound('/sound/9/jp9/9-02.mp3');
    const [playXRAYjp9_3] = useSound('/sound/9/jp9/9-03.mp3');
    const [playXRAYjp9_4] = useSound('/sound/9/jp9/9-04.mp3');
    const [playXRAYjp9_5] = useSound('/sound/9/jp9/9-05.mp3');
    const [playXRAYjp8_3] = useSound('/sound/8/jp8/8-03.mp3');
    const [playXRAYjp4_3] = useSound('/sound/4/jp4/4-03.mp3');
    const [playXRAYjp7_4] = useSound('/sound/7/jp7/7-04.mp3');
    const [playXRAYjp7_5] = useSound('/sound/7/jp7/7-05.mp3');
    const [playXRAYjp10_6] = useSound('/sound/10/jp10/10-06.mp3');
    const [playXRAYjp8_4] = useSound('/sound/8/jp8/8-04.mp3');
    const [playXRAYjp8_5] = useSound('/sound/8/jp8/8-05.mp3');
    const [playXRAYjp8_6] = useSound('/sound/8/jp8/8-06.mp3');
    const [playXRAYjp8_7] = useSound('/sound/8/jp8/8-07.mp3');
    const [playXRAYjp9_14] = useSound('/sound/9/jp9/9-14.mp3');
    const [playXRAYjp9_15] = useSound('/sound/9/jp9/9-15.mp3');
    const [playXRAYjp9_16] = useSound('/sound/9/jp9/9-16.mp3');
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');
    const [playXRAYjp6_5] = useSound('/sound/6/jp6/6-05.mp3');
    const [playXRAYjp2_6] = useSound('/sound/2/jp2/2-6.mp3');
    const [playXRAYjp9_17] = useSound('/sound/9/jp9/9-17.mp3');
 
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            臥位({t('test.title.lying-position')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.wholeBack}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>背中全部の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.whole back')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.lyingPosition.thoracicSpine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>胸椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.thoracic spine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                   </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lumbarVertebra}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>腰椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.lumbar vertebra')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.scaralVertebra}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>仙椎の写真を撮ります
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.scaral vertebra')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.pictureOfLying}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台に寝て撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp8_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.picture of lying')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti8_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lieFaceUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いでこちらに頭がくるように仰向けに寝てください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp4_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.lie face up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti4_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.layDownOnYourSide}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この寝台に靴を脱いで横向きに寝てください<br />{t('test.Xray.lay down on your side')}</h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.putHandsOnTheSide}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>手は体のわきに置いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put hands on the side')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.touchYourBody}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>位置合わせのために、お体を動かしたり、触れたりします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp7_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.touch your body')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti7_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.ifFeelPain}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>痛かったら、教えてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp10_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.if feel pain')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti10_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lookUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し上を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp8_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.look up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti8_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lookDown}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し下を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp8_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.look down')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti8_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lookRight}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し右を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp8_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.look right')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti8_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.lookLeft}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し左を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp8_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.look left')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti8_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.holdingKnees}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>横向きのまま膝をかかえるようにして丸くなってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.holding knees')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.bendBackward}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は横向きのまま、できる限りのけぞってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_15}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.bend backward')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_15}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.notForceYouSelf}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>無理はしないでください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_16}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.not force yourself')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_16}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
               
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.giveAsignal}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸の合図をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.give a signal')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.breatheInThenOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って吐いて止めてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp2_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.breathe in then out')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti2_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.lyingPosition.relaxYourBreath}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸を楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp9_17}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.relax your breath')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti9_17}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray9;