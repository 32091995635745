import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import Axios from "axios";
import UserContext from "./components/context/UserContext";
//import Nav from "./components/nav";

import Login1 from "./components/auth/login1";
import Hompage from "./components/homepage";
import Nav from "./components/nav";
import Inteview from "./components/inteview";
import InternalMedcine from "./components/inteviewForms/InternalMedcine";
import ViewInternalMed from "./components/inteviewForms/viewInternalMed";
import Dialect from "./components/diaLect/dialect";
import Wakayama from "./components/diaLect/wakayama";
import FontDetails from "./components/diaLect/fontDetails";
import FindDetails from "./components/diaLect/findDetails";
import BasicSentences from "./components/diaLect/basicSentences";
import Obstetricgynecology from "./components/ObstetricgynecologyForms/Obstetricgynecology";
import Disclaimer from "./components/disclaimer";
import Covid19 from "./components/covid-19";
import Covid19Result from "./components/covid-19Resuls";
import Register from "./components/auth/register";
import Cardiology from "./components/CardiologyForms/cardiology";
import Gastrology from "./components/GastrologyForm/gastrology";
import Spychiatry from "./components/Spychiatry/spychiatry";
import Pediatrics from "./components/Pediatrics/pediatrics";
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { InternalMedContext } from './components/context/internalMedContext';
import { CardioContext } from './components/context/cardiologyContext';
import { LogContext } from './components/context/logfileContext';
import { LangContext } from './components/context/languageContext';
import { GastroContext } from './components/context/gastrologyContext';
import { SpychiaContext } from './components/context/spychiatryContext';
import { PediaContext } from './components/context/pediatricsContext';
//import { BasicSentContext } from './components/diaLect/basicSentContext';
import { ObstetricgynecologyContext } from "./components/context/ObstetricgynecologyContext"
import { Covid19Context } from "./components/context/covid19Context"

import WordDetails from "./components/diaLect/wordDetails";
import Dictionary from "./components/diaLect/dictionary";
import Translate from "./components/diaLect/translate";
import WordDetails2 from "./components/diaLect/wordDetails2";
import ShowWords from "./components/diaLect/showWords";
import List from "./components/diaLect/list";
import Okinawadic from "./components/diaLect/okinawadic";
import Okinawadiag from "./components/diaLect/okinawadiag";
import Okinawa from "./components/diaLect/okinawa";
import List2 from "./components/diaLect/list2";
import List3 from "./components/diaLect/list3";
import List4 from "./components/diaLect/list4";
import List5 from "./components/diaLect/list5";

import ShowWords2 from "./components/diaLect/showWords2";
import ShowWords3 from "./components/diaLect/showWords3";
import ShowWords4 from "./components/diaLect/showWords4";
import ShowWords5 from "./components/diaLect/showWords5";
import WordDetails3 from "./components/diaLect/wordDetails3";
import WordDetails4 from "./components/diaLect/wordDetails4";
import WordDetails5 from "./components/diaLect/wordDetails5";
import WordDetails6 from "./components/diaLect/wordDetails6";
import WordDetails7 from "./components/diaLect/wordDetails7";

import OkinawaBasicSentence from "./components/diaLect/okinawaBasicSentence";
import FontDetails2 from "./components/diaLect/fontDetails2";
import FindDetails2 from "./components/diaLect/findDetails2";
import FindDetails3 from "./components/diaLect/findDetails3";
import FontDetails3 from "./components/diaLect/fontDetails3";
import ForEdit from "./components/diaLect/forEdit";
import ForEdit2 from "./components/diaLect/forEdit2";
import ForEdit3 from "./components/diaLect/forEdit3";
import EditSentence from "./components/diaLect/editSentence";
import AddSentence from "./components/diaLect/addSentence";
import Terms from "./components/diaLect/terms";

import InspectionHome from "./components/inspectionForms/inspectionHome";
import Ct1 from "./components/inspectionForms/ct1";
import Ct2 from "./components/inspectionForms/ct2";
import Ct3 from "./components/inspectionForms/ct3";
import Ct4 from "./components/inspectionForms/ct4";
import Ct5 from "./components/inspectionForms/ct5";
import Ct6 from "./components/inspectionForms/ct6";
import Ct7 from "./components/inspectionForms/ct7";
import Mri1 from "./components/inspectionForms/mri1";
import Mri2 from "./components/inspectionForms/mri2";
import Mri3 from "./components/inspectionForms/mri3";
import Mri4 from "./components/inspectionForms/mri4";
import Mri5 from "./components/inspectionForms/mri5";
import Mri6 from "./components/inspectionForms/mri6";
import Xray1 from "./components/inspectionForms/xray1";
import Xray2 from "./components/inspectionForms/xray2";
import Xray3 from "./components/inspectionForms/xray3";
import Xray4 from "./components/inspectionForms/xray4";
import Xray5 from "./components/inspectionForms/xray5";
import Xray6 from "./components/inspectionForms/xray6";
import Xray7 from "./components/inspectionForms/xray7";
import Xray8 from "./components/inspectionForms/xray8";
import Xray9 from "./components/inspectionForms/xray9";
import Xray10 from "./components/inspectionForms/xray10";
import Xray11 from "./components/inspectionForms/xray11";
import Xray12 from "./components/inspectionForms/xray12";
import Xray13 from "./components/inspectionForms/xray13";
import Xray14 from "./components/inspectionForms/xray14";
import Xray15 from "./components/inspectionForms/xray15";
import Xray16 from "./components/inspectionForms/xray16";
import Xray17 from "./components/inspectionForms/xray17";
import Xray18 from "./components/inspectionForms/xray18";
import Xray19 from "./components/inspectionForms/xray19";
import Xray20 from "./components/inspectionForms/xray20";
import Xray21 from "./components/inspectionForms/xray21";
import { CtTestContext } from './components/ctContext ';
import { MriTestContext } from './components/mriContext';
import { XrayTestContext } from './components/xrayContext';


function App() {

  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });
  const base_url = process.env.REACT_APP_BACKEND_URL;;
  //const base_url = "http://localhost:5000";

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await Axios.post(
        base_url + "/user/tokenIsValid",
        null,
        { headers: { "x-auth-token": token } }
      );
      if (tokenRes.data) {
        const userRes = await Axios.get(base_url + "/user/", {
          headers: { "x-auth-token": token },
        });
        setUserData({
          token,
          user: userRes.data
        });
      }
    }
    checkLoggedIn();
  }, [])

  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>

          <LogContext>
            <Covid19Context>
              <Route exact path="/" component={Covid19} />
              <Route path="/covid19Result" component={Covid19Result} />
            </Covid19Context>

            <Route path="/login1" component={Login1} />
            <Route path="/homepage" component={Hompage} />
            <Route path="/inteview" component={Inteview} />
            <Route path="/nav" component={Nav} />


            <Route path="/disclaimer" component={Disclaimer} />

            <InternalMedContext>
              <Route path="/internalMedcine" component={InternalMedcine} />
              <Route path="/viewInternalMed" component={ViewInternalMed} />
            </InternalMedContext>

            <ObstetricgynecologyContext>
              <Route path="/obstetricgynecology" component={Obstetricgynecology} />
            </ObstetricgynecologyContext>

            <CardioContext>
              <Route path="/cardiology" component={Cardiology} />
            </CardioContext>

            <GastroContext>
              <Route path="/gastrology" component={Gastrology} />
            </GastroContext>

            <SpychiaContext>
              <Route path="/spychiatry" component={Spychiatry} />
            </SpychiaContext>

            <PediaContext>
              <Route path="/pediatrics" component={Pediatrics} />
            </PediaContext>

            <Route path="/register" component={Register} />


            <Route path="/dialect" component={Dialect} />
            <Route path="/wakayama" component={Wakayama} />
            <Route path="/basicSentences" component={BasicSentences} />
            <Route path="/fontDetails" component={FontDetails} />
            <Route path="/findDetails" component={FindDetails} />
            <Route path="/dictionary" component={Dictionary} />
            <Route path="/translate" component={Translate} />
            <Route path="/wordDetails" component={WordDetails} />
            <Route path="/wordDetails2" component={WordDetails2} />
            <Route path="/showWords" component={ShowWords} />
            <Route path="/list" component={List} />
            <Route path="/list2" component={List2} />
            <Route path="/list3" component={List3} />
            <Route path="/list4" component={List4} />
            <Route path="/list5" component={List5} />
            <Route path="/okinawa" component={Okinawa} />
            <Route path="/okinawadic" component={Okinawadic} />
            <Route path="/showWords2" component={ShowWords2} />
            <Route path="/showWords3" component={ShowWords3} />
            <Route path="/showWords4" component={ShowWords4} />
            <Route path="/showWords5" component={ShowWords5} />
            <Route path="/wordDetails3" component={WordDetails3} />
            <Route path="/wordDetails4" component={WordDetails4} />
            <Route path="/wordDetails5" component={WordDetails5} />
            <Route path="/wordDetails6" component={WordDetails6} />
            <Route path="/wordDetails7" component={WordDetails7} />
            <Route path="/okinawaBasicSentence" component={OkinawaBasicSentence} />
            <Route path="/fontDetails2" component={FontDetails2} />
            <Route path="/findDetails2" component={FindDetails2} />
            <Route path="/fontDetails3" component={FontDetails3} />
            <Route path="/forEdit" component={ForEdit} />
            <Route path="/forEdit2" component={ForEdit2} />
            <Route path="/editSentence" component={EditSentence} />
            <Route path="/addSentence" component={AddSentence} />
            <Route path="/okinawadiag" component={Okinawadiag} />
            <Route path="/terms" component={Terms} />
            <Route path="/findDetails3" component={FindDetails3} />
            <Route path="/forEdit3" component={ForEdit3} />
          </LogContext>


          <Route path="/inspectionHome" component={InspectionHome} />
          <CtTestContext>
            <Route path="/ct1" component={Ct1} />
            <Route path="/ct2" component={Ct2} />
            <Route path="/ct3" component={Ct3} />
            <Route path="/ct4" component={Ct4} />
            <Route path="/ct5" component={Ct5} />
            <Route path="/ct6" component={Ct6} />
            <Route path="/ct7" component={Ct7} />
          </CtTestContext>
          <MriTestContext>
            <Route path="/mri1" component={Mri1} />
            <Route path="/mri2" component={Mri2} />
            <Route path="/mri3" component={Mri3} />
            <Route path="/mri4" component={Mri4} />
            <Route path="/mri5" component={Mri5} />
            <Route path="/mri6" component={Mri6} />
          </MriTestContext>

          <XrayTestContext>
            <Route path="/xray1" component={Xray1} />
            <Route path="/xray2" component={Xray2} />
            <Route path="/xray3" component={Xray3} />
            <Route path="/xray4" component={Xray4} />
            <Route path="/xray5" component={Xray5} />
            <Route path="/xray6" component={Xray6} />
            <Route path="/xray7" component={Xray7} />
            <Route path="/xray8" component={Xray8} />
            <Route path="/xray9" component={Xray9} />
            <Route path="/xray10" component={Xray10} />
            <Route path="/xray11" component={Xray11} />
            <Route path="/xray12" component={Xray12} />
            <Route path="/xray13" component={Xray13} />
            <Route path="/xray14" component={Xray14} />
            <Route path="/xray15" component={Xray15} />
            <Route path="/xray16" component={Xray16} />
            <Route path="/xray17" component={Xray17} />
            <Route path="/xray18" component={Xray18} />
            <Route path="/xray19" component={Xray19} />
            <Route path="/xray20" component={Xray20} />
            <Route path="/xray21" component={Xray21} />
          </XrayTestContext>

          {/* <Route path='/medical-documentation' component={() => {
            window.location.href = 'http://13.113.108.197';
            return null;
          }} /> */}

          <Route path='/medical-documentation' component={() => {
            window.location.href = 'http://medoc.mip.medical-inclusion.academy';
            return null;
          }} />

        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
