import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "16px",
        marginLeft: "20px",
        color: "black",
        fontSize: "16px",
        backgroundColor: "#99ff66",
        minHeight: "75px",
        minWidth: "28%",
        borderRadius: "46px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "7%",
        paddingTop: "7%",
    }
}));

    function Ct1() {
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct1 - Inspection home page",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

        return(
            <div  >
                <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>

                            <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                                ＣＴ検査({t('test.title.CT')})
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <div style={{ margin: 10 }}>
                    <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
                </div>
                 <div className={classes.content} style={{}}>

                    <Link to="ct2" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation >検査受付<br/> {t('test.title.reception')}</Button></Link>
                    <Link to="ct3" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 更衣<br />{t('test.title.dressing')}</Button></Link>
                    <Link to="ct4" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 問診<br />{t('test.title.question')}</Button></Link>
                    <Link to="ct5" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation > ラインキープ<br />{t('test.title.linekeep')}</Button></Link>
                    <Link to="ct6" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 検査時<br />{t('test.title.during inspection')}</Button></Link>
                    <Link to="ct7" className={classes.link}><Button className={classes.button} variant="contained"  disableElevation> 検査後<br />{t('test.title.after examination')}</Button></Link>
                </div>
                
            
                <Footer />
                </div>
    );
}
export default Ct1;