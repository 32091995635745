import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from "axios"
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        //paddingBottom: "10%",
        //paddingTop: "15%",
    },

    foot:{
        bottom: 0
    },

    div1: {
        width: "95%",
        height: "auto",
        fontSize: "26px",
        backgroundColor: "#6CAFED",
        margin: "20px",
        marginLeft: "20px",
        borderRadius:"10px"
    },

     div2: {
        width: "95%",
         height: "fit-content",
         fontSize: "26px",
         backgroundColor: "#B4C0CB",
         margin: "20px",
         marginLeft: "20px",
         paddingBottom:"20px",
         borderRadius: "10px"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p1: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "44px",
        fontWeight:"bold",
        color: "white"
    },
    p2: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "22px",
        fontWeight: "bold",
        color: "white"
    },
    p3: {
        textAlign: "left",
        marginTop: "20px",
        marginLeft: "20px",
        fontWeight: "bold"
    },
    p4: {
        textAlign: "left",
        marginLeft: "80px"
    }
}));

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

function kana2romaji(string) {

	var arrayedString = string.split('');
	var value = '';

	var triTable = {
		'う゛ぁ': 'va', 'う゛ぃ': 'vi', 'う゛ぅ': 'vu', 'う゛ぇ': 've', 'う゛ぉ': 'vo'
	};

	var biTable = {
		'きゃ': 'kya', 'きゅ': 'kyu', 'きょ': 'kyo',
		'ぎゃ': 'gya', 'ぎゅ': 'gyu', 'ぎょ': 'gyo',
		'しゃ': 'sha', 'しゅ': 'shu', 'しょ': 'sho',
		'じゃ': 'ja', 'じゅ': 'ju', 'じょ': 'jo',
		'ちゃ': 'cha', 'ちゅ': 'chu', 'ちょ': 'cho',
		'ぢゃ': 'dya', 'ぢゅ': 'dyu', 'ぢょ': 'dyo',
		'でゃ': 'dha', 'でゅ': 'dhu', 'でょ': 'dho',
		'にゃ': 'nya', 'にゅ': 'nyu', 'にょ': 'nyo',
		'ひゃ': 'hya', 'ひゅ': 'hyu', 'ひょ': 'hyo',
		'びゃ': 'pya', 'びゅ': 'byu', 'びょ': 'byo',
		'みゃ': 'mya', 'みゅ': 'myu', 'みょ': 'myo',
		'りゃ': 'rya', 'りゅ': 'ryu', 'りょ': 'ryo',
		'てぁ': 'tha', 'てぃ': 'thi', 'てぇ': 'tee',
		'う゛': 'vu', 'あ゛': 'a"',
		'っか': 'kka', 'っき': 'kki', 'っく': 'kku', 'っけ': 'kke', 'っこ': 'kko',
		'っさ': 'ssa', 'っし': 'sshi', 'っす': 'ssu', 'っせ': 'sse', 'っそ': 'sso',
		'った': 'tta', 'っち': 'cchi', 'っつ': 'ttu', 'って': 'tte', 'っと': 'tto',
		'っな': 'nna', 'っに': 'nni', 'っぬ': 'nnu', 'っね': 'nne', 'っの': 'nno',
		'っは': 'hha', 'っひ': 'hhi', 'っふ': 'ffu', 'っへ': 'hhe', 'っほ': 'hho',
		'っま': 'mma', 'っみ': 'mmi', 'っむ': 'mmu', 'っめ': 'mme', 'っも': 'mmo',
		'っや': 'yya', 'っゆ': 'yyu', 'っよ': 'yyo',
		'っら': 'rra', 'っり': 'rri', 'っる': 'rru', 'っれ': 'rre', 'っろ': 'rro',
		'っわ': 'wwa',
		'っが': 'gga', 'っぎ': 'ggi', 'っぐ': 'ggu', 'っげ': 'gge', 'っご': 'ggo',
		'っざ': 'zza', 'っじ': 'jji', 'っず': 'zzu', 'っぜ': 'zze', 'っぞ': 'zzo',
		'っだ': 'dda', 'っぢ': 'ddi', 'っづ': 'ddu', 'っで': 'dde', 'っど': 'ddo',
		'っば': 'bba', 'っび': 'bbi', 'っぶ': 'bbu', 'っべ': 'bbe', 'っぼ': 'bbo',
		'っぱ': 'ppa', 'っぴ': 'ppi', 'っぷ': 'ppu', 'っぺ': 'ppe', 'っぽ': 'ppo'

	};

	var uniTable = {
		'あ': 'a', 'い': 'i', 'う': 'u', 'え': 'e', 'お': 'o',
		'か': 'ka', 'き': 'ki', 'く': 'ku', 'け': 'ke', 'こ': 'ko',
		'さ': 'sa', 'し': 'shi', 'す': 'su', 'せ': 'se', 'そ': 'so',
		'た': 'ta', 'ち': 'chi', 'つ': 'tsu', 'て': 'te', 'と': 'to',
		'な': 'na', 'に': 'ni', 'ぬ': 'nu', 'ね': 'ne', 'の': 'no',
		'は': 'ha', 'ひ': 'hi', 'ふ': 'fu', 'へ': 'he', 'ほ': 'ho',
		'ま': 'ma', 'み': 'mi', 'む': 'mu', 'め': 'me', 'も': 'mo',
        'や': 'ya', 'ゆ': 'yu', 'よ': 'yo', 'っか': 'kka',
		'ら': 'ra', 'り': 'ri', 'る': 'ru', 'れ': 're', 'ろ': 'ro',
		'わ': 'wa', 'を': 'wo', 'ん': 'n',
		'が': 'ga', 'ぎ': 'gi', 'ぐ': 'gu', 'げ': 'ge', 'ご': 'go',
		'ざ': 'za', 'じ': 'ji', 'ず': 'zu', 'ぜ': 'ze', 'ぞ': 'zo',
		'だ': 'da', 'ぢ': 'di', 'づ': 'du', 'で': 'de', 'ど': 'do',
		'ば': 'ba', 'び': 'bi', 'ぶ': 'bu', 'べ': 'be', 'ぼ': 'bo',
		'ぱ': 'pa', 'ぴ': 'pi', 'ぷ': 'pu', 'ぺ': 'pe', 'ぽ': 'po',
		'ぁ': 'xa', 'ぃ': 'xi', 'ぅ': 'xu', 'ぇ': 'xe', 'ぉ': 'xo',
		'ゃ': 'xya', 'ゅ': 'xyu', 'ょ': 'xyo', 'っ': 'xtsu', 'ー': '-'
	};

	if (triTable[string] !== undefined) {
		return triTable[string];
	} else if (biTable[string] !== undefined) {
		return biTable[string];
	}

	var biCheck = new Object();
	for (var k in biTable) {
		var tmp = k.split('');
		biCheck[tmp[0]] = true;
	}

	var triCheck = new Object();
	for (var tk in triTable) {
		var tmp = tk.split('');
		triCheck[tmp[0] + tmp[1]] = true;
		biCheck[tmp[0]] = true;
	}


	var buf = '';
	for (var i = 0; i < arrayedString.length; i++) {
		var str = arrayedString[i];
		buf += str;
		if (buf.length == 3) {
			if (triTable[buf] !== undefined) {
				value += triTable[buf];
			} else {
				tmp = buf.split('');
				value += biTable[tmp[0] + tmp[1]];
				value += uniTable[tmp[2]] === undefined ? tmp[2] : uniTable[tmp[2]];

			}

		} else if (buf.length == 2) {
			if (triCheck[buf] !== undefined) {
			} else if (biTable[buf] !== undefined) {
				value += biTable[buf];
				buf = '';
			} else {
				tmp = buf.split('');
				value += uniTable[tmp[0]];
				value += uniTable[tmp[1]] === undefined ? tmp[1] : uniTable[tmp[1]];
				buf = '';
			}
		} else if (biCheck[buf] !== undefined) {
		} else {
			value += uniTable[str] === undefined ? str : uniTable[str];
			buf = '';
		}


	}

	value += buf !== '' ? uniTable[buf] : '';

	value = value.replace(/([aiueo])ー/gi, '$1');
	return value;
}

function WordDetails3() {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        text1: '',
    });

    const words = (decodeURI(getQueryVariable("find")));
    const request = {       
        words: words,
        type: 2
    }
    const setText1 = (event) => {
        setValues({ ...values, text1: event.target.value });
    }
    const history = useHistory();
    const find = () => {
        var findVal = values.text1
        history.push("/wordDetails3?find=" + findVal);
    }

    //const base_url = "http://localhost:5000";
    const base_url = process.env.REACT_APP_BACKEND_URL;
        axios.post(base_url+'/okinawaWords/findWords', request).then((response) => {
        const list = response.data.length;
        console.log(list);
        const data = response.data;
        const div1 = document.getElementById('div1');
        const div2 = document.getElementById('div2');
            if (list > 0) {
                var testStr = data[0].words;
                var result1 = kana2romaji(testStr);
        div2.removeAttribute("hidden");
        div1.innerHTML =
            '<p><span style="text-align: left; margin-left: 20px; margin-top: 20px;font-size: 44px; color: white">' + data[0].words + '</span><span style="text-align: left; margin-left: 20px; margin-top: -15px;font-size: 20px; color: white">「' + result1 + '」</span></p >' +
            //'<p style="text-align: left; margin-left: 20px; margin-top: -15px;font-size: 20px; color: white">「' + result1 + '」</p >' +
            '<p style="text-align: left; margin-left: 20px; color: white" >' + data[0].explain + '</p>';
        div2.innerHTML =
            '<p style="text-align: left; margin-left: 20px; margin-top: 20px;font-weight: bold">詳細</p >' +
            '<p style="text-align: left; margin-left: 80px " >' + data[0].detail + '</p>';
        } else {
            axios.post(base_url +'/okinawaWords/showWords', request).then((response) => {
                const data2 = response.data;
                const list2 = data2.length;
                const found = [];
                var content = '<p style="text-align: left; margin-left: 20px; margin-top: 20px;font-size: 44px; color: white"> Sorry, we can\'t find the word</p >';
                if (list2 > 0) {

                    
                    for (var i = 0; i < list2; i++) {
                        found.push(data2[i].words);
                    }
                    history.push("/wordDetails4?word=" + found);
                   // for (var i = 0; i < list2; i++) {
                        // words = words + '<p><a href="/wordDetails?find=' + data2[i].words + '" style="text-align: left; margin-left: 20px; font-size: 15px; color: white;margin-top: -10px;">' + data2[i].words + '</a></p >';                                
                   //}                              
                   /* div1.innerHTML =
                        content +
                        '<p style="text-align: left; margin-left: 20px; margin-top: 20px;font-size: 22px; color: white"> Do you want to find words like these:</p >' +
                        words + '<br></br>';     */         
                 
                } else {
                    div1.innerHTML = content + '<br></br>';
                }
                div2.setAttribute("hidden","true");
            }).catch((error) => {
                console.log(error);
            });     
            axios.post(base_url +'/userAddWords/addWords', request);
        }
}) .catch((error) => {
        console.log(error);
});



        return (
            <div>            
                 <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                Word Detail
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
                <CssBaseline />   
                <div className={classes.content}>
                    <TextField className={classes.textFiled} defaultValue={values.text1} onChange={setText1} id="text1" variant="outlined" label="Input your word" style={{ width: "62%", marginTop: "20px", paddingLeft: "10px", borderRadius: "15px" }} />
                    <Button className={classes.button} variant="contained" color="primary" disableElevation style={{ marginTop: "20px" }} onClick={find}> {t('search')}</Button>
                    <Link to="/okinawadic"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ backgroundColor: "#B4C0CB", marginRight: "20px", marginTop: "20px" }} > {t('back')}</Button></Link>
                    <div className={classes.div1} id="div1">
                    </div >
                    <div className={classes.div2} id="div2" hidden>                     
                    </div >
                </div>
                </Container>
                <Footer />
            </div>
        );
   }

export default WordDetails3;
