import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray12() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray12 - Clavicle",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti11_2] = useSound('/sound/11/'+language+'11/11-02.mp3');
    const [playXRAYmulti11_8] = useSound('/sound/11/' + language + '11/11-08.mp3');
    const [playXRAYmulti11_9] = useSound('/sound/11/' + language + '11/11-09.mp3');
    const [playXRAYmulti11_10] = useSound('/sound/11/' + language + '11/11-10.mp3');
    const [playXRAYmulti11_15] = useSound('/sound/11/' + language + '11/11-15.mp3');
    const [playXRAYmulti11_16] = useSound('/sound/11/' + language + '11/11-16.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/' + language + '13/13-14.mp3');
   
    const [playXRAYjp11_2] = useSound('/sound/11/jp11/11-02.mp3');
    const [playXRAYjp11_8] = useSound('/sound/11/jp11/11-08.mp3');
    const [playXRAYjp11_9] = useSound('/sound/11/jp11/11-09.mp3');
    const [playXRAYjp11_10] = useSound('/sound/11/jp11/11-10.mp3');
    const [playXRAYjp11_15] = useSound('/sound/11/jp11/11-15.mp3');
    const [playXRAYjp11_16] = useSound('/sound/11/jp11/11-16.mp3');
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            鎖骨({t('test.title.clavicle')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.photoOfClavicle}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>鎖骨の撮影をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of clavicle')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.sitDown}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>椅子に座って撮影しますので、腰かけてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.sit down')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.photoOfStanding}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>立って撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_9}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of standing')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_9}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.touchBodyTellIfHurts}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>位置合わせで触りますので痛かったら教えてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.touch body tell if hurts')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_10}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.putYourBackAndStandUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>背中を板に付けて立ってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_15}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put your back and stand up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_15}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.machineWillMove}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>前の機械が動きますがそのまま動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp11_16}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.machine will move')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti11_16}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.clavicle.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
              </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray12;