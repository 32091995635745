import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from "axios";
import CssBaseline from '@material-ui/core/CssBaseline';
import UserContext from '../context/UserContext.js';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        //paddingBottom: "10%",
        //paddingTop: "15%",
    },

    foot:{
        bottom: 0
    },

    div1: {
        width: "95%",
        height: "auto",
        fontSize: "26px",
        backgroundColor: "#6CAFED",
        margin: "20px",
        marginLeft: "20px",
        borderRadius:"10px"
    },
    div3: {
        width: "95%",
        height: "auto",
        fontSize: "26px",
        backgroundColor: "#F9B1B1",
        margin: "20px",
        marginLeft: "20px",
        borderRadius: "10px"
    },

     div2: {
        width: "95%",
         height: "fit-content",
         fontSize: "26px",
         backgroundColor: "#B4C0CB",
         margin: "20px",
         marginLeft: "20px",
         paddingBottom:"20px",
         borderRadius: "10px"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p1: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "44px",
        fontWeight:"bold",
        color: "white"
    },
    p2: {
        textAlign: "left",
        marginLeft: "20px",
        marginTop: "20px",
        fontSize: "22px",
        fontWeight: "bold",
        color: "white"
    },
    p3: {
        textAlign: "left",
        marginTop: "20px",
        marginLeft: "20px",
        fontWeight: "bold"
    },
    p4: {
        textAlign: "left",
        marginLeft: "80px"
    }
}));

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

function EditSentence() {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const [values, setValues] = useState({
        text1: '',
        text2: '',
        text3: (decodeURI(getQueryVariable("letter"))),
    });

    const setText1 = (event) => {
        setValues({ ...values, text1: event.target.value });
    }

    const setText2 = (event) => {
        setValues({ ...values, text2: event.target.value });
    }
    const setText3 = (event) => {
        setValues({ ...values, text3: event.target.value });
    }
    //const base_url = "http://localhost:5000";
    const base_url = process.env.REACT_APP_BACKEND_URL;

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'x-auth-token': userData.token,
        },
    });

    console.log(userData.user);
    const user = userData.user;
    var arr = [];
    for (let i in user) {
        arr.push(user[i]); //属性
        //arr.push(obj[i]); //值
    }
    console.log(arr[1]);
    const userName = arr[1];

    const find = () => {
        var findVal1 = values.text1;
        var findVal2 = values.text2;
        var findVal3 = values.text3;
        var myDate = new Date();
        var date = myDate.toLocaleString();
        const request = {
            letter: findVal3,
            phraseOri: findVal1,
            phraseAft: findVal2,
            changeUser: userName,
            changeDate: date,
            remark : 1
        }
        axios.post(base_url + '/okinawaphrase/addPhrase', request).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
        alert("追加完了");
        history.push("okinawaBasicSentence");
    }

    const history = useHistory();

        return (
            <div>            
                 <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                Sentence Adding
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
                <CssBaseline />   
                <div className={classes.content}>
                    <div className={classes.div3} id="div3">
                            <p style={{ textAlign: "left", marginLeft: "20px", marginTop: "20px" }}>ひらがな:*</p >
                            <TextField style={{ textAlign: "left", marginLeft: "20px", width: "90%" }} defaultValue={values.text3} id="text3" onChange={setText3} disabled></TextField>
                    </div >
                    <div className={classes.div1} id="div1">
                            <p style={{ textAlign: "left", marginLeft: "20px", marginTop: "20px" }}>沖縄弁:*</p >
                            <TextField style={{ textAlign: "left", marginLeft: "20px", width: "90%" }} defaultValue={values.text1} id="text1" onChange={setText1} required></TextField>
                    </div >
                    <div className={classes.div2} id="div2"> 
                            <p style={{ textAlign: "left", marginLeft: "20px", marginTop: "20px" }}>標準日本語:*</p >
                            <TextField style={{ textAlign: "left", marginLeft: "20px", width: "90%" }} defaultValue={values.text2} id="text2" onChange={setText2} required></TextField>
                    </div >
                        <Button className={classes.button} variant="contained" color="primary" disableElevation style={{ marginRight: "20px", marginTop: "20px" }} onClick={find}> {t('add')}</Button>
                        <Link to="/okinawaBasicSentence"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{ backgroundColor: "#B4C0CB", marginRight: "20px", marginTop: "20px" }} > {t('back')}</Button></Link>          
                    </div>
                </Container>
                <Footer />
            </div>
        );
   }

export default EditSentence;
