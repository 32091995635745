import React, { useEffect, useContext }  from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import { ObstetricContext } from '../context/ObstetricgynecologyContext';
import UserContext from '../context/UserContext';
import Axios from "axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,
       
        textAlign: 'center',
        margin:10,
        padding:3
      },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));




const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const problemtodays = [
        {name: t('obstetricGynecology.Pregnancy'), text: ' /妊娠', value:"Pregnancy"},
        {name: t('obstetricGynecology.Menstrualdisorder'), text: ' /月経異常', value:"Menstrualdisorder"},
        {name: t('obstetricGynecology.Menstrualpain'), text: ' /月経痛', value:"Menstrualpain"},
        {name: t('obstetricGynecology.Vaginaldischarge'), text: ' /おりもの', value:"Vaginaldischarge"},
        {name: t('obstetricGynecology.Abnormalvaginalbleeding'), text: ' /不正出血', value:"Abnormalvaginalbleeding"},
        {name: t('obstetricGynecology.Painwhenurinating'), text: ' /排尿時痛', value:"Painwhenurinating"},
        {name: t('obstetricGynecology.Difficultyurinating'), text: ' /尿がでにくい', value:"Difficultyurinating"},
        {name: t('obstetricGynecology.Hematuria'), text: ' /尿に血が混じる', value:"Hematuria"},
        {name: t('obstetricGynecology.Pyuria'), text: ' /尿に膿が混じる', value:"Pyuria"},
        {name: t('obstetricGynecology.Perineumrash'), text: ' /会陰部にできもの', value:"Perineumrash"},
        {name: t('obstetricGynecology.Rednessandswelling'), text: ' /赤く脹れている', value:"Rednessandswelling"},
        {name: t('obstetricGynecology.Havepain'), text: ' /痛みがある', value:"Havepain"},
        {name: t('obstetricGynecology.Itchiness'), text: ' /かゆみ', value:"Itchiness"},
        {name: t('obstetricGynecology.Urinaryincontinence'), text: ' /尿失禁', value:"Urinaryincontinence"},
        {name: t('obstetricGynecology.Fecalincontinence'), text: ' /便失禁', value:"Fecalincontinence"},
        {name: t('obstetricGynecology.Uterineprolapse'), text: ' /子宮脱', value:"Uterineprolapse"},
        {name: t('obstetricGynecology.Consultationonfertilitytreatment'), text: ' /不妊の相談', value:"Consultationonfertilitytreatment"},
        {name: t('obstetricGynecology.Vomiting'), text: ' /嘔吐', value:"Vomiting"},
        {name: t('obstetricGynecology.Nausea' ), text: ' /嘔気', value:"Nausea"},
        {name: t('obstetricGynecology.Cancerscreening'), text: ' /がん健診', value:"Cancerscreening"},
        {name: t('obstetricGynecology.Other(s)' ), text: ' /その他', value:"Other(s)"},
        {name: t('obstetricGynecology.advisedbyanother'), text: ' /他の医療機関から受診するように勧められた（健診含む）', value:"advisedbyanother"},
        
       ]
    

    const [values, setValues] = useContext(ObstetricContext);
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    });

    const authAxios = Axios.create({
        baseURL: "http://localhost:5000",
        headers: {
          'x-auth-token': userData.token,
        },
      });

    const logFunction = async (question) =>{
        const loginfo = {
            inteviewName: "Obstetric and Gynecology form 2",
            //userName: userData.user.userName,
            language: i18n.language,
            contentSentence: question,
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
    } 

    const handleToggleProblemtoday = (object) => () => {
        const currentIndex = values.problemtoday.indexOf(object.value);
        const newChecked = [...values.problemtoday];
        
        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        
         setValues({...values, problemtoday:newChecked});
         logFunction("What is the problem today");
    };

   

    return (

        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex:1 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.problemtoday')}<br/><i style={{ color: "#0000ffbf" }}>/今日はどのような症状がありますか。（複数ある方は複数☑してください.)</i></h4></strong></Paper>
                    </Grid>
                    
                    <Grid container spacing={3} style={{ padding: 20 }}>
                    <GridList container style={{ height: 332, paddingLeft: 20,paddingRight: 20, paddingTop: 16, paddingBottom: 15, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={3}>
                        {problemtodays.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem  key={value.name} role={undefined} dense button onClick={handleToggleProblemtoday(value)}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            checked={values.problemtoday.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                            {value.name}
                                            <span style={{color: "blue" }}>{value.text}</span>
                                        </ListItemText>
                                </ListItem>
                            );
                        })}
                    </GridList>

                    
                        </Grid>
                    </Grid>

            </div>
        </div>

    )
}
export default CenteredGrid;