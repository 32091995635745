import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { MriContext } from "../mriContext"
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Mri6() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(MriContext);
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "mri6 - After examination",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [otherTest, setOtherTest] = React.useState('');

    const handleChangeOtherTest = event => {
        setOtherTest(event.target.value);
        setValues({ ...values, otherTest: event.target.value })
    };

    const language = i18n.language;
    const [playMRImulti5_1] = useSound('/sound/5/'+language+'5/5-01.mp3');
    const [playMRImulti5_2] = useSound('/sound/5/' + language + '5/5-02.mp3');
    const [playMRImulti5_3] = useSound('/sound/5/' + language + '5/5-03.mp3');
    const [playMRImulti5_4] = useSound('/sound/5/' + language + '5/5-04.mp3');
    const [playMRImulti5_5] = useSound('/sound/5/' + language + '5/5-05.mp3');
    const [playMRImulti5_6] = useSound('/sound/5/' + language + '5/5-06.mp3');
    const [playMRImulti5_7] = useSound('/sound/5/' + language + '5/5-07.mp3');
   
    const [playMRIjp5_1] = useSound('/sound/5/jp5/5-01.mp3');
    const [playMRIjp5_2] = useSound('/sound/5/jp5/5-02.mp3');
    const [playMRIjp5_3] = useSound('/sound/5/jp5/5-03.mp3');
    const [playMRIjp5_4] = useSound('/sound/5/jp5/5-04.mp3');
    const [playMRIjp5_5] = useSound('/sound/5/jp5/5-05.mp3');
    const [playMRIjp5_6] = useSound('/sound/5/jp5/5-06.mp3');
    const [playMRIjp5_7] = useSound('/sound/5/jp5/5-07.mp3');

    
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査後({t('test.title.after examination')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.mriComplete}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査は終わりになりますが、ご気分にお変わりはないですか？
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.MRI complete')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlipAfter}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この受付票をお持ちください<br />{t('test.after.take slip')}</h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.waitUntilBedLowerd}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台が安全の高さに下がるまで寝たままでお待ちください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.wait until bed lowerd')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.bedlowerGetOff}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>寝台が下がりましたので、こちら側からお降りください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.bed lower get off')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.putOutNeedle}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちらで針を抜くので寝ててください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.put out needle')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.pressHere}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ご自身で5分ほど押さえていてください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.press here')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.pressHard}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>抑えが甘いと出血する恐れがあります
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.press hard')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.bleedingStop}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>血が止まったことを確認してからお着換えください
                                <IconButton aria-label="Play/pause" onClick={playMRIjp5_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.after.bleeding stop')}
                                <IconButton aria-label="Play/pause" onClick={playMRImulti5_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>ほかに検査がありますか？<br />{t('test.after.other test')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="otherTest" name="otherTest" value={values.otherTest} onChange={handleChangeOtherTest}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>}/>
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={11}>
                    <div>
                        {values.otherTest === 'yes' ? (

                            <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1" }}>
                                <FormControlLabel
                                    value={values.otherExam}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<h5>他に検査があるようでしたら、その指示に従ってください<br />{t('test.after.other exam')}</h5>} />

                            </Paper>
                        ) : (
                                <nav> &apos; </nav>
                            )}

                    </div>
                    <div>
                        {values.otherTest === 'no' ? (

                            <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1" }}>
                                <FormControlLabel
                                    value={values.casher}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<h5>お会計にお向かいください<br />{t('test.after.casher')}</h5>} />

                            </Paper>
                        ) : (
                                <nav> &apos; </nav>
                            )}

                    </div>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.outpatientsDepartment}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>外来受診科にお向かいください<br />{t('test.after.outpatients department')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.leaveClothesAndKey}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>脱いだ検査着と鍵は更衣室に置いていってください<br />{t('test.after.leave clothes and key')}</h5>} />

                    </Paper>

                </Grid >
               
         </Grid>
            <div  style={{margin:20}}>

                <Link to="mri1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >MRI HOME</Button></Link>
                
            </div>
            <Footer />

        </div>
                
    );
}
 
export default Mri6;