import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Axios from "axios";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MedContext } from '../context/internalMedContext'
import UserContext from '../context/UserContext.js';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,

        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const symptomoccurs = [
        { name: t('internalMedcine.Morning'), text:'/ 朝', value: "Morning" },
        { name: t('internalMedcine.Daytime'), text:'/ 昼', value: "Daytime" },
        { name: t('internalMedcine.Evening'), text:'/ 夕方', value: "Evening" },
        { name: t('internalMedcine.Whileinbed'), text:'/ 就寝中', value: "Whileinbed" },
        { name: t('internalMedcine.Whenwakingup'), text:'/ 起床時', value: "Whenwakingup" },
        { name: t('internalMedcine.Irregular'), text:'/ 不定期', value: "Irregular" },
        { name: t('internalMedcine.Other'), text:'/ その他', value: "Other" },
    ]

    const symptomlikes = [
        { name: t('internalMedcine.Constant'), text:'/ 絶え間なく、続いている', value: "Constant" },
        { name: t('internalMedcine.symptomcomesgoes'), text:'/ 症状が出たり消えたりしている', value: "symptomcomesgoes" },
        { name: t('internalMedcine.symptomgraduallyworsening'), text:'/ 徐々にひどくなってきている', value: "symptomgraduallyworsening" },
        { name: t('internalMedcine.Other'), text:'/ その他', value: "Other" },
    ]

    const [values, setValues] = useContext(MedContext);
    const classes = useStyles();
    const [checkedSymptomoccurs, setCheckedSymptomoccurs] = React.useState([]);
    const [checkedSymptomlikes, setCheckedSymptomlikes] = React.useState([]);
    const [frequency, setFrequency] = React.useState('');
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const { userData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    });

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

      const logFunction = async (question) =>{
        const loginfo = {
            inteviewName: "Internal Medcine form1",
            //userName: userData.user.userName,
            language: i18n.language,
            contentSentence: question,
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
    }  

    const handleToggleSymptomlikes = (object) => () => {
        const currentIndex = values.symptomlike.indexOf(object.value);
        const newChecked = [...values.symptomlike];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedSymptomlikes(newChecked);
        setValues({...values, symptomlike:newChecked});
        logFunction("How is the symptom like");
    };

    const handleToggleSymptomoccurs = (object) => () => {
        const currentIndex = values.symptomoccur.indexOf(object.value);
        const newChecked = [...values.symptomoccur];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedSymptomoccurs(newChecked);
        setValues({...values, symptomoccur:newChecked});
        logFunction("When does the symptoms occur");
    };
    const updateFrequency = (event) => {
        setFrequency(event.target.value);
        setValues({...values, scale1to10:event.target.value});
        logFunction("If you describe the symptom on a scale of 1 - 10, how severe is it? Select the number below.");
    };

    const updateDate = (date) => {
        setDate(date)
        setValues({...values, symptomstart:date})
        logFunction("When did the symptom start Date");
    }
    const updateTime = (time) => {
        setTime(time)
        setValues({...values, symtomstarttime:time})
        logFunction("When did the symptom start Time");
    }

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('internalMedcine.symptomoccur')}? <br /><i style={{color:"blue"}}>/症状はいつ頃起こりますか？</i></h5></strong></Paper>
                    </Grid>

                    <GridList style={{ height: 120, padding: 20, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {symptomoccurs.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleSymptomoccurs(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.symptomoccur.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                    </GridList>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('internalMedcine.symptomlike')}? <br /><i style={{color:"blue"}}>/症状はどのような性質を持っていますか</i></h5></strong></Paper>
                    </Grid>

                    <GridList style={{ height: 90, padding: 20, width:"inherit" }} cellHeight={10} className={classes.gridList} cols={4}>
                        {symptomlikes.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleSymptomlikes(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.symptomlike.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                    </GridList>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('internalMedcine.scale1to10')}<br /><i style={{color:"blue"}}>/その症状の程度を数字で表すと、どのぐらいですか？下の数字から選びなさい。</i></h5></strong></Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth xs={8} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.scale1to10}
                                onChange={updateFrequency}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>



                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('internalMedcine.symptomstart')}?<br /> <i style={{color:"blue"}}>/この症状はいつからありますか</i></h5></strong></Paper>
                    </Grid>
                    <Grid  style={{width:"inherit"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Grid container justify="space-around" >
                                    <Grid item xs={6} style={{paddingLeft:20, paddingRight:20}}>
                                <KeyboardDatePicker 
                                    fullWidth
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('internalMedcine.Date')}
                                    format="MM/dd/yyyy"
                                    value={values.symptomstart}
                                    onChange={updateDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                </Grid>
                                <Grid item xs={6} style={{paddingLeft:20, paddingRight:20}}>
                                <KeyboardTimePicker 
                                   
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label={t('internalMedcine.Time')}
                                    value={values.symtomstarttime}
                                    onChange={updateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }} />
                                    </Grid>
                            </Grid>

                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}
export default CenteredGrid;