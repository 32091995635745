import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { MedContext } from '../context/internalMedContext'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import UserContext from '../context/UserContext';
import Axios from "axios";
import { FormLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    gridList: {
        width: 500,
        height: 300,
        textAlign: 'center',
        margin: 10,
        padding: 13
    },
    ListItemIcon: {
        minWidth: 32
    }
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();

    const foodAlergie = [
        { name: t('internalMedcine.FishRoe'), text: '/魚卵', value: 'FishRoe' },
        { name: t('internalMedcine.Shellfish'), text: '/貝類', value: 'Shellfish' },
        { name: t('internalMedcine.Milk'), text: '/牛乳', value: 'Milk' },
        { name: t('internalMedcine.Egg'), text: '/卵', value: 'Egg' },
        { name: t('internalMedcine.Buckwheat'), text: '/そば', value: 'Buckwheat' },
        { name: t('internalMedcine.Peanuts'), text: '/ピーナッツ', value: 'Peanuts' },
        { name: t('internalMedcine.Almonds'), text: '/アーモンド', value: 'Almonds' },
        { name: t('internalMedcine.Wheat'), text: '/小麦', value: 'Wheat' },
        { name: t('internalMedcine.Soy'), text: '/大豆', value: 'Soy' },
        { name: t('internalMedcine.Kiwifruit'), text: '/キウイ', value: 'Kiwifruit' },
        { name: t('internalMedcine.Peaches'), text: '/桃', value: 'Peaches' },
        { name: t('internalMedcine.Yams'), text: '/山芋', value: 'Yams' },
        { name: t('internalMedcine.Blue-skinfish'), text: '/青魚', value: "Blue-skinfish" },
        { name: t('internalMedcine.ShrimpPrawns'), text: '/甲殻類（エビ、カニ等)/発熱', value: "ShrimpPrawns" },
    ];

    const medecinesAlergie = [
        { name: t('internalMedcine.Alcohol'), text: '/アルコール', value: "Alcohol" },
        { name: t('internalMedcine.Feverreducer'), text: '/解熱剤', value: "Feverreducer" },
        { name: t('internalMedcine.Painkiller'), text: '/痛み止め', value: "Painkiller" },
        { name: t('internalMedcine.Antibiotics'), text: '/抗生物質', value: "Antibiotics" },
        { name: t('internalMedcine.Medicineforstomach'), text: '/胃腸薬', value: "Medicineforstomach" },
        { name: t('internalMedcine.Anesthetic'), text: '/麻酔薬', value: "Anesthetic" }
    ];

    const [values, setValues] = useContext(MedContext);

    const classes = useStyles();

    const [email, setEmail] = useState('')
    const [emailCheck, setEmailCheck] = useState('')
    const [msg, setMsg] = useState('')
    const [date, setDate] = useState('')
    const [sex, setSex] = useState('');

    const [checkedFood, setCheckedFood] = React.useState([]);
    const [checkedMedecine, setCheckedMedecine] = React.useState([]);
    const { userData } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    });

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'x-auth-token': userData.token,
        },
    });

    const logFunction = async (question) => {
        const loginfo = {
            inteviewName: "Internal Medcine form1",
            //userName: userData.user.userName,
            language: i18n.language,
            contentSentence: question,
            date: new Date,
            userId: userData.user.id,
        };
        const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
        );
    }

    const handleToggleFood = (value) => () => {
        const currentIndex = values.allergis.foods.indexOf(value.value);
        const newChecked = [...values.allergis.foods];

        if (currentIndex === -1) {
            newChecked.push(value.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        //setCheckedFood({allergis:{...values.allergis, foods:newChecked}});
        setValues({ ...values, allergis: { ...values.allergis, foods: newChecked } })
        logFunction("Food Allergie")
    };


    const handleToggleMedecin = (value) => () => {
        const currentIndex = values.allergis.medcine.indexOf(value.value);
        const newChecked = [...values.allergis.medcine];

        if (currentIndex === -1) {
            newChecked.push(value.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        //setCheckedMedecine({allergis:{...values.allergis, medcine:newChecked}});
        setValues({ ...values, allergis: { ...values.allergis, medcine: newChecked } });
        logFunction("Medicine Allergie");
    };
    //console.log(checkedFood)
    // console.log(checkedMedecine)

    const updateName = (e) => {
        // setName(e.target.value);
        setValues({ ...values, name: e.target.value })
        logFunction("Patient name")
    }
    console.log(values)

    const updateHeight = (e) => {
        // setHeight(e.target.value);
        setValues({ ...values, height: e.target.value })
        logFunction("Patient height")
    }

    const updateWeight = (e) => {
        //setWeight(e.target.value);
        setValues({ ...values, weight: e.target.value })
        logFunction("Patient Weight")
    }

    const updateDate = (date) => {
        setDate(date.toLocaleDateString('en-GB'))
        setValues({ ...values, DateOfBirth: date });
        logFunction("Date of birth")
    }

    const updateSex = (event) => {
        //setSex(event.target.value);
        setValues({ ...values, sex: event.target.value });
        logFunction("Patient sex")
    };

    const update = (e) => {
        setValues({ ...values, [e.target.id]: e.target.value });

    }

    const EmailCheck = (e) => {
        if (e.target.value !== values.email){
            setValues({ ...values, msg : "Enter the same password twice for verification."})
        }else{
            setValues({ ...values, [e.target.id]: e.target.value })
        }
    }


    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off">
            <Grid container spacing={3} style={{ padding: 20 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h4>{t('internalMedcine.PersonnalInformation')} <i style={{ color: "blue" }}>/個人情報</i></h4></strong></Paper>
                </Grid>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={6} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <FormControl fullWidth className=''>
                                <InputLabel htmlFor="lastName">{t('internalMedcine.LastName')} <i style={{ color: "#0000ffbf" }}>/姓(ファミリーネーム)</i></InputLabel>
                                <Input
                                    id="lastName"
                                    type="text"
                                    //values={name}
                                    defaultValue={values.lastName}
                                    onChange={update}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <FormControl fullWidth className=''>
                                <InputLabel htmlFor="firstName">{t('internalMedcine.FirstName')} <i style={{ color: "#0000ffbf" }}>/名(ファーストネーム）</i></InputLabel>
                                <Input
                                    id="firstName"
                                    type="text"
                                    //values={name}
                                    defaultValue={values.firstName}
                                    onChange={update}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid style={{ paddingLeft: 20, paddingRight: 20 }} item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <FormControl fullWidth className={classes.formControl}>

                                        <KeyboardDatePicker
                                            fullWidth
                                            margin="normal"
                                            labelId="date-picker-dialog"
                                            id="date-picker-dialog1"
                                            label={t('internalMedcine.Dateofbirth')}
                                            format="yyyy/MM/dd"
                                            value={values.DateOfBirth}
                                            onChange={updateDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </FormControl>

                                </Grid>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 16 }} item xs={6}>
                            <FormControl fullWidth xs={6} className=''>
                                <InputLabel htmlFor="height">{t('internalMedcine.Height')}<i style={{ color: "#0000ffbf" }}>/身長</i></InputLabel>
                                <Input
                                    id="height"
                                    type="number"
                                    //values={height}
                                    defaultValue={values.height}
                                    onChange={updateHeight}
                                />
                            </FormControl>

                        </Grid>
                        <Grid container>
                        <Grid item xs={6} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <FormControl fullWidth className=''>
                                <InputLabel error required htmlFor="email">{t('internalMedcine.email')} <i style={{ color: "#0000ffbf" }}>/</i></InputLabel>
                                <Input
                                    id="email"
                                    type="email"
                                    //values={name}
                                    defaultValue={values.email}
                                    onChange={update}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <FormControl fullWidth className=''>
                                <InputLabel error required htmlFor="emailCheck">{t('internalMedcine.emailCheck')} <i style={{ color: "#0000ffbf" }}>/</i></InputLabel>
                                <Input
                                    id="emailCheck"
                                    type="email"
                                    //values={name}
                                    defaultValue={values.emailCheck}
                                    onChange={update}
                                />
                            </FormControl>
                        </Grid>
                        </Grid>
                    </Grid>
                  

                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t('internalMedcine.Sex')}<i style={{ color: "#0000ffbf" }}>/性別</i></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.sex}
                            onChange={updateSex}
                        >
                            <MenuItem value="Male">{t('internalMedcine.Male')}</MenuItem>
                            <MenuItem value="Female">{t('internalMedcine.Female')}</MenuItem>
                        </Select>
                    </FormControl>

                    <Grid style={{ paddingTop: 16 }}>
                        <FormControl fullWidth xs={6} className=''>
                            <InputLabel htmlFor="weight">{t('internalMedcine.Weight')}<i style={{ color: "#0000ffbf" }}>/体重</i></InputLabel>
                            <Input
                                id="weight"
                                type="number"
                                //values={weight}
                                defaultValue={values.weight}
                                onChange={updateWeight}
                            />
                        </FormControl>
                    </Grid>
                    <Grid style={{ paddingTop: 39 }}>
                            {
                                values.email !== values.emailCheck ? (
                                    <i style={{ color: "#f44336" }}>{t('internalMedcine.samepassword')}</i>
                                ):(
                                    <nav> &apos; </nav>
                                )
                            
                            }   
                        </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h4>{t('internalMedcine.Allergie')} <span style={{ color: "blue" }}>/アレルギー</span></h4></strong></Paper>
                </Grid>
                <br />
                <Grid container spacing={3} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.FoodAllergie')} <span style={{ color: "blue" }}>/食物アレルギー</span></h4></strong></Paper>
                        <GridList cellHeight={10} className={classes.gridList} cols={2} style={{ width: "inherit" }}>
                            {foodAlergie.map((value) => {
                                const labelId = `checkbox-list-label-${value.name}`;

                                return (
                                    <ListItem key={value.name} role={undefined} dense button onClick={handleToggleFood(value)}>
                                        <ListItemIcon className={classes.ListItemIcon}>
                                            <Checkbox
                                                edge="start"
                                                checked={values.allergis.foods.indexOf(value.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} >
                                            {value.name}
                                            <span style={{ color: "blue" }}>{value.text}</span>
                                        </ListItemText>


                                    </ListItem>
                                );
                            })}
                        </GridList>

                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.MedicineAllergie')}<span style={{ color: "blue" }}>/薬アレルギー</span></h4></strong></Paper>
                        <GridList cellHeight={10} className={classes.gridList} cols={1} style={{ width: "inherit" }}>
                            {medecinesAlergie.map((value) => {
                                const labelId = `checkbox-list-label-${value.name}`;

                                return (
                                    <ListItem key={value.name} role={undefined} dense button onClick={handleToggleMedecin(value)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={values.allergis.medcine.indexOf(value.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} >
                                            {value.name}
                                            <span style={{ color: "blue" }}>{value.text}</span>
                                        </ListItemText>

                                    </ListItem>
                                );
                            })}
                        </GridList>


                    </Grid>
                </Grid>
            </Grid>
            </form>
        </div>
    );
}
export default CenteredGrid;