import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: []
        }
    }


    componentDidMount() {
        const _this = this;  
        const request = {
            words: decodeURI(getQueryVariable("letter"))
        }
    //const base_url = "http://localhost:5000";
    const base_url = process.env.REACT_APP_BACKEND_URL;

        axios.post(base_url +'/okinawaWords/diagWords', request)
            .then((response) => {
                _this.setState({
                    datas: response.data,
                });
            });
    }
    
    render() {
        return (
            this.state.datas.map((data, i) => {
                if (i % 4 == 0) {
                    return (
                        <div style={{ fontSize: "20px", float: "left", backgroundColor: "#6CAFED", width: "25%", marginTop: "20px" }} id="div1">
                            <Link to={'/wordDetails5?find=' + data.words} key={i}><p style={{ marginLeft: "50px", color: "white" }}>
                                {data.words}
                            </p></Link>
                        </div>
                    )
                } else if (i % 4 == 1) {
                    return (
                        <div style={{ fontSize: "20px",float: "left",backgroundColor: "#6CAFED",width: "25%",marginTop: "20px" }} id="div2">
                            <Link to={'/wordDetails5?find=' + data.words} key={i}><p style={{ marginLeft: "50px", color: "white" }}>
                                {data.words}
                            </p></Link>
                        </div>
                    )
                } else if (i % 4 == 2) {
                    return (
                        <div style={{ fontSize: "20px", float: "left", backgroundColor: "#6CAFED", width: "25%", marginTop: "20px" }} id="div3">
                            <Link to={'/wordDetails5?find=' + data.words} key={i}><p style={{ marginLeft: "50px", color: "white" }}>
                                {data.words}
                            </p></Link>
                        </div>
                    )
                } else{
                    return (
                        <div style={{ fontSize: "20px", float: "left", backgroundColor: "#6CAFED", width: "25%", marginTop: "20px" }} id="div4">
                            <Link to={'/wordDetails5?find=' + data.words} key={i}><p style={{ marginLeft: "50px", color: "white" }}>
                                {data.words}
                            </p></Link>
                        </div>
                    )
                }
            })
        )
    }
}

export default List;