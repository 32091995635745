import React, {useState, createContext} from 'react';

export const XrayContext = createContext();

export const XrayTestContext = props => {
    const [values, setValues] = useState({
        name: '',
        DateOfBirth: new Date,
        height: '',
        weight: '',
        sex: '',
        

        //reception
        xray: '',
        passTicket:'',
        importCD: '',
        bringPinkSlip: '',
        calledByNumber: '',
        sitAndWait:'',

        //enter
        goThisWay: '',
        receiveSlip: '',
        tellFullName: '',
        putLuggage: '',
        photoOfHead: '',
        areYouPregnant: '',
        photoOfHead: '',
        photoOfChest: '',
        photoOfAbdomen: '',
        photoOfSpine: '',
        photoOfPelvis: '',
        photoOfShoulder: '',
        photoOfHand: '',
        photoOfLegs: '',
        photoOfBothSide: '',
        painRightSide: '',
        painLeftSide: '',
        standUp: '',

        //dressing
        takeOffAccessories: '',
        removeStickingMaterials: '',
        takeOffDenture:'',

        //Xray
        chest: {
            chestOnTheMachine: '',
            moveYourBody: '',
            machineMove: '',
            tryToRelax: '',
            moveElbows: '',
            donotMove: '',
            giveAsignal: '',
            deepBreath: '',
            relaxYourBreath: '',
            holdYourBody: '',
            photoWhenBreathOut: '',
            breatheInThenOut: '',
            turnAndHoldStick: '',
            photoWhenSitting: '',
            putPlateOnYourBack: '',
            lookUp:'',
        },
        abdomen: {
            photoOfStomach: '',
            machineWillGoDown: '',
            holdStickOfMachine: '',
            giveAsignal: '',
            breatheInThenOut: '',
            relaxYourBreath: '',
            lieFaceUp: '',
            putHandsOnTheSide: '',
            touchYourBody: '',
            ifFeelPain:'',
        },
        cranial: {
            photoOfHead: '',
            photoOfFace:'',
            pictureOfLying: '',
            lieFaceUp: '',
            putHandsOnTheSide: '',
            touchYourBody: '',
            ifFeelPain: '',
            lookUp: '',
            lookDown: '',
            lookRight: '',
            lookLeft: '',
            takeAphoto: '',
            photoFromTheSide: '',
            faceTheBoardAndSit:'',
        },
        cervicalVertebra: {
          cervicalSpine: '',
            wholeBack: '',
            thoracicSpine: '',
            lumbarVertebra: '',
            scaralVertebra: '',
            photoOfStanding: '',
            putYourBackOnBoard: '',
            raiseJaw: '',
            tellMeIfHurts: '',
            nowTakeObliquePhoto: '',
            stickOutChin: '',
            nowTakeFromSide: '',
            turnBody: '',
            stayLookingUp: '',
            stayLookingDown: '',
            takeAphoto: '',
            giveAsignal: '',
            breatheInThenOut: '',
            relaxYourBreath:'',
        },
        lyingPosition: {
            wholeBack: '',
            thoracicSpine: '',
            lumbarVertebra: '',
            scaralVertebra: '',
            pictureOfLying: '',
            lieFaceUp: '',
            layDownOnYourSide:'',
            putHandsOnTheSide: '',
            touchYourBody: '',
            ifFeelPain: '',
            lookUp: '',
            lookDown: '',
            lookRight: '',
            lookLeft: '',
            holdingKnees:'',
            bendBackward: '',
            notForceYouSelf: '',
            takeAphoto: '',
            giveAsignal: '',
            breatheInThenOut: '',
            relaxYourBreath: '',
        },
        pelvis: {
            photoOfPelvis: '',
            putYourBackOnBoard: '',
            moveBoard: '',
            pigeontoed: '',
            outYourToes: '',
            pictureOfLying: '',
            lieFaceUp: '',
            putHandsOnTheSide: '',
            wrapAround: '',
            touchYourBody: '',
            ifFeelPain: '',
            pigeontoed2: '',
            outYourToes2: '',
            obliquePhoto: '',
            kneesUpAndFaceOverHere: '',
            kneesUpAndFaceOverThere: '',
            separateKnees:'',
            takeAphoto: '',
            giveAsignal: '',
            breatheInThenOut: '',
        },
        shoulder: {
            photoOfShoulder: '',
            sitDown: '',
            photoOfStanding: '',
            touchBodyTellIfHurts: '',
            putRightShoulder: '',
            putLeftShoulder: '',
            bendElbow: '',
            turnTowardsBoard: '',
            takeAphoto:'',
        },
        clavicle: {
            photoOfClavicle: '',
            sitDown: '',
            photoOfStanding: '',
            touchBodyTellIfHurts: '',
            putYourBackAndStandUp: '',
            machineWillMove: '',
            takeAphoto:'',
        },
        upperArm: {
            photoOfUpperArm: '',
            sitDown: '',
            photoOfStanding: '',
            touchBodyTellIfHurts: '',
            putYourBackAndStandUp: '',
            extendArmsAndTurn: '',
            bendElbowAndPutThumbs: '',
            takeAphoto:'',
        },
        elbow: {
            photoOfUpperForearm: '',
            sitDown: '',
            photoOfStanding: '',
            touchBodyTellIfHurts: '',
            extendArmsAndPutHand: '',
            bendElbowAndTurn: '',
            takeAphoto:'',
        },
        hand: {
            photoOfHand: '',
            sitDown: '',
            photoOfStanding: '',
            touchBodyTellIfHurts: '',
            lightFist: '',
            handChopping: '',
            moveOutwards: '',
            bothHandsOnBoard: '',
            takeAphoto:'',
        },
        lowerLimbs: {
            photoWholeLeg:'',
            photoOfLowerLimbs: '',
            wrapAround:'',
            standingUpOnTheStand:'',
            feetInwards: '',
            takeAphoto: '',
        },
        backThigh: {
            photoOfThighs: '',
            standingUpOnBed: '',
            boardUnderBottom: '',
            faceSideways: '',
            takeAphoto:'',
        },
        knees: {
            photoOfKnees: '',
            drawKnees: '',
            machineComeClose: '',
            extendKnees: '',
            turnFeet: '',
            sidewaysThere: '',
            sidewaysHere: '',
            putOutOppositeKnee: '',
            moveKnee: '',
            takeAphoto: '',
        },
        foot: {
            standingUpOnBed: '',
            holdMachine: '',
            standWillMove: '',
            feetOnBoard: '',
            turnFeet: '',
            turnFeetInards: '',
            turnFeetOutward: '',
            weightOnMachine: '',
            notMoveAndStay: '',
            photoOfOpposite: '',
            photoOfSitting: '',
            feetPutOnBoard: '',
            tiltKneesInward: '',
            tiltKneesOutward: '',
            takeAphoto:'',
        },
        //fiinish
        finish: '',
        compleatedMark: '',
        nextTest: '',
        outpatientsDepartment: '',
        cashier: '',
        completed: '',
        //leaving
        thatsAll: '',
        inspectionEnded: '',
        stopByReception: '',
        handThisIn:'',
    });


    return (
        <XrayContext.Provider value={[values, setValues]}>
            {props.children}
        </XrayContext.Provider>
    );
};