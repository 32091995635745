import React, {useState, createContext} from 'react';

export const LanguagesContext = createContext();

export const LangContext = props => {
    const [values, setValues] = useState({
        language : ""
        
    });
   

    return(
        <LanguagesContext.Provider value={[values, setValues]}>
            {props.children}
        </LanguagesContext.Provider>
    );
};