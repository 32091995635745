import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { PediatricsContext } from '../context/pediatricsContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,

        textAlign: 'center',
        margin: 10,
        padding: 3
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const problemtodays = [
        { name: t('Pediatric.Fever'), text: '/発熱', value: "Fever" },
        { name: t('Pediatric.Pain'), text: '/痛み', value: "Pain" },
        { name: t('Pediatric.Cough'), text: '/咳', value: "Cough" },
        { name: t('Pediatric.Runnynose'), text: '/鼻水', value: "Runnynose" },
        { name: t('Pediatric.Nasalcongestion'), text: '/鼻づまり', value: "Nasalcongestion" },
        { name: t('Pediatric.Vomiting'), text: '/嘔吐', value: "Vomiting" },
        { name: t('Pediatric.Diarrhea'), text: '/下痢', value: "Diarrhea" },
        { name: t('Pediatric.Nausea'), text: '/吐き気', value: "Nausea" },
        { name: t('Pediatric.Bloodystool'), text: '/血便', value: "Bloodystool" },
        { name: t('Pediatric.Hives'), text: '/じんましん', value: "Hives" },
        { name: t('Pediatric.Eczema'), text: '/湿疹', value: "Eczema" },
        { name: t('Pediatric.Birthmarkbruise'), text: '/あざ', value: "Birthmarkbruise" },
        { name: t('Pediatric.Boil'), text: ' /できもの', value: "Boil" },
        { name: t('Pediatric.Rash'), text: '/発疹', value: "Rash" },
        { name: t('Pediatric.Dryskin'), text: '/皮膚の乾燥', value: "Dryskin" },
        { name: t('Pediatric.Atopicsymptoms'), text: ' /アトピー', value: "Atopicsymptoms" },
        { name: t('Pediatric.Lossofconsciousness'), text: ' /意識がなくなる', value: "Lossofconsciousness" },
        { name: t('Pediatric.Badmood'), text: '/機嫌がわるい', value: "Badmood" },
        { name: t('Pediatric.Lossappetite'), text: '/食欲がない', value: "Lossappetite" },
        { name: t('Pediatric.Doesnotdrinkmilk'), text: '/ミルクを飲まない', value: "Doesnotdrinkmilk" },
        { name: t('Pediatric.Convulsion'), text: '/けいれん', value: "Convulsion" },
        { name: t('Pediatric.Earache'), text: '/耳痛', value: "Earache" },
        { name: t('Pediatric.Consultationonchilddevelopment'), text: '/ 発達相談', value: "Consultationonchilddevelopment" },
        { name: t('Pediatric.Consultationonchildcare'), text: '/育児相談       ', value: "Consultationonchildcare" },
        { name: t('Pediatric.Allergyconsultation'), text: '/アレルギーの相談', value: "Allergyconsultation" },
        { name: t('Pediatric.Vaccination'), text: '/ワクチン接種', value: "Vaccination" },
        { name: t('Pediatric.Other(s)'), text: '/その他：', value: "Other(s)" },
        { name: t('Pediatric.advisedanotherclinic'), text: '/他の医療機関から受診するように勧められた（健診含む）', value: "advisedanotherclinic" }

    ]

    const anotherClinic = [
        { name: t('Pediatric.Abnormalityinelectrocardiogram'), text: '/ 心電図異常', value: "Abnormalityinelectrocardiogram" },
        { name: t('Pediatric.Shortheight'), text: '/ 低身長', value: "Shortheight" },
        { name: t('Pediatric.Poorweightgain'), text: '/体重増加不良', value: "Poorweightgain" },
        { name: t('Pediatric.AbnormalityinUrinalysis'), text: '/尿検査異常', value: "AbnormalityinUrinalysis" },
        { name: t('Pediatric.Slowlanguagedevelopment'), text: '/言葉の遅れ', value: "Slowlanguagedevelopment" }
    ]

    const [values, setValues] = useContext(PediatricsContext);
    const classes = useStyles();
    const [checkedProblemtoday, setCheckedProblemtoday] = React.useState([]);
    const [checkedanotherClinic, setcheckedanotherClinic] = React.useState([]);
    const [frequency, setFrequency] = React.useState('');

    const handleToggleProblem = (val) => () => {
        const currentIndex = values.problemtoday.indexOf(val.value);
        const newChecked = [...values.problemtoday];

        if (currentIndex === -1) {
            if (val.value === "advisedanotherclinic") {
                newChecked.push(val.value);
                setValues({ ...values, problemtoday: newChecked, anotherClinicWindow: "1" })
            } else {
                newChecked.push(val.value);
                setValues({ ...values, problemtoday: newChecked });
            }
        } else {
            if (val.value === "advisedanotherclinic") {
                newChecked.splice(currentIndex, 1);
                setValues({ ...values, problemtoday: newChecked, anotherClinicWindow: "0" })
            } else {
                newChecked.splice(currentIndex, 1);
                setValues({ ...values, problemtoday: newChecked });
            }
        }

        setCheckedProblemtoday(newChecked);
        //setValues({...values, problemtoday:newChecked});

    };

    const handleToggleAnotherClinic = (object) => () => {
        const currentIndex = values.anotherClinic.indexOf(object.value);
        const newChecked = [...values.anotherClinic];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setcheckedanotherClinic(newChecked);
        setValues({ ...values, anotherClinic: newChecked });

    };



    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.problemtoday')}<br /><i style={{ color: "blue" }}>/今日はどのような症状がありますか。（複数ある方は複数☑してください.)</i></h4></strong></Paper>
                    </Grid>

                    <Grid style={{ width: "inherit", flex: 1 }}>
                        <GridList style={{ width: "inherit", height: 339, paddingLeft: 20, paddingRight: 20 }} cellHeight={10} className={classes.gridList} cols={4}>
                            {problemtodays.map((value) => {
                                const labelId = `checkbox-list-label-${value.name}`;

                                return (
                                    <ListItem key={value.name} role={undefined} dense button onClick={handleToggleProblem(value)}>
                                        <ListItemIcon >
                                            <Checkbox
                                                edge="start"
                                                checked={values.problemtoday.indexOf(value.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} >
                                            {value.name}
                                            <span style={{ color: "blue" }}>{value.text}</span>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </GridList>


                    </Grid>

                    <Grid>
                        <div style={{ width: "inherit", flex: 1 }}>
                            {values.anotherClinicWindow === '1' ? (
                                <Grid style={{ width: "inherite", backgroundColor: "#ffff0047" }}>
                                    <FormLabel style={{ lineHeight: 2, fontSize: 18 }}><strong>{t('Pediatric.ifAnotherClinic')} <br /><i style={{ color: "#0000ffbf" }}>/「他の医療機関から受診するように勧められた（健診含む）」に☑された方は、当てはまるものを☑してください。</i></strong></FormLabel>
                                    <GridList style={{ height: 83, paddingLeft: 20, paddingRight: 20, width: "inherit" }} cellHeight={10} className={classes.gridList} cols={3}>
                                        {anotherClinic.map((value) => {
                                            const labelId = `checkbox-list-label-${value.name}`;

                                            return (
                                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleAnotherClinic(value)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={values.anotherClinic.indexOf(value.value) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} >
                                                        {value.name}
                                                        <span style={{ color: "blue" }}>{value.text}</span>
                                                    </ListItemText>

                                                </ListItem>
                                            );
                                        })}
                                    </GridList>
                                </Grid>
                            ) : (
                                    <nav> &apos; </nav>
                                )}
                        </div>
                    </Grid>

                </Grid>

            </div>
        </div>
    )
}
export default CenteredGrid;