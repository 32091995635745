import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { GastrologyContext } from '../context/gastrologyContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,
       
        textAlign: 'center',
        margin:10,
        padding:3
      },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const problem = [
        { name: t('gastroenterology.Nausea'), text:' /吐き気',  value: 'Nausea' },
        { name: t('gastroenterology.Vomiting'), text:' /嘔吐', value: 'Vomiting' },
        { name: t('gastroenterology.Diarrhea'), text:'/下痢', value: 'Diarrhea' },
        { name: t('gastroenterology.Burping'), text:'/げっぷ', value: 'Burping' },
        { name: t('gastroenterology.Constipation'), text:'/便秘', value: 'Constipation' },
        { name: t('gastroenterology.Heartburn'), text:'/胸やけ', value: 'Heartburn' },
        { name: t('gastroenterology.Bloodystool'), text:'/血便', value: 'Bloody stool' },
        { name: t('gastroenterology.Vomitingblood'), text:'/血を吐く', value: 'Vomiting blood' },
        { name: t('gastroenterology.Appetiteloss'), text:'/食欲減退', value: 'Appetite loss' },
        { name: t('gastroenterology.Weightloss'), text:'/体重減少', value: 'Weight loss' },
        { name: t('gastroenterology.Foodstuckinthroat'), text:'/食事がのどにつかえる', value: 'Food stuck in throat' },
        { name: t('gastroenterology.Other'), text:'/その他', value: 'Other(s)' },
        { name: t('gastroenterology.advisedbyanotherclinic'), text:'/他の医療機関から受診するように勧められた(健診含む)', value: 'I was advised by another clinic/hospital (or at a regular check-up) to come here.' },
    ]

    const stools = [
        {name: t('internalMedcine.Grayishwhite'), text:'/ 灰白色', value:"Grayishwhite"},
        {name: t('internalMedcine.Brown'), text:'/ 茶色', value:"Brown"},
        {name: t('internalMedcine.Black'), text:'/黒色', value:"Black"},
        {name: t('internalMedcine.Bloody'), text:'/血便', value:"Bloody"},
        {name: t('internalMedcine.Watery'), text:'/水様', value:"Watery"},
        {name: t('internalMedcine.Soft'), text:'/軟便', value:"Soft"},
        {name: t('internalMedcine.Hard'), text:'/硬い便', value:"Hard"},
       ]

    const [values, setValues] = useContext(GastrologyContext);
    const classes = useStyles();
    const [checkedProblemtoday, setCheckedProblemtoday] = React.useState([]);
    const [checkedStool, setCheckedStool] = React.useState([]);
    const [frequency, setFrequency] = React.useState('');

    const handleToggleProblem = (value) => () => {
        const currentIndex = values.problemtoday.indexOf(value.value);
        const newChecked = [...values.problemtoday];

        if (currentIndex === -1) {
            newChecked.push(value.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedProblemtoday(newChecked);
        setValues({...values, problemtoday:newChecked});
        
    };

    const handleToggleStool = (object) => () => {
        const currentIndex = values.stools.indexOf(object.value);
        const newChecked = [...values.stools];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedStool(newChecked);
        setValues({...values, stools:newChecked});
       
    };

    const updateFrequency = (event) => {
        setFrequency(event.target.value);               
        setValues({...values, stoolfrequency:event.target.value});
        
    };

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.problemtoday')}<br/><i style={{color:"blue"}}>/今日はどのような症状がありますか。（複数ある方は複数☑してください.)</i></h4></strong></Paper>
                    </Grid>
                    
                    <Grid  style={{ width:"inherit", flex:1 }}>
                    <GridList  style={{  width:"inherit", height: 200, paddingLeft: 20,paddingRight: 20}} cellHeight={10} className={classes.gridList} cols={3}>
                        {problem.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem  key={value.name} role={undefined} dense button onClick={handleToggleProblem(value)}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            checked={values.problemtoday.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </GridList>

                    
                        </Grid>

                        <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>Check all that apply about your stool. <br/><i style={{color:"blue"}}>/ 便の性状に☑してください</i></h4></strong></Paper>
                    </Grid>
                        
                    <GridList container style={{height:107, padding: 20, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {stools.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleStool(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.stools.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                    </GridList>
                    <Grid item xs={6}>
                    <FormControl fullWidth xs={8} className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t('internalMedcine.Stoolfrequency')}<i style={{color:"#0000ffbf"}}>/ 一日の排便回数：</i></InputLabel>
                        <Select 
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.stoolfrequency}
                            onChange={updateFrequency}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>
                    </Grid>

            </div>
        </div>
    )
}
export default CenteredGrid;