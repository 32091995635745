import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from '../ctContext '
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
    
    
}));
function Ct4() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(CtContext);

    const [haveBreakfast, setHaveBreakfast] = React.useState('');
    const [everCT, setEverCT] = React.useState('');
    const [didnotFeelBetter, setDidnotFeelBetter] = React.useState('');
    const [anyAllergy, setAnyAllergy] = React.useState('');
    const [anyMedAllergy, setAnyMedAllergy] = React.useState('');
    const [metfirmin, setMetfirmin] = React.useState('');
    const [pregnantorBreastfeeding, setPregnantorBreastfeeding] = React.useState('');
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct4 - Medical Questionnaire",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

    const handleChangeHaveBreakfast = event => {
        setHaveBreakfast(event.target.value);
        setValues({ ...values, haveBreakfast: event.target.value })
    };
    const handleChangeEverCT = event => {
        setEverCT(event.target.value);
        setValues({ ...values, everCT: event.target.value })
    };
    const handleChangeDidnotFeelBetter = event => {
        setDidnotFeelBetter(event.target.value);
        setValues({ ...values, didnotFeelBetter: event.target.value })
    };
    const handleChangeAnyAllergy = event => {
        setAnyAllergy(event.target.value);
        setValues({ ...values, anyAllergy: event.target.value })
    };
    const handleChangeAnyMedAllergy = event => {
        setAnyMedAllergy(event.target.value);
        setValues({ ...values, anyMedAllergy: event.target.value })
    };
    const handleChangeMetfirmin = event => {
        setMetfirmin(event.target.value);
        setValues({ ...values, metfirmin: event.target.value })
    };
    const handleChangePregnantorBreastfeeding = event => {
        setPregnantorBreastfeeding(event.target.value);
        setValues({ ...values, pregnantorBreastfeeding: event.target.value })
    };
    

    const symptoms = [
        { name: t('test.CTquestion.nausea') + "/吐き気", value: "nausea" },
        { name: t('test.CTquestion.stuffiness') + "/息苦しさ", value: "stuffiness" },
        { name: t('test.CTquestion.rash') + "/発疹", value: "rash" },
        { name: t('test.CTquestion.itching') + "/かゆみ", value: "itching" },
        { name: t('test.CTquestion.cough') + "/咳", value: "cough" },
        { name: t('test.CTquestion.sneeze') + "/くしゃみ", value: "sneeze" },
        { name: t('test.others') + "/その他", value: "others" },

    ]
    const [checkedSymptoms, setCheckedSymptoms] = React.useState([]);
    const handleToggleSymptoms = (object) => () => {
        const currentIndex = values.symptoms.indexOf(object.value);
        const newChecked = [...values.symptoms];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedSymptoms(newChecked);
        setValues({ ...values, symptoms: newChecked })
    };
    const allergies = [
        
        { name: t('test.Shrimp') + "/甲殻類（エビ、カニ類）", value: "Shrimp" },
        { name: t('test.fish') + "/青魚", value: "fish" },
        { name: t('test.fishroe') + "/魚卵", value: "fishroe" },
        { name: t('test.shellfish') + "/貝類", value: "shellfish" },
        { name: t('test.egg') + "/卵", value: "egg" },
        { name: t('test.milk') + "/牛乳", value: "milk" },
        { name: t('test.cheese') + "/チーズ", value: "cheese" },
        { name: t('test.buckwheat') + "/そば", value: "buckwheat" },
        { name: t('test.peanuts') + "/ピーナッツ", value: "peanuts" },
        { name: t('test.almonds') + "/アーモンド", value: "almonds" },
        { name: t('test.wheat') + "/小麦", value: "wheat" },
        { name: t('test.soy') + "/大豆", value: "soy" },
        { name: t('test.kiwifruit') + "/キウイ", value: "kiwifruit" },
        { name: t('test.peaches') + "/桃", value: "peaches" },
        { name: t('test.apples') + "/りんご", value: "apples" },
        { name: t('test.avocados') + "/アボガド", value: "avocados" },
        { name: t('test.yams') + "/山芋", value: "yams" },       
    ];
    const [checkedAllergies, setCheckedAllergies] = React.useState([]);
    const handleToggleAllergies = (object) => () => {
        const currentIndex = values.allergies.indexOf(object.value);
        const newChecked = [...values.allergies];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAllergies(newChecked);
        setValues({ ...values, allergies: newChecked })
    };

    const medAllergies = [
        { name: t('test.penicillin') + "/抗菌剤・抗生物質", value: "penicillin" },
        { name: t('test.antiviral') + "/抗ウイルス薬", value: "antiviral" },
        { name: t('test.painkillers') + "/鎮痛剤・抗炎症薬", value: "painkillers" },
        { name: t('test.OTC') + "/市販の風邪薬", value: "OTC" },
        { name: t('test.others') + "/その他", value: "others" },
    ];
    const [checkedMedAllergies, setCheckedMedAllergies] = React.useState([]);
    const handleToggleMedAllergies = (object) => () => {
        const currentIndex = values.medAllergies.indexOf(object.value);
        const newChecked = [...values.medAllergies];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedMedAllergies(newChecked);
        setValues({ ...values, medAllergies: newChecked })
    };
    const illnesses = [
        { name: t('test.CTquestion.thyroid') + "/甲状腺疾患", value: "thyroid" },
        { name: t('test.CTquestion.kidney') + "/重篤な腎疾患", value: "kidneyDisease" },
        { name: t('test.CTquestion.liver') + "/重篤な肝疾患", value: "liverDisease" },
        { name: t('test.CTquestion.bronchialasthma') + "/気管支喘息", value: "bronchialAsthma" },
    ];
    const [checkedIllnesses, setCheckedIllnesses] = React.useState([]);
    const handleToggleIllnesses = (object) => () => {
        const currentIndex = values.illnesses.indexOf(object.value);
        const newChecked = [...values.illnesses];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedIllnesses(newChecked);
        setValues({ ...values, illnesses: newChecked })
    };

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            問診({t('test.title.question')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.keepSlip}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>受付票と会計ファイルをお預かりいたします<br />{t('test.CTquestion.keep slip')}</h5> </strong>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                        value={values.takeSlipQuestion}
                        control={<Checkbox />}
                        labelPlacement="start"
                        label={<strong><h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5> </strong>} />
 
                    </Paper>
                </Grid>

            <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>今朝、朝食は食べましたか？<br />{t('test.CTquestion.have breakfast')}</h5></strong></Paper>
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">

                        <RadioGroup row aria-label="haveBreakfast" name="haveBreakfast" value={values.haveBreakfast} onChange={handleChangeHaveBreakfast}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                    </RadioGroup>
                </FormControl>
            </Grid>    

        <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>以前に「造影検査」を受けたことはありますか？<br />{t('test.CTquestion.have you ever CT')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                <RadioGroup row aria-label="everCT" name="everCT" value={values.everCT} onChange={handleChangeEverCT}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                </RadioGroup>
            </FormControl>
        </Grid>    
        <Grid item xs={12}>
            <div>
                {values.everCT === 'yes' ? (
                            <div>
                            <Grid item xs={12}>
                                    <Paper className={classes.paper}><strong><h5>その時、気分が悪くなりましたか？<br />{t('test.CTquestion.didnot you feel better')}</h5></strong></Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">

                                    <RadioGroup row aria-label="didnotFeelBetter" name="didnotFeelBetter" value={values.didnotFeelBetter} onChange={handleChangeDidnotFeelBetter}>
                                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>    
                                </div>
                    
                ) : (
                        <nav> &apos; </nav>
                    )}
            </div>
                </Grid>
                <div>
                    {values.didnotFeelBetter === 'yes' ? (
                        <div className={classes.root}>
                            <Grid container spacing={3} style={{ padding: 20 }}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}><strong><h4>どのような症状ですか？（主な症状を４つまで）<br />{t('test.CTquestion.whats symptoms')}</h4></strong></Paper>
                                </Grid>

                                <Grid container spacing={3} style={{ padding: 20 }}>
                                    <GridList container style={{ height: 90, padding: 20 }} cellHeight={20} className={classes.gridList} cols={4}>
                                        {symptoms.map((value) => {
                                            const labelId = `checkbox-list-label-${value.name}`;

                                            return (
                                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleSymptoms(value)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={values.symptoms.indexOf(value.value) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`${value.name}`} />

                                                </ListItem>
                                            );
                                        })}
                                    </GridList>

                                </Grid>
                            </Grid>

                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
        
        
        <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>アレルギーはありますか？<br />{t('test.CTquestion.any allergy')}</h5></strong></Paper>
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">

                        <RadioGroup row aria-label="anyAllergy" name="anyAllergy" value={values.anyAllergy} onChange={handleChangeAnyAllergy}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                </RadioGroup>
            </FormControl>
            </Grid> 
            <div>
                    {values.anyAllergy === 'yes' ? (
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>どのようなものに対してアレルギーがありますか？<br />{t('test.CTquestion.what allergic')}</h4></strong></Paper>
                    </Grid>

                    <Grid container spacing={3} style={{ padding: 20 }}>
                        <GridList container style={{ height: 420, padding: 20 }} cellHeight={20} className={classes.gridList} cols={2}>
                            {allergies.map((value) => {
                                const labelId = `checkbox-list-label-${value.name}`;

                                return (
                                    <ListItem key={value.name} role={undefined} dense button onClick={handleToggleAllergies(value)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={values.allergies.indexOf(value.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={`${value.name}`} />

                                    </ListItem>
                                );
                            })}
                        </GridList>
          
                    </Grid>
                </Grid>

            </div>
                ) : (
                        <nav> &apos; </nav>
                    )}
            </div>
        
                <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>以前に「薬」でアレルギーが出たことはありますか？<br />{t('test.CTquestion.any medicines allergies')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="anyMedAllergy" name="anyMedAllergy" value={values.anyMedAllergy} onChange={handleChangeAnyMedAllergy}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <div>
                    {values.anyMedAllergy === 'yes' ? (
                        <div className={classes.root}>
                            <Grid container spacing={3} style={{ padding: 20 }}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}><strong><h4>「はい」と答えられた方は具体的なお薬の名前を聞かせてください。<br />{t('test.CTquestion.tell us name of the medication')}</h4></strong></Paper>
                                </Grid>

                                <Grid container spacing={3} style={{ padding: 20 }}>
                                    <GridList container style={{ height: 120, padding: 20 }} cellHeight={20} className={classes.gridList} cols={2}>
                                        {medAllergies.map((value) => {
                                            const labelId = `checkbox-list-label-${value.name}`;

                                            return (
                                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleMedAllergies(value)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={values.medAllergies.indexOf(value.value) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`${value.name}`} />

                                                </ListItem>
                                            );
                                        })}
                                    </GridList>

                                </Grid>
                            </Grid>

                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>

                <div className={classes.root}>
                    <Grid container spacing={3} style={{ padding: 20 }}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}><strong><h4>以下の病気があるといわれたことはありますか？<br />{t('test.CTquestion.experienced illnesses')}</h4></strong></Paper>
                        </Grid>

                        <Grid container spacing={3} style={{ padding: 20 }}>
                            <GridList container style={{ height: 90, padding: 20,  }} cellHeight={20} className={classes.gridList} cols={2}>
                                {illnesses.map((value) => {
                                    const labelId = `checkbox-list-label-${value.name}`;

                                    return (
                                        <ListItem key={value.name} role={undefined} dense button onClick={handleToggleIllnesses(value)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={values.illnesses.indexOf(value.value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${value.name}`} />

                                        </ListItem>
                                    );
                                })}
                            </GridList>

                        </Grid>
                    </Grid>

                </div>

                <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>糖尿病薬メトホルミン(メルビン・グリコラン・メデット等)を服用していますか？<br />{t('test.CTquestion.Metfirmin')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="metfirmin" name="metfirmin" value={values.metfirmin} onChange={handleChangeMetfirmin}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid> 
                <Grid item xs={11}>
                <div>
                    {values.metfirmin === 'yes' ? (
                      
                          <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <FormControlLabel
                                    value={values.nameOfDiabetesMed}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<h5>「はい」と答えられた方は具体的なお薬の名前を聞かせてください。<br />{t('test.CTquestion.tell us name of the medication')}</h5>} />

                            </Paper>
                          </Grid>
                            
                    ) : (
                            <nav> &apos; </nav>
                        )}
                    </div>
                    </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}><strong><h5>現在、妊娠中または授乳中ですか？<br />{t('test.CTquestion.pregnant or breast-feeding')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="pregnantorBreastfeeding" name="pregnantorBreastfeeding" value={values.pregnantorBreastfeeding} onChange={handleChangePregnantorBreastfeeding}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid> 

            </Grid>
            <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >CT HOME</Button></Link>
                <Link to="ct5" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                </div>
            <Footer />

        </div>
                
    );
}
 
export default Ct4;