import React, { useState, useContext } from 'react'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { PediatricsContext } from '../context/pediatricsContext'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 1000,
        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    sidecheck: {
        paddingTop: 11,
        marginLeft: -13,
        marginRight: 5,
        color: "blue"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
        padding: 6
    },
    label: {
        width: "100%",
        textAlign: "center",
        lineHeight: 1.5
    }
}));

const Form5 = () => {
    const { t, i18n } = useTranslation();


    const [values, setValues] = useContext(PediatricsContext);
    const [fields, setFields] = useState([{
        date: new Date(),
        HospitalName: "",
        prescription: false,
        diseaseName: "",
        treatmentProgress: ""
    }]);
    const classes = useStyles();


    function handleAdd() {
        const values1 = [...fields];
        values1.push({
            date: new Date(),
            HospitalName: "",
            prescription: false,
            diseaseName: "",
            treatmentProgress: ""
        });
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 });

    }

    function handleRemove(i) {
        const values1 = [...fields];
        values1.splice(i, 1);
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 })
    }

    function handleChangeDate(i, date) {
        const values1 = [...fields];
        values1[i].date = date;
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 });
        // logFunction(t('obstetricGynecology.YearMonthDay'));
    }

    function handleChangeHospitalName(i, event) {
        const values1 = [...fields];
        values1[i].HospitalName = event.target.value;
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 })
        //    logFunction(t('obstetricGynecology.weekpregnancy'));
    }

    function handleChangeDiseaseName(i, event) {
        const values1 = [...fields];
        values1[i].diseaseName = event.target.value;
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 })
        //    logFunction(t('obstetricGynecology.weekpregnancy'));
    }

    function handleChangeRadio(i, event) {
        const values1 = [...fields];
        values1[i].prescription = event.target.value;
        setFields(values1);
        setValues({ ...values, YeshaveBeentreatedAtOtherhospital: values1 });
        // logFunction(t('obstetricGynecology.Delivery'));
    }

    const haveBeentreatedAtOtherhospital = (event) => {
        if (event.target.value === "true") {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
                YeshaveBeentreatedAtOtherhospital: [{
                    date: new Date(),
                    HospitalName: "",
                    prescription: false
                }]
            })
        } else {
            setValues({ ...values, [event.target.name]: event.target.value });
        }

    };

    const update = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    }

    const updateDrpdown = (i, event) => {
        const values1 = [...fields];
        values1[i].treatmentProgress = event.target.value;
        setFields(values1);
        setValues({...values, [event.target.id]:event.target.value});
       }

console.log(values.YeshaveBeentreatedAtOtherhospital)
    return (
        <div className={classes.root}>
            <Grid container spacing={3} style={{ padding: 20, flex: 1, }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h4>{t('Pediatric.Birth')}<br /><i style={{ color: "#0000ffbf" }}>/出生時</i></h4></strong></Paper>
                </Grid>

                <Grid className={classes.labelAligne} item xs={3}>
                    <h5>{t('Pediatric.WeeksPregnancyChildbirth')}<i style={{ color: "#0000ffbf" }}> /出生時週数</i></h5>
                </Grid>
                <Grid className={classes.labelAligne} style={{ marginTop: 12, marginLeft: -66 }} item xs={1}>
                    <h5>:</h5>
                </Grid>
                <Grid container item xs={2} style={{ marginLeft: -27, marginRight: -46 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="weeks">{t('Pediatric.weeks')} <i style={{ color: "#0000ffbf" }}>/週</i></InputLabel>
                        <Input
                            id="weeks"
                            type="number"
                            values={values.WeeksPregnancyChildbirth}
                            defaultValue={values.WeeksPregnancyChildbirth}
                            onChange={update}
                            name="WeeksPregnancyChildbirth"
                        />
                    </FormControl>

                </Grid>
                <Grid container item xs={2} style={{ marginRight: 41, marginLeft: -27 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="DaysPregnancyChildbirth">{t('Pediatric.days')} <i style={{ color: "#0000ffbf" }}>/日</i></InputLabel>
                        <Input
                            id="DaysPregnancyChildbirth"
                            type="number"
                            values={values.DaysPregnancyChildbirth}
                            defaultValue={values.DaysPregnancyChildbirth}
                            onChange={update}
                            name="DaysPregnancyChildbirth"
                        />
                    </FormControl>

                </Grid>

                <Grid className={classes.labelAligne} style={{ marginTop: 17, width: "50%" }} item xs={2}>
                    <h5>{t('Pediatric.Weight')}<i style={{ color: "#0000ffbf" }}> /体重</i>  :</h5>
                </Grid>
                <Grid container item xs={2} style={{ marginRight: 41, marginLeft: -27 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="Weight">(g)</InputLabel>
                        <Input
                            id="Weight"
                            type="number"
                            values={values.Weight}
                            defaultValue={values.Weight}
                            onChange={update}
                            name="Weight"
                        />
                    </FormControl>

                </Grid>

                <Grid className={classes.labelAligne} style={{ marginTop: 17, width: "50%" }} item xs={2}>
                    <h5>{t('Pediatric.Height')}<i style={{ color: "#0000ffbf" }}> /身長</i>  :</h5>
                </Grid>
                <Grid container item xs={2} style={{ marginLeft: -27, marginRight: -70 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="Height">(cm)</InputLabel>
                        <Input
                            id="Height"
                            type="number"
                            values={values.Height}
                            defaultValue={values.Height}
                            onChange={update}
                            name="Height"
                        />
                    </FormControl>

                </Grid>

                <Grid className={classes.labelAligne} style={{ marginTop: 17, width: "50%" }} item xs={3}>
                    <h5>{t('Pediatric.HeadCircumference')}<i style={{ color: "#0000ffbf" }}> /頭囲</i>  :</h5>
                </Grid>
                <Grid container item xs={2} style={{ marginLeft: -20, marginRight: -70 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="HeadCircumference">(cm)</InputLabel>
                        <Input
                            id="HeadCircumference"
                            type="number"
                            values={values.HeadCircumference}
                            defaultValue={values.HeadCircumference}
                            onChange={update}
                            name="HeadCircumference"
                        />
                    </FormControl>

                </Grid>

                <Grid className={classes.labelAligne} style={{ marginTop: 17, width: "50%" }} item xs={3}>
                    <h5>{t('Pediatric.ChestCircumference')}<i style={{ color: "#0000ffbf" }}> /胸囲</i>  :</h5>
                </Grid>
                <Grid container item xs={2} style={{ marginRight: -20, marginLeft: -27 }}>
                    <FormControl fullWidth className='' style={{ marginTop: -11, width: "50%" }}>
                        <InputLabel htmlFor="ChestCircumference">(cm)</InputLabel>
                        <Input
                            id="ChestCircumference"
                            type="number"
                            values={values.ChestCircumference}
                            defaultValue={values.ChestCircumference}
                            onChange={update}
                            name="ChestCircumference"
                        />
                    </FormControl>

                </Grid>

                {/* Have you been treated at any other hospital before this hospital? */}
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h4>{t('Pediatric.haveBeentreatedAtOtherhospital')}<br /><i style={{ color: "#0000ffbf" }}>/当院に来るまでに、ほかの病院にかかられましたか。</i></h4></strong></Paper>
                </Grid>
                <Grid style={{ padding: 0 }} item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="gender" name="haveBeentreatedAtOtherhospital" value={values.haveBeentreatedAtOtherhospital} onChange={haveBeentreatedAtOtherhospital}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" /><i className={classes.sidecheck}>/はい</i>
                            <FormControlLabel value="false" control={<Radio />} label="No" /><i className={classes.sidecheck}>/いいえ</i>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <div style={{ marginLeft: 55 }}>
                    {values.haveBeentreatedAtOtherhospital === "true" ? (
                        <div style={{marginLeft: -17, marginRight: 26}}>
                            <FormLabel style={{ marginTop: -15, marginLeft: 65, fontSize: 14 }}><strong style={{ lineHeight: 2 }}>{t('Pediatric.IfYeswriteTheNameOfHospital')} <i style={{ color: "#0000ffbf" }}>/「はい」に☑した人は、治療していた医療機関名を書いてください。</i></strong></FormLabel>
                            <Grid item xs={12} style={{ backgroundColor: "#f2888747" }}>

                                {values.YeshaveBeentreatedAtOtherhospital.map((field, idx) => {
                                    return (
                                        <div key={`${field}-${idx}`}>
                                            <hr style={{ height: 2, borderWidth: 0, color: "gray", backgroundColor: "gray" }} />
                                            <FormLabel style={{  marginLeft: 60, fontSize: 20, color: "black" }}><strong style={{ lineHeight: 1 }}>{"Hospital: " + `${idx + 1}`} </strong></FormLabel>
                                            <hr style={{ height: 2, borderWidth: 0, color: "gray", backgroundColor: "gray", marginTop: -6 }} />
                                            <Grid container spacing={3} style={{ paddingLeft: 20, paddingRight: 20 }}>

                                                <Grid item xs={3}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Grid container justify="space-around">

                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                margin="normal"
                                                                name="date"
                                                                label={t('Pediatric.DateOfConsoltation')}
                                                                format="yyyy/MM/dd"
                                                                value={field.date}
                                                                onChange={e => handleChangeDate(idx, e)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />

                                                        </Grid>

                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} style={{ marginTop: 15 }}>
                                                    <FormControl fullWidth className='' >
                                                        <InputLabel id="HospitalName" htmlFor="HospitalName">{t('Pediatric.HospitalName')}<i style={{ color: "#0000ffbf" }}> /医療機関名</i></InputLabel>
                                                        <Input
                                                            id="HospitalName"
                                                            type="text"
                                                            name="HospitalName"
                                                            id={field.HospitalName}
                                                            className="age"
                                                            value={field.HospitalName}
                                                            onChange={e => handleChangeHospitalName(idx, e)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={2} style={{ marginTop: 22 }}>
                                                    <strong><h5 style={{ fontSize: 19 }}>{t('Pediatric.Prescription')} <i style={{ color: "#0000ffbf" }}>/ 処方の有無</i></h5></strong>
                                                </Grid>
                                                <Grid style={{ textAlign: "left", marginLeft: 5, marginTop: -15 }} item xs={2}>
                                                    <FormControl component="fieldset">

                                                        <RadioGroup row aria-label="gender" name="delivery" value={field.prescription} onChange={e => handleChangeRadio(idx, e)}>
                                                            <FormControlLabel value="true" control={<Radio />} label={t('internalMedcine.true')} /><Grid className="navCheckbox"> <span style={{ color: "blue" }}> /はい</span></Grid>
                                                            <FormControlLabel value="false" control={<Radio />} label={t('internalMedcine.false')} /><Grid className="navCheckbox"> <span style={{ color: "blue" }}> /いいえ </span></Grid>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>




                                                <Grid item xs={1} style={{ marginTop: 30 }}>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleRemove(idx)}
                                                        className={classes.button}
                                                    >
                                                        {t('obstetricGynecology.Remove')}
                                                    </Button>

                                                </Grid>

                                                <Grid item xs={3} style={{ marginTop: -34 }}>
                                                    <FormControl fullWidth className='' >
                                                        <InputLabel htmlFor="diseaseName">{t('Pediatric.DiseaseNames')}<i style={{ color: "#0000ffbf" }}> /疾患名</i></InputLabel>
                                                        <Input
                                                            id="DeseaseName"
                                                            type="text"
                                                            name="diseaseName"
                                                            id={field.diseaseName}
                                                            className="age"
                                                            value={field.diseaseName}
                                                            onChange={e => handleChangeDiseaseName(idx, e)}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={3} style={{ marginTop: -34 }}>
                                                    <FormControl fullWidth className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-label">{t('Pediatric.TreatmentProgress')}<i style={{ color: "#0000ffbf" }}>/治療経過</i></InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select-label"
                                                            value={field.treatmentProgress}
                                                            onChange={e => updateDrpdown(idx, e)}
                                                        >
                                                            <MenuItem value="Recovered">{t('Pediatric.Recovered')}<i style={{ color: "#0000ffbf" }}>/治癒</i></MenuItem>
                                                            <MenuItem value="UnderTreatment">{t('Pediatric.UnderTreatment')} <i style={{ color: "#0000ffbf" }}>/現在治療中</i></MenuItem>
                                                            <MenuItem value="Withdrawal of treatment">{t('Pediatric.WithdrawalOfTreatment')}<i style={{ color: "#0000ffbf" }}>/治療中断</i></MenuItem>
                                                            <MenuItem value="Untreatd">{t('Pediatric.Untreatd')} <i style={{ color: "#0000ffbf" }}>/未治療</i></MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    );
                                })}
                                <Button
                                    style={{ marginTop: 10, marginBottom: 10, fontSize: 20, backgroundColor: '#f28887', color: "white" }}
                                    variant="contained"
                                    onClick={() => handleAdd()}
                                    className={classes.button}
                                >
                                    +
                                             </Button>
                            </Grid>
                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>


            </Grid>

        </div>
    );
};

export default Form5;