import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray18() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(XrayContext);
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray18 - Knees/legs",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const language = i18n.language;
    const [playXRAYmulti12_4] = useSound('/sound/12/'+language+'12/12-04.mp3');
    const [playXRAYmulti12_12] = useSound('/sound/12/' + language + '12/12-12.mp3');
    const [playXRAYmulti12_13] = useSound('/sound/12/' + language + '12/12-13.mp3');
    const [playXRAYmulti12_14] = useSound('/sound/12/' + language + '12/12-14.mp3');
    const [playXRAYmulti12_15] = useSound('/sound/12/' + language + '12/12-15.mp3');
    const [playXRAYmulti12_16] = useSound('/sound/12/' + language + '12/12-16.mp3');
    const [playXRAYmulti12_17] = useSound('/sound/12/' + language + '12/12-17.mp3');
    const [playXRAYmulti12_18] = useSound('/sound/12/' + language + '12/12-18.mp3');
    const [playXRAYmulti12_19] = useSound('/sound/12/' + language + '12/12-19.mp3');
    const [playXRAYmulti13_14] = useSound('/sound/13/'+language+'13/13-14.mp3');
    
    const [playXRAYjp12_4] = useSound('/sound/12/jp12/12-04.mp3');
    const [playXRAYjp12_12] = useSound('/sound/12/jp12/12-12.mp3');
    const [playXRAYjp12_13] = useSound('/sound/12/jp12/12-13.mp3');
    const [playXRAYjp12_14] = useSound('/sound/12/jp12/12-14.mp3');
    const [playXRAYjp12_15] = useSound('/sound/12/jp12/12-15.mp3');
    const [playXRAYjp12_16] = useSound('/sound/12/jp12/12-16.mp3');
    const [playXRAYjp12_17] = useSound('/sound/12/jp12/12-17.mp3');
    const [playXRAYjp12_18] = useSound('/sound/12/jp12/12-18.mp3');
    const [playXRAYjp12_19] = useSound('/sound/12/jp12/12-19.mp3');
    const [playXRAYjp13_14] = useSound('/sound/13/jp13/13-14.mp3');

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            膝・下腿({t('test.title.knees/legs')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.photoOfKnees}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>膝の撮影をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo of knees')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.drawKnees}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>膝を立ててください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_12}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.draw knees')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_12}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.machineComeClose}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お顔の近くまで機械が近づきますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.machine come close')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_13}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.extendKnees}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>膝をまっすぐ伸ばしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.extend knees')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.turnFeet}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し内股にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_15}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.turn feet')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_15}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.sidewaysThere}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>あちら側に横向きになってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_16}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.sideways there')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_16}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.sidewaysHere}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちら側に横向きになってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_17}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.sideways here')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_17}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.putOutOppositeKnee}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>反対側の膝を前にだしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_18}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put out opposite knee')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_18}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.moveKnee}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お膝を動かしますので力を抜いていてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp12_19}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.move knee')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti12_19}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.knees.takeAphoto}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>撮影しますので動かないでいてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_14}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.take a photo')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_14}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" >Finish</Button></Link>
                 </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray18;