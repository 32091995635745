import React, {useState, createContext} from 'react';

export const PediatricsContext = createContext();

export const PediaContext = props => {
    const [values, setValues] = useState({
        name : '',
        lastName : '',
        firstName : '',
        DateOfBirth : new Date(),
        height : '',
        weight : '',
        sex : '',
        allergis : {
            foods : [],
            medcine : [],
        },
 
        problemtoday : [],
        anotherClinic : [],
        anotherClinicWindow : "0",
        diseasesSpreadSchool : "",
        diseasesSpreadSchools : [],

        symptomstart : new Date(),
        symtomstarttime : new Date(),

        onMedication : '',
        onmedications: [],

        conditionAtBirth: '',
        conditionAtBirths : [],

        WeeksPregnancyChildbirth: "",
        DaysPregnancyChildbirth: "",
        Weight:"",
        Height:"",
        HeadCircumference:"",
        ChestCircumference:"",

        haveBeentreatedAtOtherhospital : "false",
        YeshaveBeentreatedAtOtherhospital : [{
            date: new Date(),
            HospitalName: "",
            prescription: "false",
            diseaseName: "",
            treatmentProgress: ""
        }],

        MilkFeeding: false,
        MilkFeedingType: "",
            mlday: "",
            timeday: "",
        BabyFood:"",
        BabyFoodPeriod: "",
        BabyFoodFrequency: "",
        MealFrequency:"",
            mldayMealFrequency: "",
            timedayMealFrequency: "",
        SolidFood:"",
        SolidFoodType: "",
        
            Hib:'',
            HibTime:"",
            Rotavirus:'',
            RotavirusTime:"",
            Pneumococcus:'',
            PneumococcusTime:"",
            Mumps:'',
            MumpsTime:"",
            Tetravaccine:'',
            TetravaccineTime:'',
            Triplevaccine:'',
            TriplevaccineTime:"",
            HepatitisB:'',
            HepatitisBTime:"",
            Oralpoliovirus:'',
            OralpoliovirusTime:"",
            BCG:'',
            BCGTime:"",
            InactivatedPoliovirus:'',
            InactivatedPoliovirusTime:"",
            MRvaccine:'',
            MRvaccineTime:"",
            HepatitisA:'',
            HepatitisATime:"",
            Chickenpox:'',
            ChickenpoxTime:"",
            Measles:'',
            MeaslesTime:"",
            JapaneseEncephalitis:'',
            JapaneseEncephalitisTime:"",
            Rubella:'',
            RubellaTime:"",
            MMR:'',
            MMRTime:"",
            Other:'',
            OtherTime:"",
        
        
       
        medicalexpenses : false,
        haveinterpreter : false,
        otherssss : false

        });
   

    return(
        <PediatricsContext.Provider value={[values, setValues]}>
            {props.children}
        </PediatricsContext.Provider>
    );
};