import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import HumanImage from '../img/human-image1.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from "axios";
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    button: {
        display:"block",
        marginTop: "18px",
        color: "white",
        textAlign: "center",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        borderRadius: "10px",
        margin: "0 auto"
    },
    content: {
        paddingBottom: "10%",
        //paddingTop: "10%"
    },
    div1: {
       //marginLeft: "60px",
        //paddingLeft: "30px",
        //width: "300px",
        fontSize: "26px",
        float: "left"
    },

    div2: {
        padding: "10px",
        fontSize: "26px",
        border: "1px solid #6CAFED",
        float: "left"
    },

    a: {
        textAlign: 'center',
        padding: 5
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },

    p: {
        float: "left",
        textAlign: "center",
        fontSize: "17px",
        padding: "10px",
        display: "inline"
    }

}));



function Okinawadic() {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
    letter : '',
    count : '',
    remark : '',
    text1: ''
});
const history = useHistory();


const setText1=(event)=>{
    setValues({ ...values, text1: event.target.value });
}

//const base_url = "http://localhost:5000";
const base_url = process.env.REACT_APP_BACKEND_URL;

        return (
            <div >
                 <Nav />                            
                    <div>
                        <AppBar position="static" style={{ backgroundColor: "#F9B1B1", width: "auto"}}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                Human body structure diagram for Okinawa
                            </Typography>
                        </Toolbar>
                        </AppBar>
                    </div>
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <div className={classes.content}>
                        <Grid container spacing={3} style={{ padding: 20, textAlign: "center", display: "block", flex: 1 }}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <strong><h5>{t('select the part you want to search')} <i style={{ color: "blue" }}>/探したい単語の領域を選んでください。</i></h5></strong>
                                </Paper>
                            </Grid>

                            <Grid container>

                                <Grid item xs={6} style={{ textAlign: "center", display: "block" }}>
                                    <img alt="" src={HumanImage} style={{ width: 500 }} />
                                </Grid>
                                <Grid item xs={6}>   
                                    <div style={{ paddingLeft: 20, paddingRight: 20,  paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>1-<Link to="/showWords3?letter=頭" className={classes.a} >チブル/頭</Link></p>
                                        <p className={classes.p}>2-<Link to="/showWords3?letter=目" className={classes.a} >ニジリヌミー/眼（右）</Link></p>
                                        <p className={classes.p}>3-<Link to="/showWords3?letter=目" className={classes.a} >ヒジャイミー/眼（左）</Link></p>                                    
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>4-<Link to="/showWords3?letter=耳" className={classes.a} >ニジリヌミミ/耳(右)</Link></p>
                                        <p className={classes.p}>5-<Link to="/showWords3?letter=耳" className={classes.a} >ヒジャイヌミミ/耳(左)</Link></p>
                                        <p className={classes.p}>6-<Link to="/showWords3?letter=鼻" className={classes.a} >ハナ/鼻</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>7-<Link to="/showWords3?letter=口" className={classes.a} >クチ/口</Link></p>
                                        <p className={classes.p}>8-<Link to="/showWords3?letter=喉" className={classes.a} >ヌーディー/のど</Link></p>
                                        <p className={classes.p}>9-<Link to="/showWords3?letter=首" className={classes.a} >クビ/首</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>10-<Link to="/showWords3?letter=肩" className={classes.a} >カタバイ/肩</Link></p>
                                        <p className={classes.p}>11-<Link to="/showWords3?letter=背" className={classes.a} >ナガニ/背中</Link></p>
                                        <p className={classes.p}>12-<Link to="/showWords3?letter=胸" className={classes.a} >ムニ/胸</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>13-<Link to="/showWords3?letter=腹" className={classes.a} >ワタ/腹</Link></p>
                                        <p className={classes.p}>14-<Link to="/showWords3?letter=性器" className={classes.a} >タニ/陰部·性器</Link></p>
                                        <p className={classes.p}>15-<Link to="/showWords3?letter=腕" className={classes.a} >ニジルヌケンナー/腕(右)</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>16-<Link to="/showWords3?letter=腕" className={classes.a} >ヒジャイヌケンナー/腕(左)</Link></p>
                                        <p className={classes.p}>17-<Link to="/showWords3?letter=手" className={classes.a} >ニジルヌティー/手(右)</Link></p>
                                        <p className={classes.p}>18-<Link to="/showWords3?letter=手" className={classes.a} >ヒジャイヌティー/手(左)</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>19-<Link to="/showWords3?letter=腰" className={classes.a} >クシ/腰</Link></p>
                                        <p className={classes.p}>20-<Link to="/showWords3?letter=尻" className={classes.a} >チビ/尻</Link></p>
                                        <p className={classes.p}>21-<Link to="/showWords3?letter=脚" className={classes.a} >ニジルヌムム/腿(右)</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>22-<Link to="/showWords3?letter=脚" className={classes.a} >ヒジャイヌムム/腿(左)</Link></p>
                                        <p className={classes.p}>23-<Link to="/showWords3?letter=足" className={classes.a} >ニジルヌヒサ/足(右)</Link></p>
                                        <p className={classes.p}>24-<Link to="/showWords3?letter=足" className={classes.a} >ヒジャイヌヒサ/足(左)</Link></p>
                                    </div>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: "inherit" }} cols={3} cellHeight={10}>
                                        <p className={classes.p}>25-<Link to="/showWords3?letter=膝" className={classes.a} >ニジルヌチンシ/膝(右)</Link></p>
                                        <p className={classes.p}>26-<Link to="/showWords3?letter=膝" className={classes.a} >ヒジャイヌチンシ/膝(左)</Link></p>
                                        <p className={classes.p}>28-<Link to="/showWords3?letter=内蔵" className={classes.a} >ナイゾ/内蔵</Link></p>                  
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Link to="/terms"><Button className={classes.button} variant="contained" color="primary" disableElevation > {t('戻る')}</Button></Link>
                    </div>
                </Container>
                <Footer />
            </div>
        );
   }

export default Okinawadic;
