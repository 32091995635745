import React, {useState, createContext} from 'react';

export const CtContext = createContext();

export const CtTestContext = props => {
    const [values, setValues] = useState({
       
        DateOfBirth: new Date,
       
        //reception
        insertCard: '',
        takeSlip: '',
        waitCT: '',
        //dressing
        enterChangingRoom: '',
        takeSlipDressing: '',
        clothesIntoTheBasket: '',
        lookTheLocker: '',
        afterChanging:'',
        //question
        keepSlip: '',
        takeSlipQuestion: '',
        haveBreakfast: '',
        everCT: '',
        didnotFeelBetter: '',
        anyAllergy: '',
        anyMedAllergy: '',
        metfirmin: '',
        nameOfDiabetesMed: '',
        pregnantorBreastfeeding:'',
        symptoms: [],
        allergies: [],
        medAllergies: [],
        illnesses: [],
        //linekeep
        restYourArm: '',
        rubberBand: '',
        tightFist: '',
        streilize: '',
        alcoholAllergies: '',
        injenting: '',
        relaxYourHand: '',
        feelNumbness: '',
        waitOverThere: '',
        //during
        keepSlipDuring: '',
        takeSlipDuring: '',
        layDownWithShoesOn: '',
        lieFaceUp: '',
        usePillow: '',
        fastenTheBands: '',
        fullname: '',
        explainCT: '',
        raiseYourHands: '',
        touchYou: '',
        hearDirentions: '',
        breatheInAndHold: '',
        breatheInThenOut: '',
        feelHotSensation: '',
        informWhenDonotFeelWell: '',
        startCT: '',
        finishedInjecting: '',
        painFromNeedle: '',
        everythingOk: '',
        hearAnnouncement:'',

        //after
        ctComplete: '',
        takeSlipAfter: '',
        waitUntilBedLowerd:'',
        lowerHand: '',
        bedlowerGetOff:'',
        accountFile: '',
        restInFrontRoom: '',
        informWhenFeelWorse: '',
        takeOffIV: '',
        press5minutes:'',
        after5minutes: '',
        followInstructions: '',
        drinkWater: '',
        changeClothes: '',
        gownInLaundry:'',
    });


    return (
        <CtContext.Provider value={[values, setValues]}>
            {props.children}
        </CtContext.Provider>
    );
};