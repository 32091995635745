import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray3() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(XrayContext);

    const [areYouPregnant, setAreYouPregnant] = React.useState('');
    const [painRightSide, setPainRightSide] = React.useState('');
    const [painLeftSide, setPainLeftSide] = React.useState('');
    const [standUp, setStandUp] = React.useState('');
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray3 - Enter the room",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const handleChangeAreYouPregnant = event => {
        setAreYouPregnant(event.target.value);
        setValues({ ...values, areYouPregnant: event.target.value })
    };
    const handleChangePainRightSide = event => {
        setPainRightSide(event.target.value);
        setValues({ ...values, painRightSide: event.target.value })
    };
    const handleChangePainLeftSide = event => {
        setPainLeftSide(event.target.value);
        setValues({ ...values, painLeftSide: event.target.value })
    };
    const handleChangeStandUp = event => {
        setStandUp(event.target.value);
        setValues({ ...values, standUp: event.target.value })
    };

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            入室({t('test.title.enter')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained" > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.goThisWay}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>～番の方こちらへどうぞ<br />{t('test.enter.go this way')}</h5> } />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.receiveSlip}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>ピンクの受付票をいただきます<br />{t('test.enter.receive slip')}</h5>} />
 
                    </Paper>
                    
                </Grid>
            <Grid item xs={12}>

                <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.tellFullName}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>確認のため、お名前をフルネームで教えていただけますか？<br />{t('test.enter.tell full name')}</h5> } />
 
                 </Paper>
                    
             </Grid>
        <Grid item xs={12}>

            <Paper className={classes.paper}>
                <FormControlLabel
                            value={values.putLuggage}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お荷物をかごの中にいれてください<br />{t('test.enter.put luggage')}</h5>} />
 
                 </Paper>
                    
             </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>現在妊娠しているか、またはその可能性がありますか？<br />{t('test.enter.are you pregnant')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="areYouPregnant" name="areYouPregnant" value={values.areYouPregnant} onChange={handleChangeAreYouPregnant}>
                            <FormControlLabel value="beingPregnant" control={<Radio />} label={<h6>{t('test.enter.being pregnant')}<br />妊娠中</h6>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h6>{t('test.no')}<br />いいえ </h6>}/>
                            <FormControlLabel value="unknown" control={<Radio />} label={<h6>{t('test.unknown')}<br />わからない</h6>} />
                                        
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

            <Paper className={classes.paper}>
                <FormControlLabel
                            value={values.photoOfHead}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は頭部の撮影をします<br />{t('test.enter.photo of head')}</h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfChest}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は胸部の撮影をします<br />{t('test.enter.photo of chest')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfAbdomen}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は腹部の撮影をします<br />{t('test.enter.photo of abdomen')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfSpine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は背骨の撮影をします<br />{t('test.enter.photo of spine')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfPelvis}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は骨盤の撮影をします<br />{t('test.enter.photo of pelvis')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfShoulder}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は肩の撮影をします<br />{t('test.enter.photo of shoulder')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfHand}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は手の撮影をします<br />{t('test.enter.photo of hand')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfLegs}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今日は足の撮影をします<br />{t('test.enter.photo of legs')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.photoOfBothSide}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>両側撮影します<br />{t('test.enter.photo of both sides')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>痛いほうは右側で間違いないですか？<br />{t('test.enter.pain in the right side')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="painRightSide" name="painRightSide" value={values.painRightSide} onChange={handleChangePainRightSide}>
                            <FormControlLabel value="right" control={<Radio />} label={<h6>{t('test.enter.right')}<br />右側</h6>} />
                            <FormControlLabel value="left" control={<Radio />} label={<h6>{t('test.enter.left')}<br />左側 </h6>} />
                            <FormControlLabel value="both" control={<Radio />} label={<h6>{t('test.enter.both')}<br />両側 </h6>} />
                            <FormControlLabel value="unknown" control={<Radio />} label={<h6>{t('test.unknown')}<br />わからない</h6>} />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>痛いほうは左側で間違いないですか？<br />{t('test.enter.pain in the left side')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="painLeftSide" name="painLeftSide" value={values.painRightSide} onChange={handleChangePainLeftSide}>
                            <FormControlLabel value="right" control={<Radio />} label={<h6>{t('test.enter.right')}<br />右側</h6>} />
                            <FormControlLabel value="left" control={<Radio />} label={<h6>{t('test.enter.left')}<br />左側 </h6>} />
                            <FormControlLabel value="both" control={<Radio />} label={<h6>{t('test.enter.both')}<br />両側 </h6>} />
                            <FormControlLabel value="unknown" control={<Radio />} label={<h6>{t('test.unknown')}<br />わからない</h6>} />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.wherePartHurt}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>痛いところはどのあたりですか？<br />{t('test.enter.where part hurt')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>立って撮影しますが、立っていられますか？<br />{t('test.enter.can you stand up')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="standUp" name="standUp" value={values.standUp} onChange={handleChangeStandUp}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray4" className={classes.link}><Button className={classes.button} variant="contained" color="secondary"> NEXT</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray3;