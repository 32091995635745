import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { CardiologyContext } from '../context/cardiologyContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,
       
        textAlign: 'center',
        margin:10,
        padding:3
      },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const CenteredGrid = () => {
    const { t, i18n } = useTranslation();
    const problem = [
        { name: t('cardiology.Irregularpulse'), text:' /脈が乱れる',  value: 'Irregular pulse' },
        { name: t('cardiology.Chestpain'), text:' /胸痛', value: 'Chest pain' },
        { name: t('cardiology.Coldsweat'), text:'/冷汗', value: 'Cold sweat' },
        { name: t('cardiology.Heavinessinchest'), text:'/胸が重たい', value: 'Heaviness in chest' },
        { name: t('cardiology.Difficultybreathing'), text:'/息苦しさ', value: 'Difficulty breathing' },
        { name: t('cardiology.Shortnessofbreath'), text:'/息切れ', value: 'Shortness of breath' },
        { name: t('cardiology.Haveapalpitation'), text:'/動悸がする', value: 'Have a palpitation' },
        { name: t('cardiology.Lightheadedness'), text:'/立ちくらみ', value: 'Lightheadedness' },
        { name: t('cardiology.Swelling'), text:'/むくみ（顔・手・足）', value: 'Swelling (face, hands, feet)' },
        { name: t('cardiology.Coldhandsandfeet'), text:'/手足が冷たい', value: 'Cold hands and feet' },
        { name: t('cardiology.Other'), text:'/その他：', value: 'Other(s)' },
        { name: t('cardiology.advisedbyanotherclinic'), text:'/他の医療機関から受診するように勧められた（健診含む）', value: 'I was advised by another clinic/hospital (or at a regular check-up) to come here.' },
    ]

    const chestPain = [
        { name: t('Squeezing pain'), text:' /締め付けられるような痛み',  value: 'Squeezing pain' },
        { name: t('Burning pain'), text:' /焼けるような痛み',  value: 'Burning pain' },
        { name: t('Tingling pain'), text:' /ちくちくするような痛み',  value: 'Tingling pain' },
        { name: t('Stabbing pain'), text:' /刺すような痛み',  value: 'Stabbing pain' },
        { name: t('Dull pain'), text:' /鈍い痛み',  value: 'Dull pain' },
        { name: t('Other(s)'), text:' /その他:',  value: 'Other(s)' },
    ]

    const [values, setValues] = useContext(CardiologyContext);
    const [chest, setShest] = useState("0");
    const classes = useStyles();

    const handleToggleProblem = (value) => () => {
        const currentIndex = values.problemtoday.indexOf(value.value);
        const newChecked = [...values.problemtoday];

        if (currentIndex === -1) {
            newChecked.push(value.value);
            if (value.value === "Chest pain"){
                setValues({ ...values, problemtoday: newChecked, chestPainWindow: "1"  })
            }else{
                newChecked.push(value.value);
                setValues({...values, problemtoday:newChecked});
            }
        } else {
            newChecked.splice(currentIndex, 1);
            if (value.value === "Chest pain"){
                setValues({ ...values, problemtoday: newChecked, chestPainWindow: "0"  })
            }else{
                newChecked.splice(currentIndex, 1);
                setValues({...values, problemtoday:newChecked});
            }
        }

        
        
    };

    const handleToggleChestPain = (value) => () => {
        const currentIndex = values.chestPain.indexOf(value.value);
        const newChecked = [...values.chestPain];

        if (currentIndex === -1) {
            newChecked.push(value.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setValues({ ...values, chestPain: newChecked })
       
    };

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h4>{t('internalMedcine.problemtoday')}<br/><i style={{color:"blue"}}>/今日はどのような症状がありますか。（複数ある方は複数☑してください.)</i></h4></strong></Paper>
                    </Grid>
                    
                    <Grid  style={{ width:"inherit", flex:1 }}>
                    <GridList  style={{  width:"inherit", height: 182, paddingLeft: 20,paddingRight: 20}} cellHeight={10} className={classes.gridList} cols={3}>
                        {problem.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem  key={value.name} role={undefined} dense button onClick={handleToggleProblem(value)}>
                                    <ListItemIcon >
                                        <Checkbox
                                            edge="start"
                                            checked={values.problemtoday.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </GridList>

                    
                        </Grid>

                        <Grid>
                        <div style={{width:"inherit", flex: 1}}>
                    {values.chestPainWindow === '1' ? (
                        <Grid style={{width:"inherite", backgroundColor:"#ffff0047"}}>
                        <FormLabel  style={{lineHeight:2, fontSize:18}}><strong>{t('cardiology.ifChestPain')} <br/><i style={{ color: "#0000ffbf" }}>/「はい」如果您在上述问题中勾选了“胸痛”。痛苦是什么样的？</i></strong></FormLabel>
                        <GridList style={{ height: 83, paddingLeft: 20, paddingRight: 20, width: "inherit" }} cellHeight={10} className={classes.gridList} cols={3}>
                        {chestPain.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggleChestPain(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.chestPain.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                            {value.name}
                                            <span style={{color: "blue" }}>{value.text}</span>
                                        </ListItemText>

                                </ListItem>
                            );
                        })}
                        </GridList>
                        </Grid>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
                        </Grid>
                    </Grid>

            </div>
        </div>
    )
}
export default CenteredGrid;