import React, {useState, createContext} from 'react';

export const MriContext = createContext();

export const MriTestContext = props => {
    const [values, setValues] = useState({
        
        DateOfBirth: new Date,
        weeks: '',
       
        
        //reception
        insertCard: '',
        takeSlip: '',
        waitMRI: '',

        //dressing
        reviewQuestionnaire: '',
        takeSlipDressing: '',
        surgeriesToInsertAMetal: '',
        eatenAnyMeal:'',
        contrastMediumBefore: '',
        asthmaOrAllergies: '',
        allergies: [],
        dringContrastMedium: '',
        openedWouned:'',
        prohibitLiquid:'',
        slowlyDrink: '',
        //question
        usesMagneticComponents: '',
        takeSlipMriQuestion: '',
        anyMetalicObjects: '',
        to30mintes: '',
        notMoveDuringExam: '',
        narrowUnnel: '',
        connotPacemaker: '',
        surgeryMeallic: '',
        metalMRIcompatible: '',
        ifnotCannotExam: '',
        anyTattoos: '',
        feelBurning: '',
        wishtoProcede: '',
        removeDentures: '',
        removeHearingAids: '',
        removeStickingMaterials: '',
        removeHeartrateMonitoringseals: '',
        ifYouArePregnant:'',
        pressTheEmergencyButton: '',
        //during

        holdKey: '',
        checkOneAnyMetals: '',
        lieFaceUpMRI: '',
        lieFaceDownMRI: '',
        fastenTheBandsMRI: '',
        wearHeadphones: '',
        fullnameMRI: '',
        explainMRI: '',
        notMakeMovement: '',
        ringBuzzer:'',
        plaseAFilmingDevice: '',
        touchYou:'',
        duringMRIhearDirentions: '',
        breatheInAndHold: '',
        injectContrastMedium: '',
        pressBuzzer: '',
        startMRI: '',
        finishedInjecting: '',
        painFromNeedle: '',
        everythingOk:'',
        donotMove: '',
        //after
        mriComplete: '',
        takeSlipAfter: '',
        waitUntilBedLowerd: '',
        bedlowerGetOff: '',
        putOutNeedle: '',
        pressHere: '',
        pressHard: '',
        bleedingStop: '',
        otherTest: '',
        otherExam: '',
        casher: '',
        outpatientsDepartment: '',
        leaveClothesAndKey:'',
    });


    return (
        <MriContext.Provider value={[values, setValues]}>
            {props.children}
        </MriContext.Provider>
    );
};