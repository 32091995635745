import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { MriContext } from "../mriContext";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
        
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Mri4() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useContext(MriContext);

    const [weeks, setWeeks] = useState('')
    const updateWeeks = (e) => {
        setWeeks(e.target.value);
        setValues({ ...values, weeks: e.target.value })
    }

    const [surgeryMeallic, setSurgeryMeallic] = React.useState('');
    const [metalMRIcompatible, setMetalMRIcompatible] = React.useState('');
    const [anyTattoos, setAnyTattoos] = React.useState('');
    const [ifYouArePregnant, setIfYouArePregnant] = React.useState('');
     const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "mri4 - Medical Questionnaire",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});
    
   

    const handleChangeSurgeryMeallic = event => {
        setSurgeryMeallic(event.target.value);
        setValues({ ...values, surgeryMeallic: event.target.value })
    };
    const handleChangeMetalMRIcompatible = event => {
        setMetalMRIcompatible(event.target.value);
        setValues({ ...values, metalMRIcompatible: event.target.value })
    };
    const handleChangeAnyTattoos = event => {
        setAnyTattoos(event.target.value);
        setValues({ ...values, anyTattoos: event.target.value })
    };
    const handleChangeIfYouArePregnant = event => {
        setIfYouArePregnant(event.target.value);
        setValues({ ...values, ifYouArePregnant: event.target.value })
    };
    
    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            問診({t('test.title.question')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.usesMagneticComponents}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>磁石を使用した検査です<br />{t('test.MRIquestion.uses magnetic components')}</h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlipMriQuestion}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5>} />
                        
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                      <FormControlLabel
                            value={values.anyMetalicObjects}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査室の中には金属系のものを持ち込めません<br />{t('test.MRIquestion.any metalic objects')}</h5>} />
                       
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.to30mintes}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査時間は15分から30分です<br />{t('test.MRIquestion.15 to 30 mintes')}</h5>} />
 
                    </Paper>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.notMoveDuringExam}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>検査中は動かないでください<br />{t('test.MRIquestion.do not move during exam')}</h5>} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.narrowUnnel}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>狭いトンネルで検査します<br />{t('test.MRIquestion.narrow unnel')}</h5>} />
 
                 </Paper>
                    
                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.connotPacemaker}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>体内に心臓のペースメーカーがあると検査できません<br />{t('test.MRIquestion.cannot exam if there is a pacemaker')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>過去に手術をして医療系の金属は体内にありますか？<br />{t('test.MRIquestion.surgery meallic')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="surgeryMeallic" name="surgeryMeallic" value={values.surgeryMeallic} onChange={handleChangeSurgeryMeallic}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        {values.surgeryMeallic === 'yes' ? (
                            <div>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}><strong><h5>「はい」と答えられた方は、その金属はMRI対応ですか？<br />{t('test.MRIquestion.metal MRI compatible')}</h5></strong></Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">

                                        <RadioGroup row aria-label="metalMRIcompatible" name="metalMRIcompatible" value={values.metalMRIcompatible} onChange={handleChangeMetalMRIcompatible}>
                                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </div>

                        ) : (
                                <nav> &apos; </nav>
                            )}
                    </div>
                </Grid>
                <Grid item xs={11}>
                    <div>
                        {values.metalMRIcompatible === 'yes' ? (
                     
                            <Paper className={classes.paper} style={{backgroundColor:"#FCD7A1"}}>
                            <FormControlLabel
                                    value={values.ifnotCannotExam}
                                control={<Checkbox />}
                                labelPlacement="start"
                                    label={<h5>対応でないと検査できません<br />{t('test.MRIquestion.if not, cannot exam')}</h5>} />

                        </Paper>
                    ) : (
                            <nav> &apos; </nav>
                            )}
                    
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>刺青はありますか？<br />{t('test.MRIquestion.any tattoos')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="anyTattoos" name="anyTattoos" value={values.anyTattoos} onChange={handleChangeAnyTattoos}>
                            <FormControlLabel value="yes" control={<Radio />} label={<h5>{t('test.yes')}<br />はい</h5>} />
                            <FormControlLabel value="no" control={<Radio />} label={<h5>{t('test.no')}<br />いいえ</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={11}>
                   <div>
                    {values.anyTattoos === 'yes' ? (
                       <div>
                            <Grid item xs={12}>
                                    <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1", margin:10 }} >
                                <FormControlLabel
                                    value={values.feelBurning}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<h5>「はい」と答えられた方は刺青が火傷や色が変化する可能性があります<br />{t('test.MRIquestion.feel burning')}</h5>} />

                               </Paper>
                            </Grid>
                            <Grid item xs={12}>
                               <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1",margin:10 }}>
                                <FormControlLabel
                                    value={values.wishtoProcede}
                                    control={<Checkbox />}
                                    labelPlacement="start"
                                    label={<h5>承知したうえで検査を行いますか？<br />{t('test.MRIquestion.wish to procede')}</h5>} />

                               </Paper>
                            </Grid>
                       </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                   </div>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.removeDentures}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>入れ歯を外してください<br />{t('test.MRIquestion.remove dentures')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.removeHearingAids}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>補聴器を外してください<br />{t('test.MRIquestion.remove hearing aids')}</h5>} />
                      
                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.removeStickingMaterials}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>シップ・カイロなど貼り薬などは外してください<br />{t('test.MRIquestion.remove sticking materials')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.removeHeartrateMonitoringseals}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>心電図モニターとシールは外してください<br />{t('test.MRIquestion.remove heart rate monitoring seals')}</h5>} />

                    </Paper>

                </Grid >
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>妊娠（3か月以内）の方は教えてください？<br />{t('test.MRIquestion.if you are pregnant')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup row aria-label="ifYouArePregnant" name="ifYouArePregnant" value={values.ifYouArePregnant} onChange={handleChangeIfYouArePregnant}>
                            <FormControlLabel value="pregnant" control={<Radio />} label={<h5>{t('test.MRIquestion.Im pregnant')}<br />妊娠しています</h5>} />
                            <FormControlLabel value="notPregnant" control={<Radio />} label={<h5>{t('test.MRIquestion.Not pregnant')}<br />妊娠していません</h5>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={11}>
                    <div>
                        {values.ifYouArePregnant === 'pregnant' ? (

                            <Paper className={classes.paper} style={{ backgroundColor: "#FCD7A1" }}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <h5>現在、妊娠何週ですか？<br />{t('test.MRIquestion.weeks pregnant')}</h5>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <FormControl fullWidth xs={3} className=''>
                                    <InputLabel htmlFor="weeks">{t('test.weeks')}</InputLabel>
                                    <Input
                                        id="weeks"
                                        type="number"
                                        values={weeks}
                                        defaultValue={values.weeks}
                                        onChange={updateWeeks}
                                    />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Paper>
                        ) : (
                                <nav> &apos; </nav>
                            )}

                    </div>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.pressTheEmergencyButton}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>閉所恐怖症の方は耐えられないと思ったら緊急ボタンを押してください<br />{t('test.MRIquestion.press the emergency button')}</h5>} />

                    </Paper>

                </Grid >
 
         　 </Grid>
            <div style={{ margin: 20 }}>

                <Link to="mri1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >MRI HOME</Button></Link>
                <Link to="mri5" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
                 </div>
            <Footer />

        </div>
                
    );
}
 
export default Mri4;