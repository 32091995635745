import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CtContext } from "../ctContext "


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign:'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "7%",
        paddingTop: "7%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Ct2() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
        const history = useHistory();

    const authAxios = Axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          'x-auth-token': userData.token,
        },
      });

    useEffect(() => {
        if (!userData.user) history.push("/login1");
    console.log(userData);
        if (userData.user) {
          try{
            const logFunction = async () => {
              const loginfo = {
                inteviewName: "ct2 - Examination reception",
                userName: userData.user.userName,
                language: i18n.language,
                contentSentence: '',
                date: new Date,
                userId: userData.user.id,
              };
              const loginInput = await authAxios.post(
                "/logfile/insert",
                loginfo
              );
            }
            logFunction();
          } catch (err) {
           console.log(err);
           
          }
        }
    });

    const [values, setValues] = React.useContext(CtContext);
    const [insertCard, setInsertCard] = React.useState('');
   


    const handleChange = event => {
        setInsertCard(event.target.value);
        setValues({ ...values, insertCard: event.target.value })
    };
   

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            検査受付({t('test.title.reception')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
            <Link to="inspectionHome" className={classes.link} ><Button variant="contained"  > Inspection Home</Button></Link>
            </div>

                <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><strong><h5>診察券をこの受付機に入れてください<br />{t('test.reception.insert card')}</h5></strong></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">

                        <RadioGroup  name="anser1" value={values.insertCard} onChange={handleChange}>
                            <FormControlLabel value="ans1" control={<Radio />} label={<strong><h6>{t('test.ans1')}<br />どうやってやればいいですか？</h6></strong>} />
                            <FormControlLabel value="ans2" control={<Radio />} label={<strong><h6>{t('test.ans2')}<br />わかりました。</h6></strong>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.takeSlip}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>この受付票をお持ちください<br />{t('test.reception.take slip')}</h5> </strong>} />
 
                    </Paper>
                    
                </Grid>
            <Grid item xs={12}>

                <Paper className={classes.paper}>
                    <FormControlLabel
                            value={values.waitCT}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<strong><h5>検査のお時間・順番まであちらのCT待合室でお待ちください<br />{t('test.reception.wait CT')}</h5> </strong>} />
 
                 </Paper>
                    
             </Grid>
                
         </Grid>
             <div  style={{margin:20}}>

                <Link to="ct1" className={classes.link}><Button className={classes.button} variant="contained" color="primary" >CT HOME</Button></Link>
            <Link to="ct3" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > NEXT</Button></Link>
             
                
             </div>

            <Footer />

        </div>
                
    );
}
 
export default Ct2;