import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import FormLabel from '@material-ui/core/FormLabel';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PediatricsContext } from '../context/pediatricsContext';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridList: {
        width: 1000,
        textAlign: 'center',
        margin: 10,
        padding: 5
    },
    sidecheck:{
        paddingTop:11, 
        marginLeft:-13, 
        marginRight:5, 
        color:"blue"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
    label: {
        width:"100%",
        textAlign:"center",
        lineHeight: 1.5
    }
}));
const Form3 = () => {
    const { t, i18n } = useTranslation();
    const disease = [
        { name: t('Pediatric.Chickenpox'), text:'/ みずぼうそう ', value: "Chickenpox" },
        { name: t('Pediatric.Infectiousgastroenteritis'), text:'/ 感染性胃腸炎', value: "Infectiousgastroenteritis" },
        { name: t('Pediatric.Streptococcalinfection'), text:'/ 溶連菌感染症', value: "Streptococcalinfection" },
        { name: t('Pediatric.Mumps'), text:'/ おたふくかぜ', value: "Mumps" },
        { name: t('Pediatric.Influenza'), text:'/ 起床時', value: "Influenza" },
        { name: t('Pediatric.Handfootandmouthdisease'), text:'/ 手足口病', value: "Handfootandmouthdisease" },
        { name: t('Pediatric.Herpangina'), text:'/ ヘルパンギーナ', value: "Herpangina" },
        { name: t('Pediatric.Adenovirusinfection'), text:'/ アデノウイルス感染症', value: "Adenovirusinfection" },
        { name: t('Pediatric.Erythemainfectiosum'), text:'/ りんご病', value: "Erythemainfectiosum" },
        { name: t('internalMedcine.Other'), text:'/ その他朝', value: "Other" },
    ]

    const [values, setValues] = useContext(PediatricsContext);
    const classes = useStyles();

    const handleChange = (event) => {
        if(event.target.value === "Yes"){
            setValues({...values, diseasesSpreadSchool:event.target.value})
        }else{
            setValues({...values, diseasesSpreadSchool:event.target.value, diseasesSpreadSchools : []})
        }
    };

    const handleToggledisease = (object) => () => {
        const currentIndex = values.diseasesSpreadSchools.indexOf(object.value);
        const newChecked = [...values.diseasesSpreadSchools];

        if (currentIndex === -1) {
            newChecked.push(object.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setValues({...values, diseasesSpreadSchools:newChecked})
    };

    const updateDate = (date) => {
        setValues({...values, symptomstart:date})
    }
    const updateTime = (time) => {
        setValues({...values, symtomstarttime:time})
    }

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{ padding: 20, flex:1}}>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('Pediatric.Haveanydiseasesbeenspreadinginyourschool')}? <br /><i style={{color:"blue"}}>/学校や保育所、家族内で流行している病気はありますか。</i></h5></strong></Paper>
                    </Grid>

                    <Grid item xs={12}>
                <FormControl component="fieldset">
                    
                    <RadioGroup row aria-label="gender" name="gender1" value={values.diseasesSpreadSchool} onChange={handleChange}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" /><i className={classes.sidecheck}>/はい</i>
                        <FormControlLabel value="No" control={<Radio />} label="No" /><i className={classes.sidecheck}>/いいえ</i>
                    </RadioGroup>
                </FormControl>
                </Grid>

                <Grid style={{width:"inherit", flex: 1}}>
                <div>
                    {values.diseasesSpreadSchool === 'Yes' ? (
                        <div>
                        <FormLabel className={classes.label}><strong>{t('internalMedcine.currentlyonmedicationyesno')}  <br /><i style={{color:"blue"}}>/「はい」に☑した人は、当てはまるものに、、チェックをしてください</i></strong></FormLabel>
                        <GridList style={{ height: 129, paddingLeft: 20, paddingRight: 20, paddingTop: 10, width:"inherit"}} cellHeight={10} className={classes.gridList} cols={4}>
                        {disease.map((value) => {
                            const labelId = `checkbox-list-label-${value.name}`;

                            return (
                                <ListItem key={value.name} role={undefined} dense button onClick={handleToggledisease(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={values.diseasesSpreadSchools.indexOf(value.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        {value.name}
                                        <span style={{color:"blue"}}>{value.text}</span>
                                    </ListItemText>

                                </ListItem>
                            );
                        })}
                        </GridList>
                        </div>
                    ) : (
                            <nav> &apos; </nav>
                        )}
                </div>
                </Grid>

                <Grid item xs={12}>
                        <Paper className={classes.paper}><strong><h5>{t('internalMedcine.symptomstart')}?<br /><i style={{color:"blue"}}> /この症状はいつからありますか</i></h5></strong></Paper>
                    </Grid>
                    <Grid  style={{width:"inherit"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Grid container justify="space-around" >
                                    <Grid item xs={6} style={{paddingLeft:20, paddingRight:20}}>
                                <KeyboardDatePicker 
                                    fullWidth
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={t('internalMedcine.Date')}
                                    format="MM/dd/yyyy"
                                    value={values.symptomstart}
                                    onChange={updateDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                </Grid>
                                <Grid item xs={6} style={{paddingLeft:20, paddingRight:20}}>
                                <KeyboardTimePicker 
                                   
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label={t('internalMedcine.Time')}
                                    value={values.symtomstarttime}
                                    onChange={updateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }} />
                                    </Grid>
                            </Grid>

                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
};

export default Form3;