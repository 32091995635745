import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from '../context/UserContext';
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Nav from "../nav.js";
import Footer from "../footer";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { XrayContext } from '../xrayContext'
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: "white",
        fontSize: "16px",
    },
    link: {
        "&:hover": {
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    },
     paper: {
        padding: theme.spacing(2),
         textAlign: 'center',
        backgroundColor:'#EFEFEF',
        color: 'black',
    },
    
}));
function Xray5() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { userData } = useContext(UserContext);
    const history = useHistory();

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'x-auth-token': userData.token,
    },
  });

useEffect(() => {
    if (!userData.user) history.push("/login1");
console.log(userData);
    if (userData.user) {
      try{
        const logFunction = async () => {
          const loginfo = {
            inteviewName: "xray5 - chest",
            userName: userData.user.userName,
            language: i18n.language,
            contentSentence: '',
            date: new Date,
            userId: userData.user.id,
          };
          const loginInput = await authAxios.post(
            "/logfile/insert",
            loginfo
          );
        }
        logFunction();
      } catch (err) {
       console.log(err);
       
      }
    }
});

    const [values, setValues] = React.useContext(XrayContext);
    const language = i18n.language;
    const [playXRAYmulti6_1] = useSound('/sound/6/'+language+'6/6-01.mp3');
    const [playXRAYmulti6_2] = useSound('/sound/6/' + language + '6/6-02.mp3');
    const [playXRAYmulti6_3] = useSound('/sound/6/' + language + '6/6-03.mp3');
    const [playXRAYmulti6_4] = useSound('/sound/6/' + language + '6/6-04.mp3');
    const [playXRAYmulti6_5] = useSound('/sound/6/' + language + '6/6-05.mp3');
    const [playXRAYmulti6_6] = useSound('/sound/6/' + language + '6/6-06.mp3');
    const [playXRAYmulti6_7] = useSound('/sound/6/' + language + '6/6-07.mp3');
    const [playXRAYmulti6_8] = useSound('/sound/6/' + language + '6/6-08.mp3');
    const [playXRAYmulti6_9] = useSound('/sound/6/' + language + '6/6-09.mp3');
    const [playXRAYmulti6_10] = useSound('/sound/6/' + language + '6/6-10.mp3');
    const [playXRAYmulti6_11] = useSound('/sound/6/' + language + '6/6-11.mp3');
    const [playXRAYmulti6_12] = useSound('/sound/6/' + language + '6/6-12.mp3');
    const [playXRAYmulti6_13] = useSound('/sound/6/' + language + '6/6-13.mp3');
   
    const [playXRAYjp6_1] = useSound('/sound/6/jp6/6-01.mp3');
    const [playXRAYjp6_2] = useSound('/sound/6/jp6/6-02.mp3');
    const [playXRAYjp6_3] = useSound('/sound/6/jp6/6-03.mp3');
    const [playXRAYjp6_4] = useSound('/sound/6/jp6/6-04.mp3');
    const [playXRAYjp6_5] = useSound('/sound/6/jp6/6-05.mp3');
    const [playXRAYjp6_6] = useSound('/sound/6/jp6/6-06.mp3');
    const [playXRAYjp6_7] = useSound('/sound/6/jp6/6-07.mp3');
    const [playXRAYjp6_8] = useSound('/sound/6/jp6/6-08.mp3');
    const [playXRAYjp6_9] = useSound('/sound/6/jp6/6-09.mp3');
    const [playXRAYjp6_10] = useSound('/sound/6/jp6/6-10.mp3');
    const [playXRAYjp6_11] = useSound('/sound/6/jp6/6-11.mp3');
    const [playXRAYjp6_12] = useSound('/sound/6/jp6/6-12.mp3');
    const [playXRAYjp6_13] = useSound('/sound/6/jp6/6-13.mp3');

    const [playXRAYjp2_6] = useSound('/sound/2/jp2/2-6.mp3');
    const [playXRAYjp2_7] = useSound('/sound/2/jp2/2-7.mp3');
    const [playXRAYmulti2_6] = useSound('/sound/2/' + language + '2/2-6.mp3');
    const [playXRAYmulti2_7] = useSound('/sound/2/' + language + '2/2-7.mp3');
   
    const [playXRAYjp13_8] = useSound('/sound/13/jp13/13-08.mp3');
    const [playXRAYmulti13_8] = useSound('/sound/13/' + language + '13/13-08.mp3');
   

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h4" noWrap style={{ paddingLeft: "10px", color: "white" }}>
                            胸部({t('test.title.chest')})
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <div style={{ margin: 10 }}>
                <Link to="inspectionHome" className={classes.link}><Button variant="contained"  > Inspection Home</Button></Link>
            </div>
            <Grid container justify={"flex-end"} spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.chestOnTheMachine}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>機械に胸を付けて、軽くあごをあげるようにして立ってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_1}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.chest on the machine')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_1}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.moveYourBody}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>位置合わせのためにお体を動かします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_2}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.move your body')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_2}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                    </Paper>
                    
                </Grid>
                <Grid item xs={12}>

                   <Paper className={classes.paper}>
                       <FormControlLabel
                            value={values.chest.machineMove}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>前の機械が動きます
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_3}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.machine move')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_3}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />
 
                   </Paper>
                    
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.tryToRelax}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>力を抜いてください<br />{t('test.Xray.try to relax')}</h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.moveElbows}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>肘と肩を板に近づけます
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_4}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.move elbows')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_4}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.donotMove}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>そのまま動かないでください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp13_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.donot move')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti13_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.giveAsignal}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸の合図をします
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_5}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton><br />{t('test.Xray.give a signal')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_5}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.deepBreath}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>大きく息を吸って止めてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.deep breath')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_6}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.relaxYourBreath}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>呼吸を楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.relax your breath')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_7}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.holdYourBody}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お体はそのままで、呼吸だけ楽にしてください<br />{t('test.Xray.hold your body')}</h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.photoWhenBreathOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>今度は息を吐いたところで撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_8}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo when breath out')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_8}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.breatheInThenOut}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>息を吸って吐いて止めてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp2_6}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                楽にしてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp2_7}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.breathe in then out')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti2_6} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton>
                                {t('test.pleaseRelax')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti2_7} >
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.turnAndHoldStick}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>こちらを向いて、上にある棒につかまってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_10}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.turn and hold stick')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_10}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>
                </Grid>

                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.photoWhenSitting}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>座ったまま撮影します
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_11}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.photo when sitting')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_11}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.putPlateOnYourBack}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>お背中に板を入れますので、少し前のめりになってください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_12}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.put a plate on your back')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_12}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <FormControlLabel
                            value={values.chest.lookUp}
                            control={<Checkbox />}
                            labelPlacement="start"
                            label={<h5>少し上を向いてください
                                <IconButton aria-label="Play/pause" onClick={playXRAYjp6_13}>
                                    <PlayCircleOutlineIcon color="primary" fontSize="large" />
                                </IconButton>
                                <br />{t('test.Xray.look up')}
                                <IconButton aria-label="Play/pause" onClick={playXRAYmulti6_13}>
                                    <PlayCircleOutlineIcon color="secondary" fontSize="large" />
                                </IconButton></h5>} />

                    </Paper>

                </Grid>
         </Grid>
            <div style={{ margin: 20 }}>

                <Link to="xray1" className={classes.link}><Button className={classes.button} variant="contained" color="primary"  >X-ray HOME</Button></Link>
                <Link to="xray20" className={classes.link}><Button className={classes.button} variant="contained" color="secondary" > Finish</Button></Link>
               </div>
            <Footer />

        </div>
                
    );
}
 
export default Xray5;